/**
 * @About
 * This file contains footer.
 */

import React from 'react';
import { Link } from 'react-router-dom';

//footer
export default function Footer() {
    return (
        <footer className="white-footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-center text-sm-left d-block d-sm-inline-block">Copyright © 2019 <Link to='/' className="link">Farechild LLC</Link>. All rights reserved.</span>
                <div className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                    <Link className="mr-2 link">Support</Link> <Link className=" link mr-2" >Terms & Conditions</Link> <Link className=" link ">Privacy Policy</Link>
                </div>
            </div>
        </footer>


    );

}


/**
 * This file used for displaying error.
 */

import React from 'react';
import PropTypes from 'prop-types'

//Error handling screen
export default class ErrorBoundary extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node)
        ]).isRequired
    }
    state = { error: null, errorInfo: null, hashError: false };

    componentDidCatch(error, errorInfo) {
        this.setState({
            hashError: true,
            error: error,
            errorInfo: errorInfo
        })
    }
    render() {
        if (this.state.hashError) {
            return (
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div class="error-template">
                                    <h1 className='txtclr'><strong>Oops! </strong></h1>
                                    <h2>
                                        Something went wrong.
                                    </h2>
                                    <div class="txtclr">
                                        Sorry, an error has occured, Please try after some time!
                            </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}
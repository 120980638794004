/**
 * @About
 * This file mange environment of the project
 */

//dev
export const devSetting = {
  api: {
    url: "https://devapi.farechild.com/api/v1/",
    AUTH: "Basic ZGVtbzpkZW1v",
  },
};

//stag
export const stagSetting = {
  api: {
    url: "https://stagapi.farechild.com/api/v1/",
    AUTH: "Basic ZmFyZWNoaWxkX3N0YWc6ZmFyZWNoaWxkQHN0YWc=",
  },
};

//prod
export const prodSetting = {
  api: {
    url: "https://prodapi.farechild.com/api/v1/",
    AUTH: "Basic ZmFyZWNoaWxkX3Byb2Q6ZmFyZWNoaWxkQHByb2Q=",
  },
};

//local
export const localhostSetting = {
  api: {
    url: "https://devapi.farechild.com/api/v1/",
  },
};

import React from 'react';
import { Loader } from "../../../components/common/Loader";
import _ from 'lodash';
import { Select, DatePicker } from 'antd';
import Modal from 'react-bootstrap/Modal'
import { usersService } from '../../../services/ApiServices'
import {ResponseFilter} from '../../../_helper/ResponseHandler';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { message } from 'antd';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const { Option } = Select;
const dateFormat = 'MM-DD-YYYY';


const genderValue = type => {
    switch (type) {
        case 1:
            return 'Male';
            break;
        case 2:
            return 'Female';
            break;
        case 3:
            return 'Other';
    }
}

class Attendee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 5,
            sortOrder: "asc",
            sortBy: "created",
            page: 1,
            loader: false,
            openEditPopup: false,
            openAddPopup: false,
            eventId: this.props.eventId,
            name: '',
            email: '',
            phone: '',
            address: '',
            city: "",
            zip: '',
            state: '',
            gender: '',
            dob: '',
            ticketId: '',
            Price: '',
            quantity: '',
            lastName: '',
            firstName: '',
            userId: '',
            disabledBtn: false,
            emailErrorMsg: '',
            firstNameErrorMsg: '',
            lastNameErrorMsg: '',
            phoneErrorMsg: '',
            ticketIdErrorMsg: '',
            quantityErrorMsg: '',
            priceErrorMsg: ''

        }
    }

    componentDidMount() {
        console.log(this.state.eventId, "eventIdeventId");

        this.getTicketList();
        this.getAttendeesList();

    }
    handleSelectAddress = address => {
        geocodeByAddress(address)
            .then(results =>
                getLatLng(results[0]))
            .then(latLng => {
               let newaddress = address.split(',')
                this.setState({ lng: latLng.lng, lat: latLng.lat, address: newaddress[0]})
                // this.postalCode(latLng)

                let Url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=AIzaSyAjSodVOYLA8VVU2oOSToc7VtM8xKs13fI`

                fetch(Url)
                    .then(response => response.json())
                    .then((result) => {
                        // console.log("response>>", result.results);
                        let zipcode;
                        let state;
                        let city;

                        for (var i = 0; i < result.results.length; i++) {
                            for (var j = 0; j < result.results[i].address_components.length; j++) {

                                for (var k = 0; k < result.results[i].address_components[j].types.length; k++) {
                                    if (result.results[i].address_components[j].types[k] == "postal_code") {
                                        zipcode = result.results[i].address_components[j].short_name;
                                        //  state=result.results[i].address_components[j].long_name;
                                        //  city=result.results[i].address_components[j].long_name;
                                        console.log("state>>", city);
                                        if (zipcode) {
                                            this.setState({ zip: zipcode })
                                        }
                                        else {
                                            this.setState({ zip: '' })
                                        }
                                    }
                                    if (result.results[i].address_components[j].types[k] == "locality") {
                                        city = result.results[i].address_components[j].long_name;
                                        if (city) {
                                            this.setState({ city: city })
                                        }
                                        else {
                                            this.setState({ city: '' })
                                        }
                                    }
                                    if (result.results[i].address_components[j].types[k] == "administrative_area_level_1") {
                                        state = result.results[i].address_components[j].long_name;
                                        // console.log("state>>", state);
                                        if (zipcode) {
                                            this.setState({ state: state })
                                        }
                                        else {
                                            this.setState({ state: '' })
                                        }




                                    }




                                }


                            }
                        }



                    })
            }

            )
            .catch(error => console.error('Error', error));
    }

    handleChange1 = address => {
        this.setState({ address });
    };
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name + 'ErrorMsg']: '',

        });
    }

    handleSearch = e => {
        this.setState({ loader: false })
        this.setState({ search: e.target.value, page: 1 }, () => this.getAttendeesList());
    }

    changeLimit = (e) => {
        this.setState({ limit: e, page: 1 }, () => this.getAttendeesList());
    }

    handlePageChange = (page) => {
        this.setState({ page: page, loader: true }, () => this.getAttendeesList());
    }
    handleSort = e => {
        this.setState({
            loader: false, page: 1, sortBy: e, sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc"
        }, () => this.getAttendeesList());
    }
    handleGenderChange = e => {
        this.setState({ gender: e ? e : '' });
    }
    handleEditGenderChange = e => {
        this.setState({ gender: e ? parseInt(e) : '' })
    }
    handleTicketChange = e => {

        this.setState({ ticketId: e ? e : '', ticketIdErrorMsg: '', priceErrorMsg: '' });
    }
    handleOnChangePhone = value => {
        console.log(value);
        this.setState({ phone: value }, () => {
            console.log(this.state.phone);
        });
    };
    disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    handleSelectDobChange = date => {
        this.setState({
            dob: date ? moment(date).format('MM-DD-YYYY') : '' // Formatted String, ex: "11-16-2020"
        });
    }

    closeModal = () => {
        this.setState({
            openAddPopup: false,
            openEditPopup: false,
            name: '',
            disabledBtn: false,
            email: '',
            phone: '',
            address: '',
            city: '',
            zip: '',
            state: '',
            gender: '',
            dob: '',
            ticketId: '',
            price: '',
            quantity: '',
            lastName: '',
            firstName: '',
            userId: '',
            emailErrorMsg: '',
            firstNameErrorMsg: '',
            lastNameErrorMsg: '',
            phoneErrorMsg: '',
            ticketIdErrorMsg: '',
            quantityErrorMsg: '',
            priceErrorMsg: ''

        });
    }
    handleOpenAddPopup = () => this.setState({ openAddPopup: !this.state.openAddPopup });

    handleOpenEditPopup = ({

        attandeeEmail,
        name,
        attandeePhone,
        eventId,
        quantity,
        ticketName,
        ticketId,
        ticketPrice,
        userId,
        attandeedGender,
        attandeedDob,
        attandeeState,
        attandeeCity,
        attandeeAddress,
        attandeezip,
        _id,

    }) => this.setState({
        openEditPopup: !this.state.openEditPopup,
        email: attandeeEmail,
        phone: attandeePhone,
        eventId,
        quantity: quantity,
        ticketName,
        ticketId: ticketId,
        price: ticketPrice,
        userId,
        firstName: name ? name.split(' ').slice(0, -1).join(' ') : '',
        lastName: name ? name.split(' ').slice(-1).join(' ') : '',
        gender: attandeedGender,
        attandeeId: _id,
        dob: attandeedDob,
        zip: attandeezip,
        state: attandeeState,
        address: attandeeAddress,
        city: attandeeCity,


    });

    getTicketList = () => {
        let { search, eventId, limit, sortOrder, sortBy, page } = this.state;
        let obj = {
            eventId: this.props.eventId,
            search: search,
            limit: limit,
            sortOrder: sortOrder,
            sortBy: sortBy,
            page: page - 1
        }


        usersService.ticketList(obj).then(res => {
            let { status, resData } = ResponseFilter(res);
            if (status) {

                this.setState({
                    ticketsList: resData.result,
                    totalCount: resData.count,

                }

                )

            }

        })
    }
    getAttendeesList = () => {
        let { search, limit, page, sortOrder, sortBy } = this.state;


        let obj = {
            eventId: this.props.eventId,
            search: search,
            limit: limit,
            sortOrder: sortOrder,
            sortBy: sortBy,
            page: page - 1
        }

        this.setState({ loader: true })
        usersService.getAttendees(obj).then(res => {
            let { status, resData } = ResponseFilter(res);
            if (status) {
                this.setState({ loader: false })
                this.setState({
                    attendeesList: resData.result,
                    totalCount: resData.count,

                })
            }
            else {
                this.setState({ loader: false })
            }

        })
    }

    validateEmail(email) {
        const pattern = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
        const result = pattern.test(email);
        return result;
    }

    handleValidation = () => {
        let validate = true;
        let { firstName, lastName, email, phone, ticketId, quantity, price } = this.state;


        if (email === '' || email === undefined) {
            validate = false;
            this.setState({
                emailErrorMsg: 'Please enter email address.'
            })
        } else if (!this.validateEmail(String(email.toLowerCase()))) {
            validate = false;
            this.setState({ emailErrorMsg: 'Please enter a valid email address.' })
        } else {
            this.setState({
                emailErrorMsg: ''

            })
        }


        if (firstName === '' || firstName === undefined) {
            validate = false;
            this.setState({
                firstNameErrorMsg: 'Please enter first name.'
            })
        }
        else {
            this.setState({
                firstNameErrorMsg: ''
            })
        }

        if (lastName === '' || lastName === undefined) {
            validate = false;
            this.setState({
                lastNameErrorMsg: 'Please enter last name.'
            })
        }
        else {
            this.setState({
                lastNameErrorMsg: ''
            })
        }
        if (phone === '' || phone === undefined) {
            validate = false;
            this.setState({
                phoneErrorMsg: 'Please enter phone.'
            })
        }
        else {
            this.setState({
                phoneErrorMsg: ''
            })
        }

        if (ticketId === '' || ticketId === undefined) {
            validate = false;
            this.setState({
                ticketIdErrorMsg: 'Please select ticket.'
            })
        }
        else {
            this.setState({
                ticketIdErrorMsg: ''
            })
        }
        if (ticketId === '' || ticketId === undefined) {
            validate = false;
            this.setState({
                priceErrorMsg: 'Please enter price.'
            })
        }
        else {
            this.setState({
                priceErrorMsg: ''
            })
        }
        if (quantity === '' || quantity === undefined) {
            validate = false;
            this.setState({
                quantityErrorMsg: 'Please enter quantity.'
            })
        }
        else {
            this.setState({
                quantityErrorMsg: ''
            })
        }


        return validate
    }


    handleAddSubmit = event => {
        event.preventDefault();
        let self = this;
        if (this.handleValidation()) {

            let params = {
                'eventId': this.props.eventId,
                'name': this.state.firstName + " " + this.state.lastName,
                'email': this.state.email.toLowerCase(),
                'phone': this.state.phone,
                'gender': this.state.gender,
                'dob': this.state.dob ? moment(this.state.dob).format('YYYY-MM-DD') : '',
                'ticketId': this.state.ticketId,
                'quantity': this.state.quantity,
                'address': this.state.address,
                'city': this.state.city,
                'zip': this.state.zip,
                'state': this.state.state


            }
            const accessToken = localStorage.getItem('accessToken');
            this.setState({ disabledBtn: true });
            usersService.createAttendee(params, accessToken).then(res => {
                if (res.data.statusCode === 1) {
                    console.log(res, 'add res......')
                    message.success("Added Successfully!");
                    self.closeModal();
                    self.setState({
                        disabledBtn: false

                    }, () => this.getAttendeesList())

                }
                else {
                    self.closeModal();
                    self.setState({
                        disabledBtn: false

                    }, () => this.getAttendeesList())

                }
            })
        }
    }


    handleEditValidation = () => {
        let validate = true;
        let { firstName, lastName, phone, ticketId, quantity } = this.state;


        if (firstName === '' || firstName === undefined) {
            validate = false;
            this.setState({
                firstNameErrorMsg: 'Please enter first name.'
            })
        }
        else {
            this.setState({
                firstNameErrorMsg: ''

            })
        }

        if (lastName === '' || lastName === undefined) {
            validate = false;
            this.setState({
                lastNameErrorMsg: 'Please enter last name.'
            })
        }
        else {
            this.setState({
                lastNameErrorMsg: ''

            })
        }
        if (phone === '' || phone === undefined) {
            validate = false;
            this.setState({
                phoneErrorMsg: 'Please enter phone.'
            })
        }
        else {
            this.setState({
                phoneErrorMsg: ''
            })
        }
        if (ticketId === '' || ticketId === undefined) {
            validate = false;
            this.setState({
                ticketIdErrorMsg: 'Please select ticket.'
            })
        }
        else {
            this.setState({
                ticketIdErrorMsg: ''

            })
        }
        if (quantity === '' || quantity === undefined) {
            validate = false;
            this.setState({
                quantityErrorMsg: 'Please enter quantity.'
            })
        }
        else {
            this.setState({
                quantityErrorMsg: ''
            })
        }


        return validate
    }


    handleEditSubmit = event => {
        event.preventDefault();
        let self = this;
        if (this.handleEditValidation()) {

            let params = {
                'attandeeId': this.state.attandeeId,
                'userId': this.state.userId,
                'eventId': this.props.eventId,
                'ticketId': this.state.ticketId,
                'name': this.state.firstName + " " + this.state.lastName,
                'phone': this.state.phone,
                'gender': this.state.gender,
                'dob': this.state.dob ? moment(this.state.dob).format('YYYY-MM-DD') : '',
                'quantity': this.state.quantity,
                'address': this.state.address,
                'city': this.state.city,
                'zip': this.state.zip,
                'state': this.state.state


            }
            const accessToken = localStorage.getItem('accessToken');
            this.setState({ disabledBtn: true });
            usersService.updateAttendee(params, accessToken).then(res => {
                if (res.data.statusCode === 1) {
                    console.log(res, 'edit res......')
                    message.success("Updated Successfully!");
                    self.closeModal();
                    self.setState({
                        disabledBtn: false

                    }, () => this.getAttendeesList())

                }
                else {
                    self.closeModal();
                    self.setState({
                        disabledBtn: false

                    }, () => this.getAttendeesList())

                }
            })
        }
    }


    render() {

        let { loader, attendeesList, disabledBtn, emailErrorMsg, firstNameErrorMsg, lastNameErrorMsg,
            phoneErrorMsg, ticketIdErrorMsg,
            quantityErrorMsg, priceErrorMsg } = this.state;
        return (



            <React.Fragment>
                {loader ? <Loader /> : null}

                <div className="text-right">

                    <button className="btn btn-sm btn-primary mr-2" onClick={this.handleOpenAddPopup}><i className="fa fa-plus"></i> Add Attendee</button>
                </div>
                <div className="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details">


                    <div className="row">
                        <div className="col-6">
                            Show<Select
                                className="select-limit mr-1 ml-1"
                                value={this.state.limit}
                                defaultValue={this.state.limit}
                                style={{ width: 120 }}
                                onChange={this.changeLimit}
                            >
                                <Option value={5}>5</Option>
                                <Option value={10}>10</Option>
                                <Option value={15}>15</Option>

                            </Select>entries
							</div>
                        <div className="col-6 mb-3">

                            <div className="d-flex flex-row-reverse bd-highlight">



                                <div>
                                    <span className="search">Search:</span><span>
                                        <input
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}

                                            className="form-control search-box"
                                            name="search"
                                            placeholder="Search"
                                            type="search"
                                            value={this.state.search}
                                            onChange={this.handleSearch} /></span>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table id="example" className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>First<i class="fa fa-sort" aria-hidden="true" onClick={() => this.handleSort('name')}></i></th>
                                    <th>Last</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>QTY<i class="fa fa-sort" aria-hidden="true" onClick={() => this.handleSort('quantity')}></i></th>
                                    <th>Type</th>
                                    <th>Paid</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>

                                {

                                    !_.isEmpty(attendeesList) ?
                                        <React.Fragment>
                                            {attendeesList.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <td>{item.name ? item.name.split(' ').slice(0, -1).join(' ') : ''}</td>
                                                        <td>{item.name ? item.name.split(' ').slice(-1).join(' ') : ''}</td>
                                                        <td>{item.attandeeEmail ? item.attandeeEmail : ''}</td>
                                                        <td>{item.attandeePhone ? `+${item.attandeePhone}` : ''}</td>
                                                        <td>{item.quantity ? item.quantity : ''}</td>
                                                        <td>{item.ticketName ? item.ticketName : ''}</td>
                                                        <td>{item.ticketPrice ? `$${item.ticketPrice}` : 'Free'}</td>

                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-dark simple-ajax-popup-align-top" onClick={() => this.handleOpenEditPopup(item)}>Details</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </React.Fragment> : ''

                                }


                            </tbody>
                        </table>

                    </div>
                    {

                        !_.isEmpty(attendeesList) ? '' : <div className="text-center"><p className="mb-4 mb-sm-0 mt-4 ">No data present </p></div>

                    }
                </div>
                <div className="card-footer bg-white">
                    <div className="d-flex align-items-end justify-content-between flex-column flex-sm-row mt-4">
                        <p className="mb-3 mb-sm-0">Showing {this.state.page} to {this.state.limit} of {this.state.totalCount} tickets</p>

                        <nav>
                            <ul >
                                {/* {this.state.totalCount > 4 && !_.isEmpty(ticketsList) ? */}
                                <Pagination
                                    hideFirstLastPages={true}
                                    itemClassPrev="page-change"
                                    prevPageText="Previous"
                                    itemClassNext="page-change"
                                    nextPageText="Next"
                                    activePage={this.state.page}
                                    itemsCountPerPage={this.state.limit}
                                    totalItemsCount={this.state.totalCount}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange}
                                />
                                {/* : ''} */}
                            </ul>
                        </nav>

                    </div>
                </div>
                <Modal
                    size="lg"
                    show={this.state.openAddPopup}
                    onHide={this.closeModal}

                    aria-labelledby="example-modal-sizes-title-sm"

                >
                    <Modal.Header closeButton>

                        <h5 class="card-title mt-4">Add Attendee</h5>

                    </Modal.Header>



                    <div id="custom-content" class="white-popup-block">
                        <div class="card card-margin">

                            <div class="card-body">
                                <form class="forms-sample" onSubmit={this.handleAddSubmit}>

                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="exampleInputUsername12">First Name<span className="text-danger">*</span></label>
                                            <input type="text" class="form-control" maxLength="50" name="firstName" placeholder="First Name" value={this.state.firstName} onChange={this.handleChange} />
                                            {firstNameErrorMsg ? <div className="error ml-0">{firstNameErrorMsg}</div> : null}
                                        </div>
                                        <div class="form-group  col-md-6">
                                            <label for="exampleInputUsername12">Last Name<span className="text-danger">*</span></label>
                                            <input type="text" class="form-control" maxLength="50" id="event-sub" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.handleChange} />
                                            {lastNameErrorMsg ? <div className="error ml-0">{lastNameErrorMsg}</div> : null}
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="exampleInputUsername12">Email<span className="text-danger">*</span></label>
                                            <input type="text" class="form-control" id="event-name" placeholder="Email" name="email" value={this.state.email} onChange={this.handleChange} />
                                            {emailErrorMsg ? <div className="error ml-0">{emailErrorMsg}</div> : null}
                                        </div>
                                        <div class="form-group  col-md-6">
                                            <label for="exampleInputUsername12">Phone<span className="text-danger">*</span></label>
                                            <ReactPhoneInput
                                                class="form-control"
                                                inputExtraProps={{
                                                    name: "phone",
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                                country={"us"}
                                                value={this.state.phone}

                                                onChange={this.handleOnChangePhone}
                                            />
                                            {phoneErrorMsg ? <div className="error ml-0">{phoneErrorMsg}</div> : null}
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="form-row">
                                        <div class="form-group col-md-4">
                                            <label for="exampleInputUsername12">Ticket Type<span className="text-danger">*</span></label>
                                            <Select

                                                className="cm-select-box"
                                                defaultValue="Female"
                                                onChange={this.handleTicketChange}
                                                name="gender"
                                                value={this.state.ticketId ? this.state.ticketId : 'Select Ticket'}>
                                                {
                                                    !_.isEmpty(this.state.ticketsList) ?

                                                        this.state.ticketsList.map(item =>

                                                            <Option value={item._id}>{item.ticketName}</Option>

                                                        ) : ''
                                                }
                                            </Select>
                                            {ticketIdErrorMsg ? <div className="error ml-0">{ticketIdErrorMsg}</div> : null}
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="exampleInputUsername12">Price<span className="text-danger">*</span></label>

                                            <Select
                                                disabled
                                                className="cm-select-box"
                                                onChange={this.handleTicketChange}
                                                name="gender"
                                                value={this.state.ticketId ? this.state.ticketId : '$Price'}>
                                                {
                                                    !_.isEmpty(this.state.ticketsList) ?

                                                        this.state.ticketsList.map(item =>

                                                            <Option value={item._id}>{item.price ? (`$${item.price}`) : 'Free'}</Option>

                                                        ) : ''
                                                }
                                            </Select>
                                            {priceErrorMsg ? <div className="error ml-0">{priceErrorMsg}</div> : null}
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="exampleInputUsername12">Quantity<span className="text-danger">*</span></label>
                                            <input type="number" class="form-control" id="time" placeholder="Quantity" name="quantity" value={this.state.quantity} onChange={this.handleChange} />
                                            {quantityErrorMsg ? <div className="error ml-0">{quantityErrorMsg}</div> : null}
                                        </div>

                                    </div>

                                    <hr />
                                    <div class="form-group">
                                        <label class="font-weight-semibold" for="inputAddress">Address:</label>
                                        <PlacesAutocomplete
                                            value={this.state.address}
                                            onChange={this.handleChange1}
                                            onSelect={this.handleSelectAddress}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Search Places',
                                                            className: 'location-search-input form-control',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-4">
                                            <label class="font-weight-semibold" for="inputCity">City:</label>
                                            <input type="text" class="form-control" id="inputCity" placeholder="City" name="city" value={this.state.city} onChange={this.handleChange} />
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label class="font-weight-semibold" for="state">State:</label>
                                            <input type="text" class="form-control" id="inputCity" placeholder="State" name="state" value={this.state.state} onChange={this.handleChange} />
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label class="font-weight-semibold" for="inputZip">Zip:</label>
                                            <input type="text" class="form-control" id="inputCity" placeholder="Zip" name="zip" value={this.state.zip} onChange={this.handleChange} />

                                        </div>
                                    </div>
                                    <hr />

                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="exampleInputUsername12">Gender</label>
                                            <br />
                                            <Select
                                                className="cm-select-box"
                                                defaultValue="Female"
                                                onChange={this.handleGenderChange}
                                                name="gender"
                                                value={this.state.gender ? this.state.gender : 'Select Gender'}>
                                                <Option value="1">Male</Option>
                                                <Option value="2">Female</Option>
                                                <Option value="3">Other</Option>
                                            </Select>

                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="exampleInputUsername12">Birthdate</label>
                                            <DatePicker
                                                className="form-control"
                                                disabledDate={this.disabledDate}
                                                format={dateFormat}
                                                name="dob"
                                                id="dob"
                                                value={this.state.dob ? moment(this.state.dob) : ''}
                                                onChange={this.handleSelectDobChange} />


                                        </div>

                                    </div>
                                    <div class="card-footer bg-white">
                                        <button type="button" class="btn btn-light" onClick={this.closeModal}>Cancel</button>
                                        <button type="submit" class="btn btn-primary ml-2" disabled={disabledBtn}>Save</button>

                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </Modal>

                <Modal
                    size="lg"
                    show={this.state.openEditPopup}
                    onHide={this.closeModal}

                    aria-labelledby="example-modal-sizes-title-sm"

                >
                    <Modal.Header closeButton>
                        <h5 class="card-title mt-4">Edit Attendee</h5>
                    </Modal.Header>



                    <div id="custom-content" class="white-popup-block">
                        <div class="card card-margin">

                            <div class="card-body">
                                <form class="forms-sample" onSubmit={this.handleEditSubmit}>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="exampleInputUsername12">First Name<span className="text-danger">*</span></label>
                                            <input type="text" class="form-control" maxLength="50" name="firstName" placeholder="First Name" value={this.state.firstName} onChange={this.handleChange} />
                                            {firstNameErrorMsg ? <div className="error ml-0">{firstNameErrorMsg}</div> : null}
                                        </div>
                                        <div class="form-group  col-md-6">
                                            <label for="exampleInputUsername12">Last Name<span className="text-danger">*</span></label>
                                            <input type="text" class="form-control" maxLength="50" id="event-sub" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.handleChange} />
                                            {lastNameErrorMsg ? <div className="error ml-0">{lastNameErrorMsg}</div> : null}
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="exampleInputUsername12">Email<span className="text-danger">*</span></label>
                                            <input type="text" class="form-control" disabled id="event-name" placeholder="Email" name="email" value={this.state.email} onChange={this.handleChange} />

                                        </div>
                                        <div class="form-group  col-md-6">
                                            <label for="exampleInputUsername12">Phone<span className="text-danger">*</span></label>
                                            <ReactPhoneInput
                                                inputExtraProps={{
                                                    name: "phone",
                                                    required: true,
                                                    autoFocus: true,
                                                    autoFormat: true
                                                }}
                                                country={"us"}
                                                class="form-control"
                                                value={this.state.phone}
                                                onChange={this.handleOnChangePhone}
                                            />
                                            {phoneErrorMsg ? <div className="error ml-0">{phoneErrorMsg}</div> : null}
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="form-row">
                                        <div class="form-group col-md-4">
                                            <label for="exampleInputUsername12">Ticket Type<span className="text-danger">*</span></label>
                                            <Select
                                                allowClear={false}
                                                className="cm-select-box"
                                                defaultValue="Female"
                                                onChange={this.handleTicketChange}
                                                name="gender"
                                                value={this.state.ticketId ? this.state.ticketId : 'Select Ticket'}>
                                                {
                                                    !_.isEmpty(this.state.ticketsList) ?

                                                        this.state.ticketsList.map(item =>

                                                            <Option value={item._id}>{item.ticketName}</Option>

                                                        ) : ''
                                                }
                                            </Select>
                                            {ticketIdErrorMsg ? <div className="error ml-0">{ticketIdErrorMsg}</div> : null}
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="exampleInputUsername12">Price<span className="text-danger">*</span></label>

                                            <Select
                                                disabled
                                                className="cm-select-box"
                                                onChange={this.handleTicketChange}
                                                name="gender"
                                                value={this.state.ticketId ? this.state.ticketId : 'Price'}>
                                                {
                                                    !_.isEmpty(this.state.ticketsList) ?

                                                        this.state.ticketsList.map(item =>

                                                            <Option value={item._id}>{item.price ? (`$${item.price}`) : 'Free'}</Option>

                                                        ) : ''
                                                }
                                            </Select>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="exampleInputUsername12">Quantity<span className="text-danger">*</span>  </label>
                                            <input type="number" class="form-control" placeholder="Quantity" id="time" name="quantity" value={this.state.quantity} onChange={this.handleChange} />
                                            {quantityErrorMsg ? <div className="error ml-0">{quantityErrorMsg}</div> : null}
                                        </div>

                                    </div>

                                    <hr />
                                    <div class="form-group">
                                        <label class="font-weight-semibold" for="inputAddress">Address:</label>
                                        <PlacesAutocomplete
                                            value={this.state.address}
                                            onChange={this.handleChange1}
                                            onSelect={this.handleSelectAddress}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Search Places',
                                                            className: 'location-search-input form-control',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>

                                    <div className="form-row">
                                        <div class="form-group col-md-4">
                                            <label class="font-weight-semibold" for="inputCity">City:</label>

                                            <input type="text" className="form-control" id="inputCity" placeholder="City" name="city" value={this.state.city} onChange={this.handleChange} />
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label class="font-weight-semibold" for="state">State:</label>
                                            <input type="text" className="form-control" id="exampleOption14" placeholder="State" name="state" value={this.state.state} onChange={this.handleChange} />

                                        </div>

                                        <div className="form-group col-md-4">
                                            <label className="font-weight-semibold" for="inputZip">Zip:</label>
                                            <input type="number" className="form-control" id="inputZip" placeholder="Zip" name="zip" value={this.state.zip} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <hr />

                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="exampleInputUsername12">Gender</label>
                                            <br />
                                            <Select
                                                className="cm-select-box"
                                                onChange={(e) => this.handleEditGenderChange(e)}
                                                name="gender"
                                                value={this.state.gender ? genderValue(this.state.gender) : 'Select Gender'}>
                                                <Option value="1">Male</Option>
                                                <Option value="2">Female</Option>
                                                <Option value="3">Other</Option>
                                            </Select>


                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="exampleInputUsername12">Birthdate</label>
                                            <DatePicker
                                                className="form-control"
                                                disabledDate={this.disabledDate}
                                                format={dateFormat}
                                                allowClear={false}
                                                value={this.state.dob ? moment(this.state.dob) : ''}
                                                onChange={this.handleSelectDobChange} />




                                        </div>

                                    </div>
                                    <div class="card-footer bg-white">
                                        <button type="button" class="btn btn-light" onClick={this.closeModal}>Cancel</button>
                                        <button type="submit" class="btn btn-primary ml-2" disabled={disabledBtn}>Save</button>

                                    </div>
                                </form>

                            </div>

                        </div>


                    </div>


                </Modal>

            </React.Fragment>


        );
    }
}


export default Attendee;

import React from 'react';
import { Loader, Delete } from "../../../SvgIcon";
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal'
import { usersService } from '../../../services/ApiServices'
import {ResponseFilter} from '../../../_helper/ResponseHandler';
import { message } from 'antd';







class Coupon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.eventId,
            limit: '',
            List: [],
            sortOrder: "",
            sortBy: "created",
            page: 1,
            loader: false,
            openEditPopup: false,
            openAddPopup: false,
            openDeletePopup: false,
            userId: '',
            couponDetails: [{ discount: "", couponName: '', quantity: "", discountType: '' }],
            disabledBtn: false,
            discountType: '',
            discount: "",
            couponName: '',
            quantity: '',
            couponId: ''



        }
    }
    componentDidMount() {
        this.getCouponList();

    }
    closeModal = () => {
        this.setState({
            openEditPopup: false,
            openAddPopup: false,
            openDeletePopup: false,
            couponDetails: [{ discount: "", couponName: '', quantity: "", discountType: '' }],
            disabledBtn: false,
            discountType: '',
            discount: "",
            couponName: '',
            quantity: '',
            couponId: '',
            inputErrorMsg: ''

        });
    }
    handleOpenAddPopup = () => this.setState({ openAddPopup: !this.state.openAddPopup });
    handleOpenDeletePopup = couponId => this.setState({ openDeletePopup: !this.state.openDeletePopup, couponId });


    handleOpenEditPopup = ({
        couponName,
        quantity,
        discountType,
        discount,
        _id,

    }) => this.setState({
        openEditPopup: !this.state.openEditPopup,
        couponName,
        quantity,
        discountType,
        discount,
        couponId: _id
    });


    handleChangeInput(i, e) {

        const { name, value } = e.target;

        let couponDetails = [...this.state.couponDetails];
        couponDetails[i] = { ...couponDetails[i], [name]: value };

        this.setState({ couponDetails, [e.target.name + 'ErrorMsg']: '', inputErrorMsg: '' });
    }

    handleChangeDiscountType(i, e) {
        let { couponDetails } = this.state
        couponDetails[i].discountType = e
        this.setState({ couponDetails, inputErrorMsg: '' })


    }

    handleChange = e => this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: '',
        inputErrorMsg: ''

    });
    handleEditDiscountType = (e) => {
        this.setState({
            'discountType': e,
            inputErrorMsg: ''
        })

    }

    addClick() {
        this.setState(prevState => ({
            couponDetails: [...prevState.couponDetails, { ticketName: '', discountType: '', quantity: '' }]
        }))
    }
    removeClick(i) {
        let couponDetails = [...this.state.couponDetails];
        couponDetails.splice(i, 1);
        this.setState({ couponDetails });
    }

    handleValidation() {
        var isValid = true;
        let { couponDetails, } = this.state;

        couponDetails.map((item, index) => {


            if (couponDetails[index].couponName === '' || couponDetails[index].couponName === undefined) {
                isValid = false;
                this.setState({
                    inputErrorMsg: 'Please fill all the mandatory fields.'
                })
            }

            if (couponDetails[index].quantity === '' || couponDetails[index].quantity === undefined) {
                isValid = false;
                this.setState({
                    inputErrorMsg: 'Please fill all the mandatory fields.'
                })
            }


            if (couponDetails[index].discount === '' || couponDetails[index].discount === undefined) {
                isValid = false;
                this.setState({
                    inputErrorMsg: 'Please fill all the mandatory fields.'
                })
            }
            if (couponDetails[index].discountType === '' || couponDetails[index].discountType === undefined) {
                isValid = false;
                this.setState({
                    inputErrorMsg: 'Please fill all the mandatory fields.'
                })
            }
            


        });
        return isValid;
    }

    handleEditValidetion = () => {
        let validate = true;
        let { couponName, quantity, discountType, discount } = this.state;
        if (couponName === '' || couponName === undefined) {
            validate = false;
            this.setState({
                inputErrorMsg: 'Please fill all the mandatory fields.'
            })
        }
        if (quantity === '' || quantity === undefined) {
            validate = false;
            this.setState({
                inputErrorMsg: 'Please fill all the mandatory fields.'
            })

        }

        if (discountType === '' || discountType === undefined) {
            validate = false;
            this.setState({
                inputErrorMsg: 'Please fill all the mandatory fields.'
            })
        }

        if (discount === '' || discount === undefined) {
            validate = false;
            this.setState({
                inputErrorMsg: 'Please fill all the mandatory fields.'
            })
        }
       

        return validate
    }

    getCouponList = () => {

        let { search, limit, page, sortOrder, sortBy } = this.state;


        let obj = {
            eventId: this.props.eventId,
            search: search,
            limit: limit,
            sortOrder: sortOrder,
            sortBy: sortBy,
            page: page - 1
        }

        this.setState({ loader: true })
        usersService.couponList(obj).then(res => {
            console.log(res, 'kkkkkkkkk>>>>>>>>')
            let { status, resData } = ResponseFilter(res);
            if (status) {
                this.setState({ loader: false })
                this.setState({
                    List: resData.result,
                    totalCount: resData.count,

                }, () => console.log(this.state.List, 'kkkkkkkkk'))

            }
            else {
                this.setState({ loader: false })
            }

        })
    }
    handleAddSubmit = event => {
        event.preventDefault();
        let self = this;
        if (this.handleValidation()) {

            let params = {
                'eventId': this.props.eventId,
                'couponDetails': JSON.stringify(this.state.couponDetails)


            }
            const accessToken = localStorage.getItem('accessToken');
            this.setState({ disabledBtn: true });
            usersService.createCoupon(params, accessToken).then(res => {
                if (res.data.statusCode === 1) {
                    console.log(res, 'add res......')
                    message.success("Added Successfully!");
                    self.closeModal();
                    self.setState({
                        disabledBtn: false

                    }, () => this.getCouponList())

                }
                else {
                    self.closeModal();
                    self.setState({
                        disabledBtn: false

                    }, () => this.getCouponList())

                }
            })
        }
    }

    handleEditSubmit = event => {
        event.preventDefault();
        if (this.handleEditValidetion()) {
            let params = {

                "couponId": this.state.couponId,
                "couponName": this.state.couponName,
                "discountType": (this.state.discountType).toString(),
                "quantity": (this.state.quantity).toString(),
                "discount": (this.state.discount).toString(),

            }
            const accessToken = localStorage.getItem('accessToken');
            usersService.updateCoupon(params, accessToken).then(res => {
                console.log(res, 'edit res......')
                if (res.data.statusCode === 1) {
                    message.success("Updated Successfully!");
                    this.closeModal();
                    this.setState({
                        openEditPopup: false
                    }, () => this.getCouponList())
                }
                else {
                    this.closeModal();
                    this.setState({
                        openEditPopup: false
                    }, () => this.getCouponList())
                }
            })
        }
    }
    handleDelete = (data) => {
        let obj = {
            'couponId': this.state.couponId,
            'status': 2
        }
        const accessToken = localStorage.getItem('accessToken');
        usersService.deleteCoupon(obj, accessToken).then(res => {
            if (res.data.statusCode === 1) {
                message.success("Delete Successfully!");
                this.setState({
                    openDeletePopup: false
                }, () => this.getCouponList())
            }
            else {
                this.setState({
                    openDeletePopup: false
                })
            }
        })
    }


    render() {
        let { List, disabledBtn, } = this.state;
        return (



            <React.Fragment>

                <div className="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details">
                    <div className="col-lg-12 card-margin">
                        <div className="card ">
                            <div className="card-header">
                                <div className="col-md-8"	>
                                    <h6 className="card-title m-0">Event Codes</h6>
                                </div>
                                <div className="col-md-4 pr-0">
                                    <button type="button" className="btn btn-sm btn-primary pull-right white" onClick={this.handleOpenAddPopup}><i className="fa fa-plus p-t-4"></i> Create Coupon Code</button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <button type="submit" className="btn btn-sm btn-outline-primary form-sbt float-right ml-2">Search</button>
                                    <table id="data-table-2" className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th> <span className="cm_col">Name</span></th>
                                                <th>Redeemed</th>
                                                <th>Availabl</th>
                                                <th> <span className="cm_col">Total</span></th>
                                                <th> <span className="cm_col">Discount</span></th>
                                                <th>Delete</th>
                                                <th>Save</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {

                                                !_.isEmpty(List) ?
                                                    <React.Fragment>
                                                        {List.map((item, i) => {
                                                            return (
                                                                <tr>
                                                                    <td className="cm_bold"><strong><span className="cm_col">{item.couponName}</span></strong></td>
                                                                    <td>0</td>
                                                                    <td>{item.quantity}</td>
                                                                    <td><span className="cm_col">{item.quantity}</span></td>


                                                                    <td>
                                                                        <div className="row">
                                                                            <div className="mt-2 col-md-2"><span className="cm_col">{item.discount}</span></div>
                                                                            <div className="col-md-8 mt-2">
                                                                                <div className="string-check-inline">
                                                                                    <div className="string-check string-check-bordered-primary string-check-inline">
                                                                                        <input type="radio" className="form-check-input" id="formRadioInput11" checked={item.discountType === 1 ? true : false} />
                                                                                        <label className="string-check-label" for="formRadioInput11">
                                                                                            <span className="ml-2"><small>%</small></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="string-check string-check-bordered-primary">
                                                                                        <input type="radio" className="form-check-input" id="formRadioInput12" checked={item.discountType === 2 ? true : false} />
                                                                                        <label className="string-check-label" for="formRadioInput12">
                                                                                            <span className="ml-2"><small>$</small></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td >
                                                                        <span onClick={() => this.handleOpenDeletePopup(item._id)}><Delete /></span>
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" className="btn btn-sm btn-dark" onClick={() => this.handleOpenEditPopup(item)}>Update</button>
                                                                    </td>
                                                                </tr>

                                                            )
                                                        })}
                                                    </React.Fragment> : ''

                                            }



                                        </tbody>
                                    </table>
                                    {

                                        !_.isEmpty(this.state.List) ? '' : <div className="text-center"><p className="mb-4 mb-sm-0 mt-4 ">No data present </p></div>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    size="md"
                    show={this.state.openDeletePopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"

                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <div className="cm_modal">


                        <div className="modal-body">
                            <h4 className="mb-4">Are you sure, you want delete this coupon?</h4>
                            <br />

                            <div className="bg-white pull-right mb-4">
                                <button className="btn btn-light" type="button" onClick={this.closeModal}>No</button>
                                <button type="submit" className="btn btn-primary ml-2" onClick={() => this.handleDelete(List)}>Yes</button>

                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </Modal>
                <Modal
                    size="lg"
                    show={this.state.openAddPopup}
                    onHide={this.closeModal}

                    aria-labelledby="example-modal-sizes-title-sm"

                >
                    <Modal.Header closeButton>
                    <h5 className="card-title mt-4">Add Coupon</h5>
                    </Modal.Header>

                    <form>

                        <div className="card-margin">
                            {this.state.inputErrorMsg ? <p className="error mt-0">{this.state.inputErrorMsg}</p> : null}

                            <div className="card-body">

                                <div className="table-responsive">
                                    <table id="data-table-2" className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Name<span className="text-danger">*</span></th>
                                                <th>Quantity<span className="text-danger">*</span></th>
                                                <th>Discount<span className="text-danger">*</span></th>
                                                <th>Remove</th>
                                            </tr>
                                        </thead>



                                        {this.state.couponDetails.map((el, i) => (
                                            <tr key={i}>
                                                <td><input type="text" maxLength="50" class="form-control" id="row-1-position" placeholder="Name" name="couponName" value={el.couponName || ''} onChange={this.handleChangeInput.bind(this, i)} /></td>

                                                <td><input type="number" class="form-control" id="row-1-position" name="quantity" placeholder="Quantity" value={el.quantity || ''} onChange={this.handleChangeInput.bind(this, i)} /></td>

                                                <td class="d-flex flex-row">

                                                    <input type="number" class="form-control mr-3" id="row-1-position"
                                                        name="discount" placeholder="Discount" value={el.discount || ''} onChange={this.handleChangeInput.bind(this, i)} />

                                                    <div class="string-check string-check-bordered-primary string-check-inline mr-2 mt-2">
                                                        <input type="radio" class="form-check-input"
                                                            id="formRadioInput11" value={el.discountType || ''} onChange={(e) => this.handleChangeDiscountType(i, 1)} checked={el.discountType === 1 ? true : false} />
                                                        <label class="string-check-label"
                                                            for="formRadioInput11">
                                                            <span class=""><small>%</small></span>
                                                        </label>
                                                    </div>
                                                    <div class="string-check string-check-bordered-primary ml-1 mt-2">
                                                        <input type="radio" class="form-check-input"
                                                            id="formRadioInput12" value={el.discountType || ''} onChange={(e) => this.handleChangeDiscountType(i, 2)} checked={el.discountType === 2 ? true : false} />
                                                        <label class="string-check-label"
                                                            for="formRadioInput12">
                                                            <span class=""><small>$</small></span>
                                                        </label>
                                                    </div>


                                                </td>
                                                <td>
                                                    <button type="button" value='remove' className="btn btn-sm btn-dark" onClick={this.removeClick.bind(this, i)}>Remove</button>
                                                </td>


                                            </tr>




                                        ))}

                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                            <td>
                                                <button type="button" className="btn btn-sm btn-dark" onClick={this.addClick.bind(this)}><i className="fa fa-plus add-input" ></i>Add</button>

                                            </td>



                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="card-footer bg-white">
                                <button className="btn btn-light" type="button" onClick={this.closeModal}>Cancel</button>
                                <button type="button" className="btn btn-primary ml-2" onClick={this.handleAddSubmit} disabled={disabledBtn}>Save</button>


                            </div>
                        </div>


                    </form>
                </Modal>
                <Modal
                    size="lg"
                    show={this.state.openEditPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"

                >
                    <Modal.Header closeButton>
                    <h5 className="card-title mt-4">Edit Coupon</h5>
                    </Modal.Header>

                    <form onSubmit={this.handleEditSubmit}>

                        <div className="card-margin">
                            
                            {this.state.inputErrorMsg ? <p className="error mt-0">{this.state.inputErrorMsg}</p> : null}

                            <div className="card-body">

                                <div className="table-responsive">

                                    <table id="data-table-2" className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Name<span className="text-danger">*</span></th>
                                                <th>Quantity<span className="text-danger">*</span></th>
                                                <th>Discount<span className="text-danger">*</span></th>


                                            </tr>
                                        </thead>
                                        <tr>
                                            <td><input type="text" class="form-control" id="row-1-position" placeholder="Name" name="couponName" value={this.state.couponName} onChange={this.handleChange} /></td>

                                            <td><input type="text" class="form-control" id="row-1-position" placeholder="Quantity" name="quantity" value={this.state.quantity} onChange={this.handleChange} /></td>

                                            <td class="d-flex flex-row">

                                                <input type="text" class="form-control mr-3" id="row-1-position" placeholder="Discount" name="discount" value={this.state.discount} onChange={this.handleChange} />

                                                <div class="string-check string-check-bordered-primary string-check-inline mr-2 mt-2">
                                                    <input type="radio" class="form-check-input" checked={this.state.discountType === 1 ? true : false} value={this.state.discountType} onChange={(e) => this.handleEditDiscountType(1)} />
                                                    <label class="string-check-label"
                                                        for="formRadioInput11">
                                                        <span class=""><small>%</small></span>
                                                    </label>
                                                </div>
                                                <div class="string-check string-check-bordered-primary ml-1 mt-2">
                                                    <input type="radio" class="form-check-input" checked={this.state.discountType === 2 ? true : false} value={this.state.discountType} onChange={(e) => this.handleEditDiscountType(2)} />

                                                    <label class="string-check-label"
                                                        for="formRadioInput12">
                                                        <span class=""><small>$</small></span>
                                                    </label>
                                                </div>


                                            </td>


                                        </tr>

                                    </table>
                                </div>
                            </div>
                            <div className="card-footer bg-white">
                                <button className="btn btn-light" type="button" onClick={this.closeModal}>Cancel</button>
                                <button type="submit" className="btn btn-primary ml-2" disabled={disabledBtn}  >Save</button>


                            </div>
                        </div>


                    </form>
                </Modal>
            </React.Fragment>


        );
    }
}


export default Coupon;

import React from 'react';
import TopBar from '../../components/template/TopBar';
import Footer from '../../components/template/Footer';
import SideNav from '../../components/template/SideNav';
import Coupon from '../eventManagemnt/SubComponents/Coupon';
import Attendee from '../eventManagemnt/SubComponents/Attandee';
import EditDetails from '../eventManagemnt/SubComponents/EditDetails';
import EditTicket from '../eventManagemnt/SubComponents/EditTickets';
import UpdateEdit from '../eventManagemnt/SubComponents/UpdateEdit';
import { Tab, Tabs } from 'react-bootstrap'
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';
import { usersService } from "../../services/ApiServices";
import _ from 'lodash';
import { ResponseFilter } from '../../_helper/ResponseHandler';
import { message } from 'antd';
import { Link } from 'react-router-dom'
import { University, Details, Updates, AttendeeIcon, Checkout, Tickets, CouponIcon, Privacy, Payout } from "../../SvgIcon";
import eventDetails from './eventDetails';

const accountType = type => {
    switch (type) {
        case 1:
            return 'Bank Account';
            break;
        case 2:
            return 'Paypal';
            break;
        case 3:
            return 'Venmo';
    }
}


class EventEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            payoutList: [],
            eventPayoutId: '',
            phone: 0,
            gender: 0,
            dob: 0,
            address: 0,
            city: 0,
            state: 0,
            zipcode: 0,
            eventType: '',
            disabledBtn: false,
            successPageMessage: '',
            theme: 'snow',
            msgErrorMsg: '',
            eventId: this.props.match.params.id,
            passwordErrorMsg: '',
            password: "",
            publicType: false,
            publicEventCheck: false,
            noChecK: true,
            privateEventCheck: false,
            privateEventProtectedCheck: false,
            privateTypeLink: false,
            privatetype: false,
            privateTypePassword: false,
        }
        this.updateCheckBox = this.updateCheckBox.bind(this)
        this.handleChangeEditor = this.handleChangeEditor.bind(this)
    }

    handleChangeEditor(html) {
        this.setState({ successPageMessage: html, msgErrorMsg: '' });
    }
    componentDidMount() {
        console.log(this.props.match.params.id);
        this.getPayoutDetails();
        this.getEventDetails();


    }
    getEventDetails = () => {
        let obj = {
            eventId: this.props.match.params.id,

        }

        usersService.viewEventDetails(obj).then(res => {
            let { status, resData } = ResponseFilter(res);

            if (status) {

                let {
                    privateType,
                    typeOfEvent,
                    eventPassword
                } = resData.result;

                this.setState({

                    publicType: typeOfEvent == 1 ? true : false,
                    privateTypePassword: typeOfEvent == 2 || (privateType === [2] && privateType === [1, 2]) ? true : false,
                    privateTypeLink: typeOfEvent == 2 || (privateType === [1] && privateType === [1, 2]) ? true : false,
                    password: eventPassword


                })


            }

        })
    }
    getPayoutDetails = () => {

        // let obj = {
        //     eventId: this.props.match.params.id,

        // }

        usersService.getPayout().then(res => {
            let { status, resData } = ResponseFilter(res);

            if (status) {

                this.setState({
                    payoutList: resData.result,

                })


            }

        })
    }


    clearSuccessPageMessage = () => {

        this.setState({
            successPageMessage: '',

        })
    }

    handleValidation = () => {
        let validate = true;
        let { successPageMessage } = this.state;


        if (successPageMessage === '' || successPageMessage === undefined) {
            validate = false;
            this.setState({
                msgErrorMsg: 'Please enter success message.'
            })
        }
        else {
            this.setState({
                msgErrorMsg: ''
            })
        }


        return validate
    }


    handleSuccessMessage = event => {
        event.preventDefault();
        let self = this;
        if (this.handleValidation()) {

            let params = {
                'eventId': this.props.match.params.id,
                'successPageMessage': this.state.successPageMessage


            }
            const accessToken = localStorage.getItem('accessToken');
            this.setState({ disabledBtn: true });
            usersService.successPageMessage(params, accessToken).then(res => {
                if (res.data.statusCode === 1) {
                    console.log(res, 'add res......')
                    message.success("The Message has been successfully submitted.");
                    self.setState({
                        disabledBtn: false,
                        successPageMessage: ''

                    })

                }
                else {

                    self.setState({
                        disabledBtn: false

                    })

                }
            })
        }
    }
    handleManageEventPayout = event => {
        event.preventDefault();
        let self = this;
        {
            let params = {
                'eventId': this.props.match.params.id,
                'eventPayout': this.state.eventPayoutId
            }
            const accessToken = localStorage.getItem('accessToken');
            this.setState({ disabledBtn: true });
            usersService.manageEventPayout(params, accessToken).then(res => {
                if (res.data.statusCode === 1) {
                    
                    message.success("successfully updated.");
                    self.setState({
                        disabledBtn: false,
                        successPageMessage: ''
                    })

                }
                else {

                    self.setState({
                        disabledBtn: false

                    })

                }
            })
        }
    }

    handleChangeId = (eventPayoutId) => {
        console.log(eventPayoutId, 'eventPayoutIdeventPayoutId');

        this.setState({
            'eventPayoutId': eventPayoutId
        })

    }

    handleCheckoutFieldsSubmit = event => {
        event.preventDefault();
        let self = this;
        {

            let params = {
                'eventId': this.props.match.params.id,
                'checkoutFields': JSON.stringify({
                    "phone": this.state.phone,
                    "gender": this.state.gender,
                    "dob": this.state.dob,
                    "address": this.state.address,
                    "city": this.state.city,
                    "state": this.state.state,
                    "zipcode": this.state.zipcode
                })


            }
            const accessToken = localStorage.getItem('accessToken');
            this.setState({ disabledBtn: true });
            usersService.checkoutFields(params, accessToken).then(res => {
                if (res.data.statusCode === 1) {
                    console.log(res, 'add res......')
                    message.success("Fields managed successfully.");
                    self.setState({
                        disabledBtn: false,

                    })

                }
                else {

                    self.setState({
                        disabledBtn: false

                    })

                }
            })
        }
    }



    handleCheckBox = e => this.setState({
        [e.target.name]: e.target.checked ? 1 : 0,
        [e.target.name + 'ErrorMsg']: '',
        inputErrorMsg: ''

    });


    handleEditPrivacyEvent = () => {
        let { publicType, privateTypePassword, privateTypeLink, password } = this.state;
        let typeOfEvent = {
            type: publicType ? 1 : privateTypePassword ? 2 : privateTypeLink ? 2 : '',
            privateType: privateTypePassword && !privateTypeLink ? [2] : !privateTypePassword && privateTypeLink ? [1] : privateTypePassword && privateTypeLink ? [1, 2] : '',
            eventPassword: privateTypePassword ? password : ''
        }
        if (this.validationEventType()) {
            let params = {
                'eventId': this.props.match.params.id,
                'typeOfEvent': JSON.stringify(typeOfEvent)

            }
            let token = localStorage.getItem('accessToken')
            this.setState({ disabledBtn: true });

            usersService.updatePrivacy(params, token).then(data => {
                if (data.data.statusCode === 1) {
                    message.success('Updated Successfully!', 7)
                    this.setState({ disabledBtn: false });
                    this.getEventDetails();

                }
                else {
                    this.getEventDetails();
                    this.setState({
                        errors: data.data.error.responseMessage,
                        miniLoader: false,
                        disabledBtn: false

                    })
                }
            });
        }
    }
    validationEventType = () => {
        var validate = true;
        let { type, privateTypePassword, privateTypeLink, password } = this.state;

        if (privateTypePassword) {

            if (this.state.password === '' || this.state.password === undefined) {
                validate = false;
                this.setState({
                    passwordErrorMsg: 'Please enter your password.'
                })
            } else if (this.state.password.length <= 5) {
                validate = false;

                this.setState({
                    passwordErrorMsg: ' Please enter minimum 6 characters.'
                })
            }

        }


        return validate;
    }
    updateCheckBox(event) {
        console.log(event.target.value, 'dfkjhdsgfsdsdfsdfdshkfdsfs');
        this.setState({
            publicType: !this.state.publicType,
            password: '',
            privateTypePassword: false,
            privateTypeLink: false,
            privatetype: false
        })
    }
    handleInputChange = (event) => {
        if (event.target.name == 'privateTypeLink') {
            this.setState({
                privateTypeLink: !this.state.privateTypeLink,
                publicType: false
            });
        }
        if (event.target.name == 'privateTypePassword') {
            this.setState({
                privateTypePassword: !this.state.privateTypePassword,
                publicType: false,
                privatetype: !this.state.privatetype
            });
        }
    }

    handleChange = e => this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: '',
        inputErrorMsg: ''

    });
    render() {
        let { payoutList, eventId, msgErrorMsg, disabledBtn, passwordErrorMsg } = this.state;
        console.log(this.state, 'lkjhf>>>>>>>>>');


        return (
            <body className="dark-sidebar">
                <div className="main-container">
                    <div className="container-fluid page-body-wrapper">
                        <nav className="navbar fixed-top"><div className="navbar-menu-container d-flex align-items-center justify-content-center">
                            <div className="sub-heading">
                                <h4 className="page-title ">MY EVENTS</h4>
                            </div>
                            <div className="text-center navbar-brand-container align-items-center justify-content-center">

                                <a className="brand-logo" href="/">	<img src={require('../../assets/images/mobile-logo.png')} alt="Event 3" className="mb-3" />
                                </a>
                            </div>

                            <TopBar
                                {...this.props}
                            />


                        </div>

                        </nav>

                        <SideNav />
                        <div className="main-panel">
                            <div className="content-wrapper manage-event">
                                <div className="row">
                                    <div className="col-md-12">


                                        <div className="card card-margin">
                                            <div className="card-header">
                                                <div className="col-md-10">
                                                    <h5 className="card-title">Edit Your Event</h5>
                                                </div>
                                                <div className="col-md-2 pull-right m-l-20">
                                                    {/**
                                                         <Link type="button" onClick={() => this.openEventPage(eventId)} className="btn btn-sm btn-dark">View Event Page</Link>
                                                     */}

                                                </div>
                                            </div>
                                            
                                            <div className="card-body">
                                               <EditDetails eventId={eventId}/>





                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>

                    </div>
                </div>




            </body >


        );
    }
}


export default EventEdit;
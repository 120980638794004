import React, { useReducer, useEffect } from 'react';
import { validateEmail, validateName, validateMobileNo, validateMobileNo1 } from '../../components/common/Validation';
import InlineError from '../../components/common/InlineError';
import TopBar from '../../components/template/TopBar'
import SideNav from '../../components/template/SideNav'
import Footer from '../../components/template/Footer'
import { MySpinner } from '../../components/common/Loader';
import { notification } from 'antd'
import { userActions } from '../../_action/UserManage'
import { connect } from 'react-redux'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { set } from 'lodash';




function AddAttandee(props) {
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            firstNameError: '',
            lastNameError: '',
            phoneNumberError: '',
            emailError: '',
            addressError: '',
            address2Error: '',
            cityError: '',
            stateError: '',
            zipCodeError: '',
            dob: '',
            dobError: '',
            gender: '',
            genderError: '',
            loading: false,
            isEdit: props.match.params.id,
        })

    //onChange
    const handleChange = (e) => {
        console.log('eeeeeeeeeee', city, cityError);
        setUserInput({
            ...userInput, [e.target.name]: e.target.value,
            [e.target.name + 'Error']: '',
            //  firstNameError: '', lastNameError: '',
            // phoneNumberError: '', emailError: '', addressError: '', address2Error: '',
            cityError: '', stateError: '', zipCodeError: '',
        })
    }
    const handleChangeaddress = address => {
        setUserInput({ ...userInput, address, addressError: '', cityError: '', stateError: '', zipCodeError: '', });
    };
    //redirect 
    const reDirectTo = () => {
        props.history.goBack()
    }
    useEffect(() => {
        if (isEdit) {
            getParticularAttendee()
        }
        else { }

    }, [])

    const getParticularAttendee = () => {

        let req = {
            userId: props.match.params.id
        }
        console.log(props.match.params.id, 'iddddddddd');
        let { dispatch } = props;
        setUserInput({ loading: true })
        dispatch(userActions.getParticularAttendee(req)).then(data => {
            if (data?.statusCode === 1) {
                let name = data?.responseData?.result?.name?.split(' ')
                let {
                    email,
                    phone,
                    address,
                    address2,
                    state,
                    city,
                    zip,
                    gender,
                    dob
                } = data?.responseData?.result
                setUserInput({ loading: false, firstName: name[0], lastName: `${name[1] ? name[1] : ''} ${name[2] ? name[2] : ''}`, phoneNumber: phone, email, address: address, address2, state, zipCode: zip, city, gender, dob })
                console.log('valallalal',)
            }
            else {
                notification.error({ message: 'Error', description: data?.error?.responseMessage })
                setUserInput({
                    loading: false
                })
            }
        })

    }
    const handleValidation = () => {
        let validate = true;

        const { firstName, lastName, phoneNumber, email, address,
            city, state, zipCode, dob, gender } = userInput
        if (firstName === '') {
            validate = false;
            setUserInput({ firstNameError: '*please enter first name.' })
        }
        else if (!validateName(firstName).status) {
            validate = false;
            setUserInput({ firstNameError: '*please enter valid name.' })
        } else {
            setUserInput({ firstNameError: '' })
        }
        if (lastName === '') {
            validate = false;
            setUserInput({ lastNameError: '*please enter last name.' })
        }
        else if (!validateName(lastName).status) {
            validate = false;
            setUserInput({ lastNameError: '*please enter valid name.' })
        } else {
            setUserInput({ lastNameError: '' })
        }

        if (phoneNumber === '') {
            validate = false;
            setUserInput({ phoneNumberError: '*please enter phone number.' })
        }
        else if (!validateMobileNo1(phoneNumber).status) {
            validate = false;
            setUserInput({ phoneNumberError: '*please enter valid phone number.' })
        } else {
            setUserInput({ phoneNumberError: '' })

        }
        if (email === '' || email === undefined) {
            validate = false;
            setUserInput({
                emailError: '*please enter your email.'
            })
        } else if (!validateEmail(email).status) {
            validate = false;
            setUserInput({ emailError: '*please enter valid email.' })
        } else {
            setUserInput({
                emailError: ''
            })
        }
        if (address === '') {
            validate = false;
            setUserInput({ addressError: '*please enter address.' })
        }
        else {
            setUserInput({ addressError: '' })
        }
        if (city === '') {
            validate = false;
            setUserInput({ cityError: '*please enter city.' })
        }
        else {
            setUserInput({ cityError: '' })
        }
        if (state === '') {
            validate = false;
            setUserInput({ stateError: '*please enter state.' })
        } else {
            setUserInput({ stateError: '' })

        }
        if (zipCode === '') {
            validate = false;
            setUserInput({ zipCodeError: '*please enter zip code.' })
        } else {
            setUserInput({ zipCodeError: '' })
        }

        return validate
    }

    //handle add attendee submit
    const handleAddAttendee = () => {
        const { firstName, lastName, phoneNumber, email, address,
            city, state, zipCode, dob, gender } = userInput
        if (handleValidation()) {
            // if (firstName === '')
            //     setUserInput({ firstNameError: '*please enter first name.' })
            // if (!validateName(firstName).status)
            //     setUserInput({ firstNameError: '*please enter valid name.' })
            // if (lastName === '')
            //     setUserInput({ lastNameError: '*please enter last name.' })
            // if (!validateName(lastName).status)
            //     setUserInput({ lastNameError: '*please enter valid name.' })
            // if (phoneNumber === '')
            //     setUserInput({ phoneNumberError: '*please enter phone number.' })
            // if (!validateMobileNo1(phoneNumber).status)
            //     setUserInput({ phoneNumberError: '*please enter valid phone number.' })
            // if (email === '')
            //     setUserInput({ emailError: '*please enter email.' })
            // if (!validateEmail(email).status)
            //     setUserInput({ emailError: '*please enter valid email.' })
            // if (address === '')
            //     setUserInput({ addressError: '*please enter address.' })
            // if (city === '')
            //     setUserInput({ cityError: '*please enter city.' })
            // if (state === '')
            //     setUserInput({ stateError: '*please enter state.' })
            // if (zipCode === '')
            //     setUserInput({ zipCodeError: '*please enter zip code.' })
            // else {

            let req = {
                name: `${firstName} ${lastName} `,
                phone: phoneNumber,
                email: email,
                address: address,
                address2: address2,
                city: city,
                state: state,
                zip: zipCode,
                dob: dob,
                gender: gender,
            }
            let { dispatch } = props;
            setUserInput({ loading: true })
            dispatch(userActions.addAttendee(req)).then(data => {
                if (data?.statusCode === 1) {
                    props.history.goBack()
                    setUserInput({ loading: false })
                }
                else {
                    notification.error({ message: 'Error', description: data?.error?.responseMessage })
                    setUserInput({
                        loading: false
                    })
                }
            })
            // }
        }
    }

    //handle add user submit
    const handleEditAttendee = () => {
        const { firstName, lastName, phoneNumber, email, address,
            city, state, zipCode, dob, gender } = userInput;
        if (firstName === '')
            setUserInput({ firstNameError: '*please enter first name.' })
        if (lastName === '')
            setUserInput({ lastNameError: '*please enter last name.' })
        if (phoneNumber === '')
            setUserInput({ phoneNumberError: '*please enter phone number.' })
        if (email === '')
            setUserInput({ emailError: '*please enter email.' })
        if (!validateEmail(email).status)
            setUserInput({ emailError: '*please enter valid email.' })
        if (address === '')
            setUserInput({ addressError: '*please enter address.' })
        if (city === '')
            setUserInput({ cityError: '*please enter city.' })
        if (state === '')
            setUserInput({ stateError: '*please enter state.' })
        if (zipCode === '')
            setUserInput({ zipCodeError: '*please enter zip code.' })
        else {

            let req = {
                name: `${firstName} ${lastName} `,
                phone: phoneNumber,
                email: email,
                address: address,
                address2: address2,
                city: city,
                state: state,
                zip: zipCode,
                dob: dob,
                gender: gender,
                userId: isEdit

            }
            let { dispatch } = props;
            setUserInput({ loading: true })
            dispatch(userActions.editAttendee(req)).then(data => {
                if (data?.statusCode === 1) {
                    props.history.goBack()
                    setUserInput({ loading: false })
                }
                else {
                    notification.error({ message: 'Error', description: data?.error?.responseMessage })
                    setUserInput({
                        loading: false
                    })
                }
            })

        }
    }
    var newaddress = ''
    let handleSelectAddress = address => {
        console.log(address, 'adresssss');

        geocodeByAddress(address)
            .then(results =>
                getLatLng(results[0]))
            .then(latLng => {
                newaddress = address.split(',')
                console.log("address111", newaddress[0]);

                setUserInput({ ...userInput, lng: latLng.lng, lat: latLng.lat, address: newaddress[0] })
                // this.postalCode(latLng)

                let Url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=AIzaSyAjSodVOYLA8VVU2oOSToc7VtM8xKs13fI`

                fetch(Url)
                    .then(response => response.json())
                    .then((result) => {

                        console.log("response>>", result.results);
                        let zipcode;
                        let city;
                        let state;
                        let street;
                        for (var i = 0; i < result.results.length; i++) {
                            for (var j = 0; j < result.results[i].address_components.length; j++) {
                                for (var k = 0; k < result.results[i].address_components[j].types.length; k++) {
                                    if (result.results[i].address_components[j].types[k] == "route") {
                                        street = result.results[i].address_components[j].long_name;
                                        console.log("zipcode>>", street);
                                        // setUserInput({ zip: zipcode })
                                        if (street) {
                                            setUserInput({ street: street })
                                        }
                                        else {
                                            setUserInput({ street: '' })
                                        }

                                    }
                                    if (result.results[i].address_components[j].types[k] == "postal_code") {
                                        zipcode = result.results[i].address_components[j].short_name;
                                        console.log("zipcode>>", zipcode);
                                        // setUserInput({ zip: zipcode })
                                        if (zipcode) {
                                            setUserInput({ zipCode: zipcode })
                                        }
                                        else {
                                            setUserInput({ zipCode: '' })
                                        }

                                    }
                                    if (result.results[i].address_components[j].types[k] == "locality") {
                                        city = result.results[i].address_components[j].long_name;
                                        if (city) {
                                            setUserInput({ city: city })
                                        }
                                        else {
                                            setUserInput({ city: '' })
                                        }
                                    }
                                    if (result.results[i].address_components[j].types[k] == "administrative_area_level_1") {
                                        state = result.results[i].address_components[j].long_name;
                                        // console.log("state>>", state);
                                        if (zipcode) {
                                            setUserInput({ state: state })
                                        }
                                        else {
                                            setUserInput({ state: '' })
                                        }
                                    }

                                }
                            }
                        }
                        //  setUserInput({ zip: result.results[0].address_components[8].long_name })


                    })
            }

            )
            .catch(error => console.error('Error', error));
    }


    const { firstName, lastName, phoneNumber, email, address2, address, city, state, zipCode,
        firstNameError, lastNameError, emailError, phoneNumberError, addressError, cityError,
        stateError, zipCodeError, loading, isEdit
    } = userInput
    console.log('editaddress', city, state);
    return (
        <>
            <body className="dark-sidebar">
                <div className="main-container">
                    <div className="container-fluid page-body-wrapper">
                        <nav className="navbar fixed-top"><div className="navbar-menu-container d-flex align-items-center justify-content-center">
                            <div className="sub-heading">
                                <h4 className="page-title ">User Management</h4>
                            </div>
                            <div className="text-center navbar-brand-container align-items-center justify-content-center">

                                <a className="brand-logo" href="/">	<img src={require('../../assets/images/mobile-logo.png')} alt="Event 3" className="mb-3" />
                                </a>
                            </div>

                            <TopBar
                                {...props}
                            />


                        </div>

                        </nav>
                        <SideNav />
                        <div className="main-panel">
                            <div className="content-wrapper manage-event">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-margin">
                                            <div className="card-header">
                                                <div className="col-md-10">
                                                    {console.log(isEdit, 'isEdit')}
                                                    <h5 className="card-title">{isEdit ? 'Edit Your Attendee' : 'Add Your Attendee'}</h5>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details">
                                                    <div class="forms-sample">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div class="form-group">
                                                                    <label for="exampleInputUsername12">First Name<span className="text-danger">*</span></label>
                                                                    <input type="text" className="form-control" id="event-name"
                                                                        placeholder="First name"
                                                                        maxLength={56}
                                                                        autoComplete="off"
                                                                        name="firstName"
                                                                        value={firstName}
                                                                        onChange={handleChange} />
                                                                    <InlineError
                                                                        message={firstNameError}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div class="form-group m-b-40">
                                                                    <label for="exampleInputUsername12">Last Name<span className="text-danger">*</span></label>
                                                                    <input type="text" className="form-control"
                                                                        id="event-sub" name="lastName"
                                                                        placeholder="Last name"
                                                                        onChange={handleChange}
                                                                        maxLength={56}
                                                                        autoComplete="off"
                                                                        value={lastName} />
                                                                    <InlineError
                                                                        message={lastNameError}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div class="form-group m-b-40">
                                                                    <label for="exampleInputUsername12">Phone number<span className="text-danger">*</span></label>
                                                                    <PhoneInput
                                                                        country={'us'}
                                                                        value={phoneNumber}
                                                                        countryCodeEditable={false}
                                                                        onlyCountries={['us']}
                                                                        autoFormat={false}
                                                                        onChange={phoneNumber => setUserInput({ ...userInput, phoneNumber, phoneNumberError: '' })}
                                                                    />
                                                                    {/**
                                                                    <input type="text" className="form-control"
                                                                        id="event-sub" name="phoneNumber"
                                                                        placeholder="Phone Number"
                                                                        maxLength={56}
                                                                        autoComplete="off"
                                                                        onChange={handleChange}
                                                                        value={phoneNumber} />
                                                                        */}
                                                                    <InlineError
                                                                        message={phoneNumberError}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                isEdit ?
                                                                    <div className="col-md-6">
                                                                        <div class="form-group m-b-40">
                                                                            <label for="exampleInputUsername12">Email<span className="text-danger">*</span></label>
                                                                            <input type="text" className="form-control"
                                                                                id="event-sub" name="email"
                                                                                placeholder="Email"
                                                                                maxLength={56}
                                                                                disabled
                                                                                autoComplete="off"
                                                                                onChange={handleChange}
                                                                                value={email} />
                                                                            <InlineError
                                                                                message={emailError}
                                                                            />
                                                                        </div>
                                                                    </div> :
                                                                    <div className="col-md-6">
                                                                        <div class="form-group m-b-40">
                                                                            <label for="exampleInputUsername12">Email<span className="text-danger">*</span></label>
                                                                            <input type="text" className="form-control"
                                                                                id="event-sub" name="email"
                                                                                placeholder="Email"
                                                                                maxLength={56}
                                                                                autoComplete="off"
                                                                                onChange={handleChange}
                                                                                value={email} />
                                                                            <InlineError
                                                                                message={emailError}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                            }
                                                            <div className="col-md-6">
                                                                <div class="form-group m-b-40">
                                                                    <label for="exampleInputUsername12">Address 1 <span className="text-danger">*</span></label>
                                                                    <PlacesAutocomplete
                                                                        value={address}
                                                                        onChange={handleChangeaddress}
                                                                        onSelect={handleSelectAddress}
                                                                    >
                                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                            <div>
                                                                                <input
                                                                                    {...getInputProps({
                                                                                        placeholder: 'Search Places',
                                                                                        className: 'location-search-input form-control',
                                                                                    })}
                                                                                />
                                                                                <div className="autocomplete-dropdown-container">
                                                                                    {loading && <div>Loading...</div>}
                                                                                    {suggestions.map(suggestion => {
                                                                                        const className = suggestion.active
                                                                                            ? 'suggestion-item--active'
                                                                                            : 'suggestion-item';
                                                                                        // inline style for demonstration purpose
                                                                                        const style = suggestion.active
                                                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                                        return (
                                                                                            <div
                                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                                    className,
                                                                                                    style,
                                                                                                })}
                                                                                            >
                                                                                                <span>{suggestion.description}</span>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </PlacesAutocomplete>
                                                                    <InlineError
                                                                        message={addressError}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div class="form-group m-b-40">
                                                                    <label for="exampleInputUsername12">Address 2 (*Optional)</label>
                                                                    <input type="text" className="form-control"
                                                                        id="event-sub" name="address2"
                                                                        placeholder="Address line 2"
                                                                        maxLength={56}
                                                                        autoComplete="off"
                                                                        onChange={handleChange}
                                                                        value={address2} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div class="form-group m-b-40">
                                                                    <label for="exampleInputUsername12">City<span className="text-danger">*</span></label>
                                                                    <input type="text" className="form-control"
                                                                        id="event-sub" name="city" placeholder="City"
                                                                        onChange={handleChange}
                                                                        value={city} />
                                                                    <InlineError
                                                                        message={cityError}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div class="form-group m-b-40">
                                                                    <label for="exampleInputUsername12">State<span className="text-danger">*</span></label>
                                                                    <input type="text" className="form-control"
                                                                        id="event-sub" name="state"
                                                                        maxLength={56}
                                                                        autoComplete="off"
                                                                        placeholder="State"
                                                                        onChange={handleChange}
                                                                        value={state} />
                                                                    <InlineError
                                                                        message={stateError}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div class="form-group m-b-40">
                                                                    <label for="exampleInputUsername12">Zip Code<span className="text-danger">*</span></label>
                                                                    <input type="text" className="form-control"
                                                                        id="event-sub" name="zipCode"
                                                                        maxLength={56}
                                                                        autoComplete="off"
                                                                        placeholder="Zip code"
                                                                        onChange={handleChange}
                                                                        value={zipCode} />
                                                                    <InlineError
                                                                        message={zipCodeError}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="text-left mt-4 pt-3 ">
                                                                    <button type="button" className="btn btn-primary float-right" onClick={isEdit ? handleEditAttendee : handleAddAttendee}>Save
                                                                    &nbsp;
                                                                    {
                                                                            loading && <MySpinner />
                                                                        }
                                                                    </button>
                                                                    <button className="btn btn-light mr-2 float-right" onClick={reDirectTo}>Cancel</button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>

                    </div>
                </div>
            </body >
        </>
    )
}
function mapStateToProps(state) {
    const { props, addAttendee, editAttendee, getParticularAttendee, user } = state;
    return {
        props,
        addAttendee,
        editAttendee,
        getParticularAttendee,
        user
    }
}
export default connect(mapStateToProps)(AddAttandee);

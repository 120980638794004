import React from 'react';
//footer
import Footer from '../../components/template/Footer';
//side navbar
import SideNav from '../../components/template/SideNav';
//header
import TopBar from '../../components/template/TopBar';
//auth action
import { adminActions } from "../../_action/CategoryManage";
//pagination
import Pagination from "react-js-pagination";
//react-redux connect
import { connect } from "react-redux";
//loader
import { MySpinner, Loader } from '../../components/common/Loader';
//modal
import Modal from 'react-bootstrap/Modal';
//inline error
import InlineError from '../../components/common/InlineError';
//notification
import { notification, Empty } from 'antd'
//antd css
import 'antd/dist/antd.css';
//validation
import { validateName } from '../../components/common/Validation';





//Category list screen
class ManageCategory extends React.Component {
    constructor() {
        super();
        this.state = {
            loader: false,
            eventListDetails: [],
            limit: 20,
            page: 1,
            status: '',
            sortOrder: '',
            sortBy: "created",
            isLoder: false,
            openPopup: false,
            categoryId: '',
            openEditPopup: '',
            categoryName: '',
            categoryNameError: '',
            search: '',
            totalCount: '',
            loading: false,
            error: null,
            openAddPopup: ''
        };
    }

    componentDidMount() {
        this.getCategoryList();
    }

    //pagination on chnage
    handlePageChange = (page) => {
        this.setState({ page: page, isLoader: true }, () => this.getCategoryList());
    }

    //sort by name
    handleSort = e => {
        this.setState({
            isLoader: false, page: 1, sortBy: e, sortOrder: this.state.sortOrder === "asc" ? 'desc' : "asc"
        }, () => this.getCategoryList());
    }

    // get category details
    getCategoryList = e => {
        let { limit, sortOrder, sortBy, page, search } = this.state;
        let obj = {
            limit: limit,
            sortOrder: sortOrder,
            sortBy: sortBy,
            page: page - 1 ? page - 1 : 0,
            search: search
        }
        let { dispatch } = this.props;
        this.setState({ isLoader: true })
        dispatch(adminActions.getCategory(obj)).then(data => {
            if (data?.statusCode === 1) {
                this.setState({ eventListDetails: data?.responseData?.result, totalCount: data?.responseData?.count, isLoader: false })
            }
            else {
                notification.error({ message: 'Error', description: data?.error?.responseMessage })
                this.setState({
                    isLoader: false
                })
            }
        }
        );
    }

    //open popup
    handleOpenPopup = (id) => this.setState({ openPopup: !this.state.openPopup, categoryId: id });

    //close delete modal
    closeModal = () => this.setState({
        openPopup: false
    });

    // delete category list 
    deleteCategoryList = e => {
        let { categoryId } = this.state;
        let obj = {
            categoryId: categoryId
        }
        let { dispatch } = this.props;
        this.setState({ loading: true })
        dispatch(adminActions.deleteCategory(obj)).then(data => {
            if (data?.statusCode === 1) {
                notification.success({ message: 'Success', description: data.responseData.message });
                this.getCategoryList()
                this.setState({ loading: false })
                this.closeModal()
            }
            else {
                notification.error({ message: 'Error', description: data?.error?.responseMessage })
                this.setState({
                    isLoader: false, loading: false
                })
            }
        });
    }

    //category filter
    handleFilter = (e) => {
        this.setState({ status: e }, () => this.categoryList());
    }
    //on change
    handleOnChange = (e) => this.setState({ [e.target.name]: e.target.value, categoryNameError: '' })
    //on change
    handleAddOnChange = (e) => this.setState({ [e.target.name]: e.target.value, categoryNameError: '' })

    //submit edit
    handleOnClickEdit = () => {
        let { categoryName } = this.state;
        if (categoryName === '')
            this.setState({ categoryNameError: '*Please enter category name.' })
        else if (!validateName(categoryName).status)
            this.setState({ categoryNameError: '*please enter valid category name.' })
        else {
            let req = {
                name: this.state.categoryName,
                categoryId: this.state.categoryId
            }
            let { dispatch } = this.props;
            this.setState({ loading: true })
            dispatch(adminActions.editCategory(req)).then(data => {
                if (data?.statusCode === 1) {
                    this.setState({ loading: false })
                    this.getCategoryList()
                    this.closeEditModal()
                }
                else {
                    notification.error({ message: 'Error', description: data?.error?.responseMessage })
                    this.setState({
                        loading: false
                    })
                }
            })
        }
    }

    //add submit
    handleOnClickAdd = () => {
        let { categoryName } = this.state;
        if (categoryName === '')
            this.setState({ categoryNameError: '*Please enter category name.' })
        else if (!validateName(categoryName).status)
            this.setState({ categoryNameError: '*please enter valid category name.' })
        else {
            let req = {
                name: this.state.categoryName,

            }
            let { dispatch } = this.props;
            this.setState({ loading: true })
            dispatch(adminActions.addCategory(req)).then(data => {
                if (data?.statusCode === 1) {
                    this.setState({ loading: false })
                    this.getCategoryList()
                    this.closeAddModal()
                    this.setState({ categoryName: "" })
                }
                else {
                    notification.error({ message: 'Error', description: data?.error?.responseMessage })
                    this.setState({
                        loading: false
                    })
                }
            })
        }

    }
    //seaching on change
    handleOnSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => this.getCategoryList())
    }


    //open popup
    handleOnClickAddPopup = () => this.setState({ categoryName: '', openAddPopup: !this.state.openAddPopup, });

    //close delete modal
    closeAddModal = () => this.setState({
        openAddPopup: false
    });

    //open popup
    handleOnClickEditPopup = (item) => this.setState({ openEditPopup: !this.state.openEditPopup, categoryId: item._id, categoryName: item.name });

    //close delete modal
    closeEditModal = () => this.setState({
        openEditPopup: false
    });

    render() {
        let { eventListDetails,page,limit,totalCount, isLoader, loading, openAddPopup, openPopup, openEditPopup, categoryName, categoryNameError, search } = this.state;
        return (
            <div className="dark-sidebar">

                {isLoader && <Loader />}
                <div className="main-container">
                    <div className="container-fluid page-body-wrapper">
                        <nav className="navbar fixed-top"><div className="navbar-menu-container d-flex align-items-center justify-content-center">
                            <div className="sub-heading">
                                <h4 className="page-title ">Category Management</h4>
                            </div>
                            <div className="text-center navbar-brand-container align-items-center justify-content-center">
                                <a className="brand-logo" href="/">	<img src={require('../../assets/images/mobile-logo.png')} alt="Event 3" className="mb-3" />
                                </a>
                            </div>
                            <TopBar
                                {...this.props}
                            />
                        </div>
                        </nav>
                        <SideNav />
                        <div className="main-panel">
                            <div className="content-wrapper manage-event">
                                <div className="row card-margin">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="row w-100 align-items-center d-flex">
                                                    <div className="col-md-6">
                                                        <h5 className="card-title">Admin
                                                        <small className="card-title-secondary">Manage your category below</small>
                                                        </h5>
                                                    </div>
                                                    <div className="col-md-4">
                                                    <div className="search ml-0">
                                                        <input type="text" class="form-control"
                                                            id="event-name"
                                                            placeholder="Search by category name..."
                                                            maxLength={50}
                                                            name="search"
                                                            value={search}
                                                            onChange={this.handleOnSearch}
                                                        />
                                                    </div>
                                                    </div>
                                                    <div className="card-header col-md-2 border-bottom-0 d-flex justify-content-end" >
                                                        <div className="card-toolbar">
                                                            <button type="button" className="btn btn-sm btn-danger" onClick={() => this.handleOnClickAddPopup()} >Add Category</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table widget-8">
                                                        <thead>
                                                            <tr>
                                                                <th className="border-0">S. No.</th>
                                                                <th className="border-0 cursor-pointer" onClick={() => this.handleSort('name')}> <i className="fa fa-sort"></i> Name</th>
                                                                <th className="border-0">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {eventListDetails?.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{((this.state.page-1) * 20) + index+1}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>
                                                                            <ul className="list-inline mb-0">

                                                                                <li className="list-inline-item cursorptr pr-3" onClick={() => this.handleOnClickEditPopup(item)}>
                                                                                    <i className="fa fa-edit"></i>
                                                                                </li>

                                                                                <li className="list-inline-item cursorptr pr-3" onClick={() => this.handleOpenPopup(item._id)}>
                                                                                    <i className="fa fa-trash"></i>
                                                                                </li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div>
                                                    {!isLoader && eventListDetails.length === 0 &&
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                                </div>
                                            </div>

                                            <div className="card-footer bg-white">
                                                <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row mt-4">
                                                <p className="mb-3 mb-sm-0">Showing {page == 1 ? page : (page * limit - 20) + 1} to {eventListDetails?eventListDetails.length===20 ? page * limit - 20 + 20 : page * limit - 20 + eventListDetails.length :''} of {totalCount} categories</p>

                                                {/* <p className="mb-3 mb-sm-0">Showing {page == 1 ? page : (page*limit - 20) + 1} to {(page*limit - 20 + 20)} of {totalCount} users</p> */}
                                                    <nav>
                                                        <ul >
                                                            <Pagination
                                                                activePage={this.state.page}
                                                                itemsCountPerPage={this.state.limit}
                                                                totalItemsCount={this.state.totalCount}
                                                                pageRangeDisplayed={5}
                                                                onChange={this.handlePageChange}
                                                            />
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                        <Modal
                                            size="md"
                                            show={openAddPopup}
                                            onHide={this.closeAddModal}
                                            aria-labelledby="example-modal-sizes-title-sm"
                                        >
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <div className="sub-heading">
                                                <h4 className="page-title ">Add Category</h4>
                                            </div>
                                            <div className="cm_modal">
                                                <div className="modal-body">
                                                    <div className="form-group inner-addon">
                                                        <input type="text"
                                                            autocomplete="off"
                                                            className="form-control"
                                                            maxLength="75"
                                                            placeholder="Category Name"
                                                            name="categoryName"
                                                            value={categoryName}
                                                            onChange={this.handleAddOnChange}

                                                        />
                                                        <InlineError
                                                            message={categoryNameError}
                                                        />
                                                    </div>
                                                    <br />
                                                    <div className="bg-white pull-right mb-4">
                                                        <button className="btn btn-light" onClick={this.closeAddModal}>Cancel</button>
                                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading} onClick={() => this.handleOnClickAdd()}>Save
                                                        &nbsp;
                                                       {
                                                                loading && <MySpinner />
                                                            }
                                                        </button>
                                                    </div>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </Modal>
                                        <Modal
                                            size="md"
                                            show={openEditPopup}
                                            onHide={this.closeEditModal}
                                            aria-labelledby="example-modal-sizes-title-sm"
                                        >
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <div className="sub-heading">
                                                <h4 className="page-title ">Edit Category</h4>
                                            </div>
                                            <div className="cm_modal">
                                                <div className="modal-body">
                                                    <div className="form-group inner-addon">
                                                        <input type="text"
                                                            autocomplete="off"
                                                            className="form-control"
                                                            maxLength="75"
                                                            placeholder="Category Name"
                                                            name="categoryName"
                                                            value={categoryName}
                                                            onChange={this.handleOnChange}

                                                        />
                                                        <InlineError
                                                            message={categoryNameError}
                                                        />
                                                    </div>
                                                    <br />
                                                    <div className="bg-white pull-right mb-4">
                                                        <button className="btn btn-light" onClick={this.closeEditModal}>Cancel</button>
                                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading} onClick={() => this.handleOnClickEdit()}>Save
                                                        &nbsp;
                                                       {
                                                                loading && <MySpinner />
                                                            }
                                                        </button>
                                                    </div>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </Modal>
                                        <Modal
                                            size="md"
                                            show={openPopup}
                                            onHide={this.closeModal}
                                            aria-labelledby="example-modal-sizes-title-sm"
                                        >
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <div className="cm_modal">
                                                <div className="modal-body">
                                                    <h4 className="mb-4"><b>Are you sure, you want to  delete this?</b></h4>
                                                    <br />
                                                    <div className="bg-white pull-right mb-4">
                                                        <button className="btn btn-light" onClick={this.closeModal}>No</button>
                                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading} onClick={() => this.deleteCategoryList()}>Yes
                                                        &nbsp;
                                                       {
                                                                loading && <MySpinner />
                                                            }
                                                        </button>
                                                    </div>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    const { props, getCategory, deleteCategory, user } = state;
    return {
        props,
        getCategory,
        deleteCategory,
        user
    }
}
export default connect(mapStateToProps)(ManageCategory);
import React from 'react';
import { Loader, Delete, Plus2, Plus } from "../../../SvgIcon";
import _ from 'lodash';
import { Select, DatePicker } from 'antd';
import Modal from 'react-bootstrap/Modal'
import Pagination from "react-js-pagination";
// import { usersActions } from "../../_actions";
import { usersService } from '../../../services/ApiServices'
import { ResponseFilter } from '../../../_helper/ResponseHandler';
import moment from 'moment';
import { message } from 'antd';

const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';





class EditTicket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 5,
            sortOrder: "asc",
            sortBy: "created",
            page: 1,
            loader: false,
            description: '',
            openEditPopup: false,
            openAddPopup: false,
            eventId: this.props.eventId,
            users: [{ ticketType: "", ticketName: '', quantity: "", price: '', description: "" }],
            ticketsList: [],
            openAddPaidTicketPopup: false,
            openEditTicketPopup: false,
            openAddFreeTicketPopup: false,
            ticketNameErrorMsg: '',
            quantityErrorMsg: '',
            descriptionErrorMsg: '',
            disabledBtn: false,
            openDeleteTicketPopup: false,


        }
    }
    componentDidMount() {
        this.ticketList();

    }
    ticketList = () => {
        let { search, limit, sortOrder, sortBy, page } = this.state;
        let obj = {
            eventId: this.props.eventId,
            search: search,
            limit: limit,
            sortOrder: sortOrder,
            sortBy: sortBy,
            page: page - 1
        }


        usersService.ticketList(obj).then(res => {
            let { status, resData } = ResponseFilter(res);
            if (status) {

                this.setState({
                    ticketsList: resData.result,
                    totalCount: resData.count,

                }

                )

            }

        })
    }
    handleSearch = e => {
        this.setState({ loader: false })
        this.setState({ search: e.target.value, page: 1 }, () => this.ticketList());
    }
    handleSort = e => {
        this.setState({
            loader: false, page: 1, sortBy: e, sortOrder: this.state.sortOrder === "asc" ? 'desc' : "asc"
        }, () => this.ticketList());
    }

    changeLimit = (e) => {
        this.setState({ limit: e, page: 1 }, () => this.ticketList());
    }

    handlePageChange = (page) => {
        this.setState({ page: page, loader: true }, () => this.ticketList());
    }

    handleOpenDeleteTicketPopup = (ticketId) => this.setState({ openDeleteTicketPopup: !this.state.openDeleteTicketPopup, ticketId });
    handleOpenAddPaidTicketPopup = (ticketType) => this.setState({ openAddPaidTicketPopup: !this.state.openAddPaidTicketPopup, ticketType: ticketType });
    handleOpenAddFreeTicketPopup = (ticketType) => this.setState({ openAddFreeTicketPopup: !this.state.openAddFreeTicketPopup, ticketType: ticketType });
    handleOpenEditTicketPopup = ({
        ticketName,
        quantity,
        ticketType,
        price,
        description,
        _id,

    }) => this.setState({
        openEditTicketPopup: !this.state.openEditTicketPopup,
        ticketName,
        quantity,
        ticketType,
        description,
        price,
        ticketId: _id
    });
    handleChange = e => this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: '',

    });

    handleValidation() {
        var isValid = true;
        let { users, } = this.state;
        users.map((item, index) => {
            console.log(users[index].ticketType, 'index>')
            if (users[index].ticketName === '' || users[index].ticketName === undefined) {
                isValid = false;
                this.setState({
                    ticketNameErrorMsg: 'Please fill all the mandatory fields.'
                })
            }
            if (users[index].description === '' || users[index].description === undefined) {
                isValid = false;
                this.setState({
                    ticketNameErrorMsg: 'Please fill all the mandatory fields.'
                })
            }
            if (users[index].quantity === '' || users[index].quantity === undefined) {
                isValid = false;
                this.setState({
                    ticketNameErrorMsg: 'Please fill all the mandatory fields.'
                })
            }

            if (users[index].ticketType === 2) {
                if (users[index].price === '' || users[index].price === undefined) {
                    isValid = false;
                    this.setState({
                        ticketNameErrorMsg: 'Please fill all the mandatory fields.'
                    })
                }

            }

        });
        return isValid;
    }



    handleSubmitTicket = (event) => {
        event.preventDefault();
        if (this.handleValidation()) {
            this.setState({ disabledBtn: true });
            let obj = {
                'eventId': this.props.eventId,
                "ticketDetails": this.state.users
            }
            let token = localStorage.getItem('accessToken')

            usersService.ticket(obj, token).then(data => {
                if (data.data.statusCode === 1) {
                    message.success("Added Successfully!");
                    this.setState({ disabledBtn: false })
                    this.setState({ openAddFreeTicketPopup: false, openAddPaidTicketPopup: false, openDeleteTicketPopup: false, })
                    this.ticketList();
                    this.closeModal();
                }
                else {
                    this.closeModal();
                    this.setState({ disabledBtn: false })
                    this.setState({ openAddFreeTicketPopup: false, openAddPaidTicketPopup: false, openDeleteTicketPopup: false })
                    this.setState({
                        errors: data.data.error.responseMessage

                    })
                }

            }
            );
        }

    }
    handleDeleteTicket = (data) => {
        let obj = {
            'ticketId': this.state.ticketId,
            'status': 2
        }
        const accessToken = localStorage.getItem('accessToken');
        usersService.deleteTicket(obj, accessToken).then(res => {
            if (res.data.statusCode === 1) {
                message.success("Delete Successfully!");
                this.setState({
                    openDeleteTicketPopup: false
                }, () => this.ticketList())
            }
            else {
                this.setState({
                    openDeleteTicketPopup: true
                })
            }
        })
    }

    handleEditValidetion = () => {
        let validate = true;
        let { ticketName, quantity, ticketType, price, description } = this.state;
        if (ticketName === '' || ticketName === undefined) {
            validate = false;
            this.setState({
                ticketNameErrorMsg: 'Please enter ticket name.'
            })
        } else {
            this.setState({
                ticketNameErrorMsg: ''
            })
        }
        if (description === '' || description === undefined) {
            validate = false;
            this.setState({
                descriptionErrorMsg: 'Please enter description.'
            })
        } else {
            this.setState({
                descriptionErrorMsg: ''
            })
        }
        if (quantity === '' || quantity === undefined) {
            validate = false;
            this.setState({
                quantityErrorMsg: 'Please enter quantity.'
            })

        } else {
            this.setState({
                quantityErrorMsg: ''
            })
        }
        if (ticketType === 2) {
            if (price === '' || price === undefined) {
                validate = false;
                this.setState({
                    priceErrorMsg: 'Please enter price.'
                })
            }
            else {
                this.setState({
                    priceErrorMsg: ''
                })

            }
        }
        return validate
    }
    closeModal = () => {
        this.setState({
            openAddFreeTicketPopup: false,
            openAddPaidTicketPopup: false,
            openEditTicketPopup: false,
            openDeleteTicketPopup: false,
            quantityErrorMsg: '',
            descriptionErrorMsg: '',
            ticketNameErrorMsg: '',
            ticketName: '',
            quantity: '',
            users: [{ ticketType: "", ticketName: '', quantity: "", price: '', description: "" }],
            ticketType: '',
            description: '',
            price: '',
            ticketId: '',
            miniLoader: false,
        });
    }

    handleEditTicketSubmit = event => {
        event.preventDefault();
        if (this.handleEditValidetion()) {
            let params = {
                'ticketId': this.state.ticketId,
                'ticketName': this.state.ticketName,
                'quantity': this.state.quantity,
                'price': this.state.price,
                'description': this.state.description,

            }
            const accessToken = localStorage.getItem('accessToken');
            usersService.editTicket(params, accessToken).then(res => {
                console.log(res, 'edit res......')
                if (res.data.statusCode === 1) {
                    this.closeModal();
                    message.success("Updated Successfully!");
                    this.setState({
                        openEditTicketPopup: false
                    }, () => this.ticketList())
                }
                else {
                    this.closeModal();
                    this.setState({
                        openEditTicketPopup: false
                    }, () => this.ticketList())
                }
            })
        }
    }
    handleChangeInput(i, e) {

        const { name, value } = e.target;
        let users = [...this.state.users];
        users[i] = { ...users[i], ticketType: this.state.ticketType, [name]: value };

        this.setState({ users, [e.target.name + 'ErrorMsg']: '', ticketNameErrorMsg: '' });
    }
    addClick() {
        this.setState(prevState => ({
            users: [...prevState.users, { eventId: localStorage.getItem('eventId'), ticketName: '', ticketType: 1, quantity: '', description: '' }]
        }))
    }
    removeClick(i) {
        let users = [...this.state.users];
        users.splice(i, 1);
        this.setState({ users });
    }
    render() {
        let { ticketsList, openDeleteTicketPopup, disabledBtn } = this.state;
        return (



            <React.Fragment>

                <div className="tab-pane fade" id="tickets" role="tabpanel" aria-labelledby="codes"></div>
                <div className="col-lg-12 card-margin">
                    <div className="card ">
                        <div className="card-header">
                            <div className="col-md-8">
                                <h6 className="card-title m-0">Ticket Types</h6>
                            </div>
                            <div className="col-md-4">
                                <div className="pull-right clearfix ">
                                    <button className="btn btn-sm btn-primary mr-2" onClick={() => this.handleOpenAddFreeTicketPopup(1)}><Plus /> Add Free Ticket</button>
                                    <button className="btn btn-sm btn-primary mr-2" onClick={() => this.handleOpenAddPaidTicketPopup(2)}><Plus /> Add Paid Ticket</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row mb-4">
                                {/* <div className="col-6">
                                    Show<Select
                                        className="select-limit mr-1 ml-1"
                                        value={this.state.limit}
                                        defaultValue={this.state.limit}
                                        style={{ width: 120 }}
                                        onChange={this.changeLimit}
                                    >
                                        <Option value={5}>5</Option>
                                        <Option value={10}>10</Option>
                                        <Option value={15}>15</Option>

                                    </Select>entries
								</div> */}
                                {/* <div className="col-6 mb-3">

                                    <div className="d-flex flex-row-reverse bd-highlight">

                                        <div>
                                            <span className="search">Search:</span><span><input type="text"
                                                onKeyPress={e => {
                                                    if (e.key === 'Enter') e.preventDefault();
                                                }}

                                                className="form-control search-box"
                                                name="search"
                                                placeholder="Search"
                                                value={this.state.search}
                                                onChange={this.handleSearch} /></span>

                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="table-responsive">

                                <table id="example" className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: 320 }}><span className="cm_col">Ticket Name</span></th>
                                            <th>Description</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Delete</th>
                                            <th>Edit</th>



                                        </tr>
                                    </thead>
                                    <tbody>

                                        {

                                            !_.isEmpty(this.state.ticketsList) ?
                                                <React.Fragment>
                                                    {this.state.ticketsList.map((item, i) => {
                                                        return (

                                                            <tr key={item._id}>

                                                                <td style={{ width: 320 }}><span className="cm_col2 form-control" >{item.ticketName ? item.ticketName : ''}</span></td>
                                                                <td><span className="cm_col2 form-control" >{item.description ? item.description : ''}</span></td>
                                                                <td><span className="cm_col2 form-control" >{item.quantity ? item.quantity : ''}</span></td>


                                                                {
                                                                    item.ticketType === 1 ? <td class="text-center">FREE</td> :
                                                                        <td><span className="cm_col2 form-control">{`$${item.price ? item.price : ''}`}</span></td>

                                                                }
                                                                <td class="text-center" onClick={() => this.handleOpenDeleteTicketPopup(item._id)}><Delete /></td>
                                                                <td >
                                                                    <button type="button" className="btn btn-sm btn-dark" onClick={() => this.handleOpenEditTicketPopup(item)}>Edit</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </React.Fragment> : ''

                                        }


                                    </tbody>
                                </table>

                            </div>
                            {

                                !_.isEmpty(this.state.ticketsList) ? '' : <div className="text-center"><p className="mb-4 mb-sm-0 mt-4 ">No data present </p></div>

                            }
                        </div>
                        {/* <div className="card-footer bg-white">
                            <div className="d-flex align-items-end justify-content-between flex-column flex-sm-row mt-4">
                                <p className="mb-3 mb-sm-0">Showing {this.state.page} to {this.state.limit} of {this.state.totalCount} tickets</p>

                                <nav>
                                    <ul >
                                       
                                        <Pagination
                                            hideFirstLastPages={true}
                                            itemClassPrev="page-change"
                                            prevPageText="Previous"
                                            itemClassNext="page-change"
                                            nextPageText="Next"
                                            activePage={this.state.page}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                        />
                                      
                                    </ul>
                                </nav>

                            </div>
                        </div> */}
                    </div>

                </div>


                <Modal
                    size="lg"
                    show={this.state.openAddFreeTicketPopup}
                    onHide={this.closeModal}

                    aria-labelledby="example-modal-sizes-title-sm"

                >
                    <Modal.Header closeButton>
                        <h5 className="card-title mt-4">Add Free Ticket</h5>
                    </Modal.Header>

                    <form >

                        <div className="card-margin">

                            <div className="card-body">

                                <div>




                                    {this.state.users.map((el, i) => (
                                        <React.Fragment >
                                            {this.state.ticketNameErrorMsg ? <p className="error mb-2 ml-0">{this.state.ticketNameErrorMsg}</p> : null}
                                            <div className="border-bottom   mt-0 mb-2">
                                                <div className="row">
                                                    <div className=" col-lg-6 col-sm-6  form-group">
                                                        <label className="sub">Ticket Name<span className="text-danger">*</span></label>
                                                        <input className="form-control" placeholder="Name" name="ticketName" value={el.ticketName || ''} onChange={this.handleChangeInput.bind(this, i)} />
                                                    </div>
                                                    <div className=" col-lg-6 col-sm-6 form-group">
                                                        <label className="sub">Quantity<span className="text-danger">*</span></label>
                                                        <input type="number" className="form-control" placeholder="Quantity" name="quantity" value={el.quantity || ''} onChange={this.handleChangeInput.bind(this, i)} />
                                                    </div>


                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-10 col-sm-6 form-group">
                                                        <label className="sub">Description<span className="text-danger">*</span></label>
                                                        <textarea className="form-control" rows="2" name="description" value={el.description || ''} onChange={this.handleChangeInput.bind(this, i)}></textarea>

                                                    </div>
                                                    <div className="col-lg-2 col-sm-6 form-group">
                                                        <button type="button" value='remove' className="btn btn-sm btn-dark dark-black" onClick={this.removeClick.bind(this, i)}>Remove</button>
                                                    </div>


                                                </div>
                                            </div>

                                        </React.Fragment>


                                    ))}


                                    <div className="text-right"><button type="button" className="btn btn-sm btn-dark" onClick={this.addClick.bind(this)} style={{ width: 70 }}>< Plus2 /> Add </button></div>



                                </div>
                            </div>
                            <div className="card-footer bg-white">
                                <button className="btn btn-light" type="button" onClick={this.closeModal}>Cancel</button>
                                <button type="button" onClick={this.handleSubmitTicket.bind(this)} className="btn btn-primary ml-2" disabled={disabledBtn}>Save</button>


                            </div>
                        </div>


                    </form>
                </Modal>

                <Modal
                    size="md"
                    show={openDeleteTicketPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"

                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <div className="cm_modal">


                        <div className="modal-body">
                            <h4 className="mb-4"><b>Are you sure, you want delete this ticket?</b></h4>
                            <br />

                            <div className="bg-white pull-right mb-4">
                                <button className="btn btn-light" type="button" onClick={this.closeModal}>No</button>
                                <button type="submit" className="btn btn-primary ml-2" onClick={() => this.handleDeleteTicket(ticketsList)}>Yes</button>

                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </Modal>
                <Modal
                    size="lg"
                    show={this.state.openAddPaidTicketPopup}
                    onHide={this.closeModal}

                    aria-labelledby="example-modal-sizes-title-sm"

                >
                    <Modal.Header closeButton>
                        <h5 className="card-title mt-4">Add Paid Ticket</h5>
                    </Modal.Header>

                    <form >

                        <div className="card-margin">

                            <div className="card-body">

                                <div>




                                    {this.state.users.map((el, i) => (
                                        <React.Fragment >
                                            {this.state.ticketNameErrorMsg ? <p className="error mb-2 ml-0">{this.state.ticketNameErrorMsg}</p> : null}
                                            <div className="border-bottom   mt-0 mb-2">
                                                <div className="row">
                                                    <div className=" col-lg-4 col-sm-6  form-group">
                                                        <label className="sub">Ticket Name<span className="text-danger">*</span></label>
                                                        <input className="form-control" placeholder="Name" name="ticketName" value={el.ticketName || ''} onChange={this.handleChangeInput.bind(this, i)} />
                                                    </div>
                                                    <div className=" col-lg-4 col-sm-6 form-group">
                                                        <label className="sub">Quantity<span className="text-danger">*</span></label>
                                                        <input type="number" className="form-control" placeholder="Quantity" name="quantity" value={el.quantity || ''} onChange={this.handleChangeInput.bind(this, i)} />
                                                    </div>
                                                    <div className=" col-lg-4 col-sm-6 form-group">
                                                        <label className="sub">Price<span className="text-danger">*</span></label>
                                                        <span className="prefix">$</span>
                                                        <input type="number" className="form-control price-input" placeholder="Price" name="price" value={el.price || ''} onChange={this.handleChangeInput.bind(this, i)} />
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-10 col-sm-6 form-group">
                                                        <label className="sub">Description<span className="text-danger">*</span></label>
                                                        <textarea className="form-control" id="exampleTextarea" rows="2" name="description" value={el.description || ''} onChange={this.handleChangeInput.bind(this, i)}></textarea>

                                                    </div>
                                                    <div className="col-lg-2 col-sm-6 form-group">
                                                        <button type="button" value='remove' className="btn btn-sm btn-dark dark-black" onClick={this.removeClick.bind(this, i)}>Remove</button>
                                                    </div>


                                                </div>
                                            </div>

                                        </React.Fragment>


                                    ))}


                                    <div className="text-right"><button type="button" className="btn btn-sm btn-dark" onClick={this.addClick.bind(this)} style={{ width: 70 }}>< Plus2 /> Add </button></div>



                                </div>
                            </div>
                            <div className="card-footer bg-white">
                                <button className="btn btn-light" type="button" onClick={this.closeModal}>Cancel</button>
                                <button type="button" onClick={this.handleSubmitTicket.bind(this)} className="btn btn-primary ml-2" disabled={disabledBtn}>Save</button>


                            </div>
                        </div>


                    </form>
                </Modal>
                <Modal
                    size="lg"
                    show={this.state.openEditTicketPopup}
                    onHide={this.closeModal}

                    aria-labelledby="example-modal-sizes-title-sm"

                >
                    <Modal.Header closeButton>
                        <h5 className="card-title mt-4">Edit Ticket</h5>
                    </Modal.Header>

                    <form >

                        <div className="card-margin">

                            <div className="card-body">

                                <div>
                                    <div className="border-bottom  mt-0 mb-2">
                                        <div className="row">
                                            <div className=" col-lg-4 col-sm-6  form-group">
                                                <label className="sub">Ticket Name<span className="text-danger">*</span></label>
                                                <input className="form-control" placeholder="Name" name="ticketName" value={this.state.ticketName} onChange={this.handleChange} />
                                                {this.state.ticketNameErrorMsg ? <div className="error">{this.state.ticketNameErrorMsg}</div> : null}
                                            </div>
                                            <div className=" col-lg-4 col-sm-6 form-group">
                                                <label className="sub">Quantity<span className="text-danger">*</span></label>
                                                <input type="number" className="form-control" placeholder="Quantity" name="quantity" value={this.state.quantity} onChange={this.handleChange} />
                                                {this.state.quantityErrorMsg ? <div className="error">{this.state.quantityErrorMsg}</div> : null}
                                            </div>
                                            {
                                                this.state.ticketType == 2 ?
                                                    <React.Fragment>
                                                        <div className=" col-lg-4 col-sm-6 form-group">
                                                            <label className="sub">Price<span className="text-danger">*</span></label>
                                                            <span className="prefix">$</span>
                                                            <input type="number" className="form-control price-input" placeholder="Price" name="price" value={this.state.price} onChange={this.handleChange} />

                                                            {this.state.priceErrorMsg ? <div className="error">{this.state.priceErrorMsg}</div> : null}
                                                        </div>
                                                    </React.Fragment> :
                                                    <React.Fragment>
                                                        <div className=" col-lg-4 col-sm-6 form-group">
                                                            <label className="sub">Price<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" value="FREE" disabled />
                                                        </div>
                                                    </React.Fragment>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 form-group">
                                                <label className="sub">Description<span className="text-danger">*</span></label>
                                                <textarea className="form-control" id="exampleTextarea" rows="2" name="description" value={this.state.description} onChange={this.handleChange}></textarea>
                                                {this.state.descriptionErrorMsg ? <div className="error">{this.state.descriptionErrorMsg}</div> : null}
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer bg-white">
                                <button className="btn btn-light" type="button" onClick={this.closeModal}>Cancel</button>
                                <button type="button" disabled={disabledBtn} onClick={this.handleEditTicketSubmit} className="btn btn-primary ml-2">Save</button>


                            </div>
                        </div>


                    </form>
                </Modal>

            </React.Fragment>


        );
    }
}


export default EditTicket;

/**
 * @About 
 * This file is entry point of config
 */

import _ from 'lodash';
import axios from 'axios'
import { devSetting, stagSetting, prodSetting, localhostSetting } from './Setting';

//defaut setting
let defaultSetting = {
    api: {
        url: "https://devapi.farechild.com/api/v1/",
        AUTH: 'Basic ZGVtbzpkZW1v',
    },    
  
}

let siteSetting = defaultSetting;

switch (process.env.REACT_APP_ENV) {
    case "prod":
    case "production":
        siteSetting = _.extend(defaultSetting, prodSetting);
        break;
    case "stag":
    case "staging":
        siteSetting = _.extend(defaultSetting, stagSetting);
        break;
    case "local":
    case "localhost":
        siteSetting = _.extend(defaultSetting, localhostSetting);
        break;
    default:
        siteSetting = _.extend(defaultSetting, devSetting);
}
export default siteSetting;
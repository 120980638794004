/**
 * @About
 * This file consist all action of event management.
 */

import promise from 'bluebird'
import { usersService } from "../services/ApiServices";
import { ResponseFilter } from '../_helper/ResponseHandler'

export const eventTypes = {
    //get category
    ADMIN_GET_CATEGORY_REQUEST: 'ADMIN_GET_CATEGORY_REQUEST',
    ADMIN_GET_CATEGORY_SUCCESS: 'ADMIN_GET_CATEGORY_SUCCESS',
    ADMIN_GET_CATEGORY_ERROR: 'ADMIN_GET_CATEGORY_ERROR',

    //view event details
    ADMIN_VIEW_EVENT_REQUEST: 'ADMIN_VIEW_EVENT_REQUEST',
    ADMIN_VIEW_EVENT_SUCCESS: 'ADMIN_VIEW_EVENT_SUCCESS',
    ADMIN_VIEW_EVENT_ERROR: 'ADMIN_VIEW_EVENT_ERROR',

    //view event details
    ADMIN_GET_NEWSFEED_REQUEST: 'ADMIN_GET_NEWSFEED_REQUEST',
    ADMIN_GET_NEWSFEED_SUCCESS: 'ADMIN_GET_NEWSFEED_SUCCESS',
    ADMIN_GET_NEWSFEED_ERROR: 'ADMIN_GET_NEWSFEED_ERROR',

    //delete event details
    ADMIN_DELETE_EVENT_REQUEST: 'ADMIN_DELETE_EVENT_REQUEST',
    ADMIN_DELETE_EVENT_SUCCESS: 'ADMIN_DELETE_EVENT_SUCCESS',
    ADMIN_DELETE_EVENT_ERROR: 'ADMIN_DELETE_EVENT_ERROR',

    //add event fee
    ADMIN_ADD_EVENT_FEE_REQUEST: 'ADMIN_ADD_EVENT_FEE_REQUEST',
    ADMIN_ADD_EVENT_FEE_SUCCESS: 'ADMIN_ADD_EVENT_FEE_SUCCESS',
    ADMIN_ADD_EVENT_FEE_ERROR: 'ADMIN_ADD_EVENT_FEE_ERROR',
    
     //add featured event
     ADMIN_ADD_FEATURED_EVENT_REQUEST: 'ADMIN_ADD_FEATURED_EVENT_REQUEST',
     ADMIN_ADD_FEATURED_EVENT_SUCCESS: 'ADMIN_ADD_FEATURED_EVENT_SUCCESS',
     ADMIN_ADD_FEATURED_EVENT_ERROR: 'ADMIN_ADD_FEATURED_EVENT_ERROR',

};

export const eventActions = {
    getEvent,
    viewEventDetails,
    getNewsFeed,
    deleteEvent,
    addEventFees,
    addFeaturedEvent,

};

//get event
function getEvent(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getEvent(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: eventTypes.ADMIN_GET_CATEGORY_REQUEST, payload }
    }

    function success(payload) {
        return { type: eventTypes.ADMIN_GET_CATEGORY_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: eventTypes.ADMIN_GET_CATEGORY_ERROR, payload }
    }
}

//view event
function viewEventDetails(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.viewEventDetails(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: eventTypes.ADMIN_VIEW_EVENT_REQUEST, payload }
    }

    function success(payload) {
        return { type: eventTypes.ADMIN_VIEW_EVENT_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: eventTypes.ADMIN_VIEW_EVENT_ERROR, payload }
    }
}

//News feed of particular event 
function getNewsFeed(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getNewsFeed(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: eventTypes.ADMIN_GET_NEWSFEED_REQUEST, payload }
    }

    function success(payload) {
        return { type: eventTypes.ADMIN_GET_NEWSFEED_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: eventTypes.ADMIN_GET_NEWSFEED_ERROR, payload }
    }
}

function deleteEvent(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.deleteEvent(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: eventTypes.ADMIN_DELETE_EVENT_REQUEST, payload }
    }
    function success(payload) {
        return { type: eventTypes.ADMIN_DELETE_EVENT_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: eventTypes.ADMIN_DELETE_EVENT_ERROR, payload }
    }
}

function addEventFees(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.addEventFees(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: eventTypes.ADMIN_ADD_EVENT_FEE_REQUEST, payload }
    }
    function success(payload) {
        return { type: eventTypes.ADMIN_ADD_EVENT_FEE_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: eventTypes.ADMIN_ADD_EVENT_FEE_ERROR, payload }
    }
}


function addFeaturedEvent(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.addFeaturedEvent(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: eventTypes.ADMIN_ADD_FEATURED_EVENT_REQUEST, payload }
    }
    function success(payload) {
        return { type: eventTypes.ADMIN_ADD_FEATURED_EVENT_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: eventTypes.ADMIN_ADD_FEATURED_EVENT_ERROR, payload }
    }
}
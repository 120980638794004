/**
 * @About
 * This file consist all action of auth module
 */

import promise from 'bluebird'
import axios from 'axios';
import { usersService } from "../services/ApiServices";
import { ResponseFilter } from '../_helper/ResponseHandler';
import Api from '../services/Api'

export const authTypes = {

    //login
    ADMIN_LOGIN_REQUEST: 'ADMIN_LOGIN_REQUEST',
    ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
    ADMIN_LOGIN_ERROR: 'ADMIN_LOGIN_ERROR',

    //logout
    ADMIN_LOGOUT_REQUEST: 'ADMIN_LOGOUT_REQUEST',
    ADMIN_LOGOUT_SUCCESS: 'ADMIN_LOGOUT_SUCCESS',
    ADMIN_LOGOUT_ERROR: 'ADMIN_LOGOUT_ERROR',


    //forgot password 
    ADMIN_ADMIN_FORGOT_REQUEST: 'ADMIN_ADMIN_FORGOT_REQUEST',
    ADMIN_ADMIN_FORGOT_SUCCESS: 'ADMIN_ADMIN_FORGOT_SUCCESS',
    ADMIN_ADMIN_FORGOT_ERROR: 'ADMIN_ADMIN_FORGOT_ERROR',

    //forgot password
    ADMIN_ADMIN_RESET_REQUEST: 'ADMIN_ADMIN_RESET_REQUEST',
    ADMIN_ADMIN_RESET_SUCCESS: 'ADMIN_ADMIN_RESET_SUCCESS',
    ADMIN_ADMIN_RESET_ERROR: 'ADMIN_ADMIN_RESET_ERROR',

    //change password
    ADMIN_CHANGE_PASSWORD_REQUEST: 'ADMIN_CHANGE_PASSWORD_REQUEST',
    ADMIN_CHANGE_PASSWORD_SUCCESS: 'ADMIN_CHANGE_PASSWORD_SUCCESS',
    ADMIN_CHANGE_PASSWORD_ERROR: 'ADMIN_CHANGE_PASSWORD_ERROR',
};

export const authActions = {
    login,
    forgotPassword,
    resetPassword,
    changePassword,
    logout,

};

axios.defaults.headers.common['Authorization'] = `${Api.AUTH}`;

//login
function login(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.login(params)
                .then(response => {
                    console.log('auth',Api.AUTH);
                    if (response?.data?.statusCode === 1) {

                        let accessToken = response?.data?.responseData?.accessToken;
                        localStorage.setItem('accessToken', accessToken)
                        localStorage.setItem('isLoggedIn', true)
                        axios.defaults.headers.common['AccessToken'] = localStorage.getItem('accessToken');
                    }
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: authTypes.ADMIN_LOGIN_REQUEST, payload }
    }

    function success(payload) {
        return { type: authTypes.ADMIN_LOGIN_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: authTypes.ADMIN_LOGIN_ERROR, payload }
    }
}

//forgot password
function forgotPassword(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.forgotPassword(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: authTypes.ADMIN_ADMIN_FORGOT_REQUEST, payload }
    }

    function success(payload) {
        return { type: authTypes.ADMIN_ADMIN_FORGOT_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: authTypes.ADMIN_ADMIN_FORGOT_ERROR, payload }
    }
}

//reset password
function resetPassword(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.resetPassword(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: authTypes.ADMIN_ADMIN_RESET_REQUEST, payload }
    }

    function success(payload) {
        return { type: authTypes.ADMIN_ADMIN_RESET_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: authTypes.ADMIN_ADMIN_RESET_ERROR, payload }
    }
}

//change password
function changePassword(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.changePassword(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: authTypes.ADMIN_CHANGE_PASSWORD_REQUEST, payload }
    }

    function success(payload) {
        return { type: authTypes.ADMIN_CHANGE_PASSWORD_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: authTypes.ADMIN_CHANGE_PASSWORD_ERROR, payload }
    }
}

//logout
function logout(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.logout(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: authTypes.ADMIN_LOGOUT_REQUEST, payload }
    }

    function success(payload) {
        return { type: authTypes.ADMIN_LOGOUT_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: authTypes.ADMIN_LOGOUT_ERROR, payload }
    }
}


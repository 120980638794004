import React, { useEffect, useReducer } from 'react';
//footer
import Footer from '../../components/template/Footer';
//side nav
import SideNav from '../../components/template/SideNav';
//topbar
import TopBar from '../../components/template/TopBar';
//event action
import { eventActions } from '../../_action/EventManage';
//pagination
import Pagination from 'react-js-pagination';
//svg icon
import { Active, Draft, Completed, Sold } from '../../SvgIcon';
//connect
import { connect } from 'react-redux';
//loader
import { Loader, MySpinner } from '../../components/common/Loader';
//card
import { Card } from 'react-bootstrap';
//moment
import moment from 'moment';
//modal
import Modal from 'react-bootstrap/Modal';
//antd
import { Select, notification, Empty } from 'antd';

const { Option } = Select;

//Event list screen
function EventList(props) {
  const [eventData, setEventData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      eventdetails: [],
      isLoader: false,
      page: 1,
      limit: 10,
      status: '',
      search: '',
      sortOrder: '',
      sortBy: 'created',
      totalCount: '',
      openPopup: false,
      eventImage: '',
      openImageModal: false,
      popupDefault: false,
      loading: false,
      checked: true,
      featureStatus: '',
    }
  );

  //open image modal
  const handleopenImageModal = (eventImage) => {
    setEventData({
      ...eventData,
      eventImage: eventImage,
      openImageModal: !eventData.openImageModal,
    });
  };

  const closeImageModal = () => {
    setEventData({ ...eventData, openImageModal: false });
  };

  //pagination onhnage
  const handlePageChange = (page) => {
    setEventData({ ...eventData, page: page });
  };

  //seaching on change
  const handleOnSearch = (e) => {
    setEventData({ ...eventData, search: e.target.value });
  };
  //event type filter
  const handleFilter = (e) => {
    setEventData({ ...eventData, status: e });
  };

  //sort by name
  const handleSort = (e) => {
    setEventData({
      isLoader: false,
      page: 1,
      sortBy: e,
      sortOrder: eventData.sortOrder === 'asc' ? 'desc' : 'asc',
    });
  };

  useEffect(() => {
    eventList();
    changeTimeFormat();
  }, [
    eventData.search,
    eventData.page,
    eventData.status,
    eventData.sortBy,
    eventData.sortOrder,
  ]);

  const changeTimeFormat = () => {
    console.log('eventdetails', eventData.eventdetails);
  };

  //get event list
  const eventList = () => {
    let { limit, sortOrder, sortBy, page, status, search } = eventData;
    const currentTimeZoneName =
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      let obj = {
        status: status,
        currentTimeZoneName: currentTimeZoneName,
        limit: limit,
        sortOrder: sortOrder,
        sortBy: sortBy,
        page: search === '' ? (page - 1 ? page - 1 : 0) : '',
        search: search,
      };
      setEventData({ isLoader: true });
      let { dispatch } = props;
      dispatch(eventActions.getEvent(obj)).then((data) => {
        if (data.statusCode === 1) {
          let params = data?.responseData;
          console.log('wertyuiytrertyuytrew', data?.responseData);
          setEventData({
            eventListDetails: params?.result,
            totalCount: params?.count,
            isLoader: false,
          });
        } else {
          notification.error({
            message: 'Error',
            description: data?.error?.responseMessage,
          });
          setEventData({ isLoader: false });
        }
      });
    } catch {}
  };

  //open popup
  const handleOpenPopup = (id) =>
    setEventData({ openPopup: !openPopup, eventId: id });

  //close delete modal
  const closeModal = () =>
    setEventData({
      openPopup: false,
    });

  // delete category list details
  const deleteEventList = (e) => {
    let { eventId } = eventData;
    let obj = {
      userId: eventId,
    };
    let { dispatch } = props;
    setEventData({ loading: true });
    dispatch(eventActions.deleteEvent(obj)).then((data) => {
      // let res = this.props.user;
      if (data?.statusCode == 1) {
        notification.success({
          message: 'Success',
          description: data?.responseData?.message,
        });
        setEventData({ loading: false });
        eventList();
        closeModal();
      } else {
        notification.error({
          message: 'Error',
          description: data?.error?.responseMessage,
        });
        setEventData({ loading: false });
      }
    });
  };

  const addSeprater = (time) => {
    let str1 = time.substring(0, time.length - 5);
    let str2 = time.substring(time.length - 5, time.length);
    //console.log(str, 'gggggggggggg')

    return `${str1}: ${str2}`;
  };
  function dateConvert(params) {
    params.map((data, i) => {
      return data;
    });
  }
  const handleFeaturdEvent = (e) => {
    addFeatured(e);
  };

  // add featured event
  const addFeatured = (params) => {
    let featureStatus = 1;
    params.featureStatus === 1 ? (featureStatus = 0) : (featureStatus = 1);
    let obj = {
      featureStatus: featureStatus,
      eventId: params._id,
    };
    let { dispatch } = props;
    setEventData({ loading: true });
    dispatch(eventActions.addFeaturedEvent(obj)).then((data) => {
      // let res = this.props.user;
      if (data?.statusCode == 1) {
        // notification.success({ message: 'Success', description: 'Event added in featured event.' });
        setEventData({ loading: false });
        eventList();
      } else {
        notification.error({
          message: 'Error',
          description: data?.error?.responseMessage,
        });
        setEventData({ loading: false });
      }
    });
  };

  let {
    page,
    limit,
    totalCount,
    eventListDetails,
    isLoader,
    search,
    openPopup,
    loading,
  } = eventData;
  return (
    <div className="dark-sidebar">
      <div className="main-container">
        <div className="container-fluid page-body-wrapper">
          {isLoader && <Loader />}
          <nav className="navbar fixed-top">
            <div className="navbar-menu-container d-flex align-items-center justify-content-center">
              <div className="sub-heading">
                <h4 className="page-title ">Event Management</h4>
              </div>
              <div className="text-center navbar-brand-container align-items-center justify-content-center">
                <a className="brand-logo" href="/">
                  {' '}
                  <img
                    src={require('../../assets/images/mobile-logo.png')}
                    alt="Event 3"
                    className="mb-3"
                  />
                </a>
              </div>
              <TopBar {...props} />
            </div>
          </nav>
          <SideNav />
          <div className="main-panel">
            <div className="content-wrapper manage-event">
              <div className="row card-margin">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row w-100 align-items-center d-flex">
                        <div className="col-md-5">
                          <h5 className="card-title">
                            Admin{' '}
                            <small className="card-title-secondary">
                              Manage your events below
                            </small>
                          </h5>
                        </div>
                        <div className="col-md-7 p-0">
                          <div className="row w-100 align-items-center justify-content-end d-flex m-0">
                            <div className="search col-lg-8 col-md-7 ml-0">
                              <input
                                type="text"
                                class="form-control"
                                id="event-name"
                                placeholder="Search by event name..."
                                name="search"
                                value={search}
                                onChange={handleOnSearch}
                              />
                            </div>
                            <div className="card-header col-lg-4 col-md-5 justify-content-end border-bottom-0">
                              <div className="card-toolbar">
                                <Select
                                  className="status_button"
                                  placeholder="Filter by Event Type"
                                  style={{ width: 144 }}
                                  onChange={(e) => handleFilter(e)}
                                >
                                  <Option value="">
                                    {' '}
                                    <span className="fa fa-list svg-active"></span>
                                    All
                                  </Option>
                                  <Option value="2">
                                    <Active className="m-3" />
                                    Live
                                  </Option>
                                  <Option value="1">
                                    <Draft />
                                    Draft
                                  </Option>
                                  <Option value="3">
                                    <Completed />
                                    Completed
                                  </Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table widget-8">
                          <thead>
                            <tr>
                              <th className="border-0">S. No.</th>
                              <th
                                className="border-0 cursor-pointer"
                                onClick={() => handleSort('userNameLower')}
                              >
                                <i className="fa fa-sort"></i> Promoter Name
                              </th>
                              <th
                                className="border-0 cursor-pointer"
                                onClick={() => handleSort('eventNameLower')}
                                style={{ width: '120px' }}
                              >
                                <i className="fa fa-sort"></i> Event Name
                              </th>
                              <th
                                className="border-0 cursor-pointer"
                                onClick={() => handleSort('eventDate')}
                              >
                                <i className="fa fa-sort"></i> Event Date
                              </th>
                              <th className="border-0">Start Time</th>
                              <th className="border-0">End Time</th>
                              <th className="border-0">Time Zone</th>
                              <th className="border-0">Image</th>
                              <th className="border-0  text-center">Action</th>
                              <th className="border-0  text-center">
                                Featured Event
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {eventListDetails?.map((item, index) => {
                              console.log('item', item);
                              // let multiEventDate = item?.eventDate?.forEach(element => console.log(element));
                              // {moment(item?.eventDate).format("MMM DD, yyyy")}
                              return (
                                <tr>
                                  <td>{(page - 1) * 10 + index + 1}</td>
                                  <td
                                    className="cursorptr"
                                    onClick={() =>
                                      props.history.push(
                                        `/event-details/${item._id}`
                                      )
                                    }
                                  >
                                    {item?.userName}
                                  </td>
                                  <td
                                    className="cursorptr"
                                    onClick={() =>
                                      props.history.push(
                                        `/event-details/${item._id}`
                                      )
                                    }
                                  >
                                    {item?.eventName}
                                  </td>
                                  {/* <td>{moment(dateConvert(item?.eventDate)).format("MMM DD, yyyy")}</td> */}
                                  <td>
                                    {item.startTimeStamp
                                      ? moment(item.eventDate[0]).format(
                                          'MMM DD, yyyy'
                                        )
                                      : ''}
                                    -
                                    {item.endTimeStamp
                                      ? moment(item.eventDate[1]).format(
                                          'MMM DD, yyyy'
                                        )
                                      : ''}{' '}
                                  </td>
                                  <td>
                                    {item.startTime}
                                    {/* {item.startTimeStamp
                                      ? new Date(
                                          item.startTimeStamp
                                        ).toLocaleTimeString('en-us', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                        })
                                      : '-'} */}
                                  </td>

                                  <td>
                                    {item.endTime}
                                    {/* {item.endTimeStamp
                                      ? new Date(
                                          item.endTimeStamp
                                        ).toLocaleTimeString('en-us', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                        })
                                      : '-'} */}
                                  </td>
                                  <td>
                                   {item.timeZone}
                                  </td>
                                  <td className="cursorptr">
                                    {item.eventImage ? (
                                      <img
                                        src={item?.eventImage}
                                        onClick={() =>
                                          handleopenImageModal(item.eventImage)
                                        }
                                        style={{ maxWidth: '50px' }}
                                      />
                                    ) : (
                                      <img
                                        src={require('../../assets/images/not.png')}
                                        style={{ maxWidth: '50px' }}
                                      />
                                    )}
                                  </td>

                                  <td>
                                    <ul className="list-inline mb-0  text-center">
                                      <li
                                        className="list-inline-item cursorptr pr-3"
                                        onClick={() =>
                                          props.history.push(
                                            `/event-edit/${item._id}`
                                          )
                                        }
                                      >
                                        {/* {
                                                                                    item.status === 3 ? '' : */}
                                        <i className="fa fa-edit"></i>
                                        {/* } */}
                                      </li>
                                      <li
                                        className="list-inline-item cursorptr pr-3"
                                        onClick={() =>
                                          handleOpenPopup(item._id)
                                        }
                                      >
                                        <i className="fa fa-trash"></i>
                                      </li>
                                      <li
                                        className="list-inline-item cursorptr"
                                        onClick={() =>
                                          props.history.push(
                                            `/event-details/${item._id}`
                                          )
                                        }
                                      >
                                        <i className="fa fa-eye"></i>
                                      </li>
                                    </ul>
                                  </td>
                                  <td>
                                    <div className="text-center cursorptr">
                                      {item.status === 2 ? (
                                        <input
                                          type="Checkbox"
                                          name="featureStatus"
                                          checked={
                                            item.featureStatus === 1
                                              ? true
                                              : false
                                          }
                                          value={item.featureStatus}
                                          onChange={() =>
                                            handleFeaturdEvent(item)
                                          }
                                        />
                                      ) : item.status == 1 ? (
                                        'Draft'
                                      ) : (
                                        'Completed'
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {
                        <div>
                          {!isLoader && eventListDetails?.length === 0 && (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                        </div>
                      }
                    </div>
                    <div className="card-footer bg-white">
                      <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row mt-4">
                        {console.log(
                          'jhugyfdrfthgjyhkjlhb',
                          limit,
                          page,
                          totalCount,
                          eventListDetails
                            ? eventListDetails.length
                            : eventListDetails
                        )}
                        <p className="mb-3 mb-sm-0">
                          Showing {page == 1 ? page : page * limit - 10 + 1} to{' '}
                          {eventListDetails
                            ? eventListDetails.length === 10
                              ? page * limit - 10 + 10
                              : page * limit - 10 + eventListDetails.length
                            : ''}{' '}
                          of {totalCount} events
                        </p>
                        <nav>
                          <ul>
                            <Pagination
                              activePage={page}
                              itemsCountPerPage={limit}
                              totalItemsCount={totalCount}
                              pageRangeDisplayed={5}
                              onChange={handlePageChange}
                            />
                          </ul>
                        </nav>
                      </div>
                      <Modal
                        size="md"
                        show={openPopup}
                        onHide={closeModal}
                        aria-labelledby="example-modal-sizes-title-sm"
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <div className="cm_modal">
                          <div className="modal-body">
                            <h4 className="mb-4">
                              <b>Are you sure, you want to delete this?</b>
                            </h4>
                            <br />
                            <div className="bg-white pull-right mb-4">
                              <button
                                className="btn btn-light"
                                onClick={closeModal}
                              >
                                No
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary ml-2"
                                onClick={() => deleteEventList()}
                              >
                                Yes &nbsp;
                                {loading && <MySpinner />}
                              </button>
                            </div>
                            <br />
                            <br />
                          </div>
                        </div>
                      </Modal>

                      <Modal
                        size="md"
                        show={eventData.openImageModal}
                        onHide={closeImageModal}
                        aria-labelledby="example-modal-sizes-title-sm"
                      >
                        {/** <Modal.Header closeButton>
                                                </Modal.Header> */}
                        <Card
                          className="m-3"
                          style={{ width: '29rem', innerHeight: '100%' }}
                        >
                          <Card.Img
                            variant="top"
                            src={eventData?.eventImage}
                            alt="Image not avaliable."
                          />
                        </Card>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  const { props, getEvent, user } = state;
  return {
    props,
    getEvent,
    user,
  };
}
export default connect(mapStateToProps)(EventList);

import React, { useReducer } from 'react';
//link
import { Link } from 'react-router-dom';
//connect
import { connect } from "react-redux";
//Inline error
import InlineError from '../../components/common/InlineError';
//validation
import { validateEmail } from '../../components/common/Validation';
//action
import { authActions } from '../../_action/Auth';
//alert
import { MyAlert } from '../../components/common/MyAlert';
//loader
import { MySpinner } from '../../components/common/Loader';
//success message
import SuccessMessage from '../../screens/auth/SuccessMessage';
import {notification} from 'antd'



//forgot pass screen
function ForgotPassword(props) {
	const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
		{
			email: '',
			emailError: '',
			loading: false,
			erroStatus: false,
			message: '',
			showMessage: false,
			successMessage: false,
			isResponse: false
		})
	const handleOnChange = (e) => {
		setUserInput({ ...userInput, [e.target.name]: e.target.value, emailError: '' })
	}
	//forgot password
	const handleSubmit = () => {
		if (userInput.email === '')
			setUserInput({ emailError: '*Please enter email.' })
		else if (!validateEmail(email).status) {
			setUserInput({ emailError: '*Please enter valid email.' })
		}
		else {
			let req = {
				email: userInput.email
			}
			let { dispatch } = props;
			setUserInput({ loading: true });
			dispatch(authActions.forgotPassword(req)).then(data => {
				if (data.statusCode === 1) {
					setUserInput({ loading: false, isResponse: true })
				}
				else {
					notification.error({ message: 'Error', description: data?.error?.responseMessage })

					setUserInput({
						loading: false,isResponse: false, 
					})
				}
			}
			);
		}
	}

	let { loading, email, emailError, erroStatus, message, showMessage, isResponse } = userInput;
	return (
		<div className="base-color-option-2">
			<div classNameName="main-container">
				{
					isResponse === true ?
						<SuccessMessage /> :
						<div className="container-fluid page-body-wrapper full-page-wrapper">
							<div className="user-login login-v2">
								<div className="row no-gutters">
									<div className="col-lg-12">
										<div className="logo-container text-center">
											<Link className="brand-logo login-page" to="/"> <img src={require('../../assets/images/mobile-logo.png')} alt="" title="" /></Link>
										</div>
									</div>
								</div>
								<div classNa="justify-content-center align-self-center">
									<div className="">
										<div className="user-login-content">
											{
												showMessage &&
												<MyAlert
													message={message}
													type={erroStatus}
												/>
											}
											<div className="cm_card card">
												<div className="p-4">
													<h4 className="login-title mt-2 cm_bold">FORGOT PASSWORD?</h4>
													<p className="text-muted mb-4">Enter your email and we'll send you instructions on how to reset your password.</p>
													<div className="needs-validation" >
														<div className="form-group inner-addon">
															<input type="email"
																className="form-control"
																id="exampleInputEmail1"
																aria-describedby="emailHelp"
																placeholder="Email"
																maxLength="75"
																autocomplete="off"
																onChange={handleOnChange}
																value={email}
																name="email"
															/>
															<InlineError
																message={emailError}
															/>
														</div>
														<div className="text-center mb-3">
															<button type="button" className="btn btn-primary btn-block mt-3 mb-5" disabled={loading} onClick={handleSubmit}>SUBMIT
															&nbsp;
															{
																	loading && < MySpinner />
																}
															</button>
														</div>
														<div className="text-center">
															<div className="text-muted  text-uppercase"><Link to="/" className="link">Back to sign in</Link></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
				}
			</div>
		</div>

	);
}
function mapStateToProps(state) {
	const { props, forgotPassword, user } = state;
	return {
		props,
		forgotPassword,
		user
	}
}
export default connect(mapStateToProps)(ForgotPassword);



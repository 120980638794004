/**
 * @about
 * This file conatins api end points
 */
import siteSetting from "../config/env/Index";

export default {

    //Authorization
    'AUTH': siteSetting.api.AUTH,
    //auth
    'ADMIN_LOGIN': siteSetting.api.url + "admin/login",
    'ADMIN_LOGOUT': siteSetting.api.url + "admin/logout",
    'ADMIN_FORGOT_PASSWORD': siteSetting.api.url + "admin/forget-password",
    'ADMIN_RESET_PASSWORD': siteSetting.api.url + "admin/reset-password",
    'ADMIN_CHANGE_PASSWORD': siteSetting.api.url + "admin/change-password",


    //category
    'ADMIN_GET_CATEGORY': siteSetting.api.url + "category/get-categories",
    'ADMIN_DELETE_CATEGORY': siteSetting.api.url + "category/delete",
    'ADMIN_EDIT_CATEGORY': siteSetting.api.url + "category/update",
    'ADMIN_ADD_CATEGORY': siteSetting.api.url + "category/create",

    //event 
    'ADMIN_GET_EVENT': siteSetting.api.url + "event/get-events",
    'ADMIN_DELETE_EVENT': siteSetting.api.url + "event/delete",
    'ADMIN_VIEW_EVENT_DETAILS': siteSetting.api.url + "event/event-details",
    'ADMIN_GET_NEWSFEED': siteSetting.api.url + "newsfeed/get-newsfeeds",
    'ADMIN_EDIT_EVENT': siteSetting.api.url + "event/update",
    'ADMIN_GET_TICKET_LIST': siteSetting.api.url + "ticket/get-tickets",
    'ADMIN_GET_PAYOUT': siteSetting.api.url + "payout/get-payouts",
    'ADMIN_GET_COUPON': siteSetting.api.url + "coupon/get-coupons",
    'ADMIN_GET_ATTENDEES': siteSetting.api.url + "attandee/get-attandees",
    'ADD_FEES_PER_EVENT': siteSetting.api.url + "event/fees",
    'ADMIN_ADD_FEATURED_EVENT': siteSetting.api.url + "event/featured",



    //user /
    'ADMIN_ADD_ATTENDEE': siteSetting.api.url + "user/create-attandee",
    'ADMIN_EDIT_ATTENDEE': siteSetting.api.url + "user/update-attandeeProfile",
    'ADMIN_GET_USER': siteSetting.api.url + "user/get-users",
    'ADMIN_DELETE_USER': siteSetting.api.url + "user/delete",
    'ADMIN_GET_PARTICULAR_ATTENDEE': siteSetting.api.url + "user/user-details",

    //dashboard
    'ADMIN_GET_DASHBOARD_DETAILS': siteSetting.api.url + "admin/dashboard",

}
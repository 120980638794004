import { combineReducers } from 'redux';
// import alert from './alert.Action'


const rootReducer = combineReducers({
    // alert,
    

});

export default rootReducer;
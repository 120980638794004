/**
 * @about this file contains api
 */

import axios from "axios";
import Api from "./Api";
import headers from "../config/env/Index";
import { ResponseFilter } from "../_helper/ResponseHandler";

export const usersService = {
  login,
  logout,
  getCategory,
  deleteCategory,
  editCategory,
  getEvent,
  viewEventDetails,
  getNewsFeed,
  getUser,
  deleteUser,
  forgotPassword,
  resetPassword,
  changePassword,
  addCategory,
  editEvent,
  ticketList,
  getPayout,
  couponList,
  getAttendees,
  deleteEvent,
  addAttendee,
  editAttendee,
  getDashboardDetails,
  getParticularAttendee,
  addEventFees,
  addFeaturedEvent,
};

const headersApplicationJson = {
  "Content-Type": "application/json",
};
const headersMultipart = {
  "Content-type": "multipart/form-data",
};

function login(params) {
  return axios.post(Api.ADMIN_LOGIN, params);
}
function logout(params) {
  return axios.post(Api.ADMIN_LOGOUT, params, {
    headers: headersApplicationJson,
  });
}
function forgotPassword(params) {
  return axios.post(Api.ADMIN_FORGOT_PASSWORD, params, {
    headers: headersApplicationJson,
  });
}
function resetPassword(params) {
  return axios.post(Api.ADMIN_RESET_PASSWORD, params, {
    headers: headersApplicationJson,
  });
}
function changePassword(params) {
  return axios.put(Api.ADMIN_CHANGE_PASSWORD, params, {
    headers: headersApplicationJson,
  });
}
function getCategory(params) {
  return axios.post(Api.ADMIN_GET_CATEGORY, params, {
    headers: headersApplicationJson,
  });
}
function deleteCategory(params) {
  return axios.delete(`${Api.ADMIN_DELETE_CATEGORY}/${params.categoryId}`, {
    headers: headersApplicationJson,
  });
}
function editCategory(params) {
  return axios.put(Api.ADMIN_EDIT_CATEGORY, params, {
    headers: headersApplicationJson,
  });
}
function getEvent(params) {
  return axios.post(Api.ADMIN_GET_EVENT, params, {
    headers: headersApplicationJson,
  });
}
function viewEventDetails(obj) {
  console.log("datattat", obj);
  return axios.get(`${Api.ADMIN_VIEW_EVENT_DETAILS}/${obj.eventId}`, {
    headers: headersApplicationJson,
  });
}
function getNewsFeed(params) {
  return axios.get(`${Api.ADMIN_GET_NEWSFEED}/?${params}`, {
    headers: headersApplicationJson,
  });
}


function getUser(params) {
  return axios.get(
    `${Api.ADMIN_GET_USER}?page=${params.page}&limit=${params.limit}&search=${params.search}&role=${params.role}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}`,
    { headers: headersApplicationJson }
  );
}
function deleteUser(params) {
  return axios.delete(`${Api.ADMIN_DELETE_USER}/${params.userId}`, {
    headers: headersApplicationJson,
  });
}
function addCategory(params) {
  return axios.post(Api.ADMIN_ADD_CATEGORY, params, {
    headers: headersApplicationJson,
  });
}
function editEvent(params) {
  return axios.put(Api.ADMIN_EDIT_EVENT, params, { headers: headersMultipart });
}
function ticketList(params) {
  return axios.post(Api.ADMIN_GET_TICKET_LIST, params, {
    headers: headersApplicationJson,
  });
}
function getPayout(params) {
    return axios.get(Api.ADMIN_GET_PAYOUT, params, { headers: headersApplicationJson })
}
function couponList(params) {
  return axios.post(Api.ADMIN_GET_COUPON, params, {
    headers: headersApplicationJson,
  });
}
function getAttendees(params) {
  return axios.post(Api.ADMIN_GET_ATTENDEES, params, {
    headers: headersApplicationJson,
  });
}
function deleteEvent(params) {
  return axios.delete(`${Api.ADMIN_DELETE_EVENT}/${params.userId}`, {
    headers: headersApplicationJson,
  });
}
function addAttendee(params) {
  return axios.post(Api.ADMIN_ADD_ATTENDEE, params, {
    headers: headersApplicationJson,
  });
}
function editAttendee(params) {
  return axios.put(Api.ADMIN_EDIT_ATTENDEE, params, {
    headers: headersApplicationJson,
  });
}
function getDashboardDetails(params) {
  console.log("wertyuio", headers.headers);
  return axios.get(Api.ADMIN_GET_DASHBOARD_DETAILS, params, {
    headers: headers.headers,
  });
}

function getParticularAttendee(obj) {
  console.log("datattat", obj);
  return axios.get(`${Api.ADMIN_GET_PARTICULAR_ATTENDEE}/${obj.userId}`, {
    headers: headersApplicationJson,
  });
}

function addEventFees(params) {
  return axios.put(Api.ADD_FEES_PER_EVENT, params, {
    headers: headersApplicationJson,
  });
}
function addFeaturedEvent(params) {
  return axios.put(Api.ADMIN_ADD_FEATURED_EVENT, params, {
    headers: headersApplicationJson,
  });
}

import React from 'react';
import Dropzone from 'react-dropzone';
import { Loader, Delete, Edit } from "../../../SvgIcon";
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal'
import { usersService } from '../../../services/ApiServices'
import { ResponseFilter } from '../../../_helper/ResponseHandler';
import { message } from 'antd';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
import ReactCrop from 'react-image-crop';
import Pagination from "react-js-pagination";
import 'react-image-crop/dist/ReactCrop.css';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"; // see installation section above for versions of NPM older than 3.0.0





class UpdateEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 5,
            newfeedImgPreview: '',
            imageErrorMsg: '',
            feedErrorMsg: '',
            editfeedErrorMsg: '',
            imageErrorMsg1: '',
            imageEditErrorMsg: '',
            disabledBtn: false,
            details: [],
            feed: '',
            cropResult: null,
            eventId: this.props.eventId,
            openEditPopup: false,
            imageUpload: '',
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 16 / 9
            },
            croppedImageUrl: '',
            imageResize: '',
            sortOrder: "",
            sortBy: "created",
            newsfeedImage: ''
        };


    }
    componentDidMount() {
        this.getNewsFeedList();

    }

    handleImageChange = droppedFile => {

        let reader = new FileReader();

        let imageUpload = droppedFile[0];
        var imageName = imageUpload.name;
        var idxDot = imageUpload.name.lastIndexOf(".") + 1;
        var extFile = imageUpload.name.substr(idxDot, imageUpload.name.length).toLowerCase();
        console.log(imageName, 'imageNameimageNameimageNameimageName');

        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "svg") {
            this.setState({
                newfeedImgPreview: '',
                eventImage: droppedFile[0],
                imageEditErrorMsg: '',
                imageName: imageName,
                docErrorMsg: '',
                prev: false
            })
            reader.addEventListener("load", () => {
                this.setState({ imagePreview: reader.result, eventImage: droppedFile[0], departmentIconErrorMsg: '', imageName })
            }, false);
            if (imageUpload) { reader.readAsDataURL(imageUpload) }

        }
        else {
            this.setState({
                imageEditErrorMsg: 'Only jpg/jpeg, png, and svg files are allowed!'
            })
        }

    }

    onSelectFile = e => {
        this.setState({
            imageErrorMsg1: '',
        })
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            var imageUpload = e.target.files[0];
            var idxDot = imageUpload.name.lastIndexOf(".") + 1;
            var extFile = imageUpload.name.substr(idxDot, imageUpload.name.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "svg") {
                reader.addEventListener('load', () =>
                    this.setState({ src: reader.result })
                );
                this.setState({ imageUpload: e.target.files[0].name })
                reader.readAsDataURL(e.target.files[0]);
                this.setState({
                    imageErrorMsg: '',
                })
            }
            else {
                this.setState({
                    imageErrorMsg: 'Only jpg/jpeg, png, and svg files are allowed!'
                })
            }
        }
    };

    onImageLoaded = image => {
        console.log("image>>", image);

        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrlFile = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.imageUpload
                // 'newFile.jpeg'
            );
            this.setState({ croppedImageUrl: window.URL.createObjectURL(croppedImageUrlFile), croppedImageUrlFile },
                () => {
                    this.imageResize(this.state.croppedImageUrlFile)
                }
            );
        }
    }
    async imageResize(image) {
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        var compressedFile = await imageCompression(image, options)
        // console.log("compressedFile>>>", compressedFile);

        this.setState({ imageUpload: compressedFile })
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    return;
                }
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg');
        });
    }


    addStatusUpdates = () => {
        if (this.handleValidetion()) {
            var formData = new FormData();
            formData.append("eventId", this.state.eventId)
            formData.append("newsfeed", this.state.feed)
            formData.append("newsfeedImage", this.state.imageUpload)
            this.setState({ disabledBtn: true });
            usersService.newdFeedAdd(formData).then(data => {
                if (data.data.statusCode == 1) {

                    message.success("Added Successfully!");
                    this.getNewsFeedList();
                    this.setState({ feed: '', imageUpload: '', src: '', disabledBtn: false })

                }
                else {
                    this.setState({
                        errors: data.data.error.responseMessage,
                        disabledBtn: false

                    })
                }
            });
        }
    }

    closeModal = () => {
        this.setState({
            openEditPopup: false,
            openDeletePopup: false,
            disabledBtn: false,
            newfeedImgPreview: '',
            imagePreview: '',
            feed: '', imageUpload: '', src: '', disabledBtn: false


        });
    }

    handleOpenDeletePopup = newsfeedId => this.setState({ openDeletePopup: !this.state.openDeletePopup, newsfeedId });


    handleOpenEditPopup = ({
        newsfeed,
        newsfeedImage,
        _id,

    }) => this.setState({
        openEditPopup: !this.state.openEditPopup,
        editfeed: newsfeed,
        newfeedImgPreview: newsfeedImage,
        newsfeedId: _id
    });




    handleChange = e => this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: '',
        inputErrorMsg: ''

    });

    handlePageChange = (page) => {
        this.setState({ page: page, loader: true }, () => this.getNewsFeedList());
    }


    handleValidetion = () => {
        let validate = true;
        let { feed, imageUpload } = this.state;
        if (feed === '' || feed === undefined) {
            validate = false;
            this.setState({
                feedErrorMsg: 'Please enter update.'
            })
        }
        if (imageUpload === '' || imageUpload === undefined || imageUpload == null) {
            validate = false;
            this.setState({
                imageErrorMsg1: 'Please attach an image.'
            })

        }




        return validate
    }

    handleEditValidetion = () => {
        let validate = true;
        let { editfeed, imageUpload } = this.state;
        if (editfeed === '' || editfeed === undefined) {
            validate = false;
            this.setState({
                editfeedErrorMsg: 'Please enter update.'
            })
        }





        return validate
    }
    getNewsFeedList = () => {

        let { search, limit, page, sortOrder, sortBy } = this.state;


        let params = `eventId=${this.props.eventId}&page=${page - 1}&limit=${limit}&sortBy=${sortBy}`;


        this.setState({ loader: true })
        usersService.getNewsFeed(params).then(res => {
            console.log(res, 'kkkkkkkkk>>>>>>>>')
            let { status, resData } = ResponseFilter(res);
            if (status) {
                this.setState({ loader: false })
                this.setState({
                    newsFeedDetails: resData.result,
                    totalCount: resData.count,

                })

            }
            else {
                this.setState({ loader: false })
            }

        })
    }

    handleEditSubmit = (newsfeedId) => {
        if (this.handleEditValidetion()) {
            var formData = new FormData();
            formData.append("newsfeedId", this.state.newsfeedId)
            formData.append("eventId", this.state.eventId)
            formData.append("newsfeed", this.state.editfeed)
            formData.append("newsfeedImage", this.state.imageUpload ? this.state.imageUpload : this.state.newfeedImgPreview)
            this.setState({ disabledBtn: true });
            usersService.newdFeedUpdate(formData).then(data => {
                if (data.data.statusCode == 1) {

                    message.success("Updated Successfully!");
                    this.getNewsFeedList();
                    this.closeModal();
                    this.setState({ feed: '', imageUpload: '', src: '', disabledBtn: false })

                }
                else {
                    this.getNewsFeedList();
                    this.closeModal();
                    this.setState({
                        errors: data.data.error.responseMessage,
                        disabledBtn: false

                    })
                }
            });
        }
    }
    handleCancel = () => {
        this.setState({ feed: '', imageUpload: '', src: '', disabledBtn: false })
    }

    handleDelete = (data) => {
        let obj = {
            'newsfeedId': this.state.newsfeedId,
            'status': 2
        }
        const accessToken = localStorage.getItem('accessToken');
        usersService.deleteNewsFeed(obj, accessToken).then(res => {
            if (res.data.statusCode === 1) {
                message.success("Delete Successfully!");
                this.setState({
                    openDeletePopup: false
                }, () => this.getNewsFeedList())
            }
            else {
                this.setState({
                    openDeletePopup: false
                })
            }
        })
    }


    render() {
        let { newfeedImgPreview, newsfeedImage, imagePreview, imageErrorMsg, imageEditErrorMsg, imageErrorMsg1, disabledBtn, newsFeedDetails, feedErrorMsg, editfeedErrorMsg } = this.state;
        return (



            <React.Fragment>
                <div className="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details">
                    <div className="col-md-12">
                        <div className="card card-margin">
                            <div className="card-header">
                                <h5 className="card-title">Event Status Update</h5>
                            </div>
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="form-group">
                                        <label for="exampleTextarea">What is the latest?</label>

                                        <textarea className="form-control" id="exampleTextarea" rows="3" name="feed" value={this.state.feed} onChange={this.handleChange}></textarea>
                                        {feedErrorMsg ? <div className="error ml-0">{feedErrorMsg}</div> : null}
                                    </div>


                                    <div class="row">
                                        <button type="button" class="btn btn-sm btn-soft-dark mt-1 m-l-15 cm_hide_upload"> <i class="far fa-image x"></i> Attach an Image
                                        <input
                                                type="file"
                                                className=""
                                                accept=".png, .jpg, .jpeg"
                                                title=""
                                                onChange={this.onSelectFile}
                                            />
                                        </button>


                                    </div>
                                    {imageErrorMsg1 ? <div className="error ml-0">{imageErrorMsg1}</div> : null}

                                </form>

                            </div>
                            <div>

                                {
                                    this.state.src ?
                                        <div style={{ width: "100%" }} className="newsfeed">


                                            <ReactCrop
                                                src={this.state.src}
                                                crop={this.state.crop}
                                                ruleOfThirds
                                                onImageLoaded={this.onImageLoaded}
                                                onComplete={this.onCropComplete}
                                                onChange={this.onCropChange}
                                            />
                                        </div> : ''
                                }



                            </div>
                            {imageErrorMsg ? <div className="error ml-0">{imageErrorMsg}</div> : null}
                            <div className="card-footer bg-white ">
                                <button type="button" className="btn btn-light" onClick={this.handleCancel}>Cancel</button>
                                <button type="submit" className="btn btn-primary mr-2" disabled={disabledBtn} onClick={() => this.addStatusUpdates()}>Post Update</button>
                            </div>
                        </div>
                        <div className="card m-t-40">


                            {/* ..................................List & Edit & Delete............................... */}
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table widget-8">
                                        <thead>
                                            <tr>

                                                <th className="border-0 cm_bold">image</th>
                                                <th className="border-0 cm_bold">Status</th>
                                                <th className="border-0 cm_bold">Delete</th>
                                                <th className="border-0 cm_bold">Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {

                                                !_.isEmpty(newsFeedDetails) ?
                                                    <React.Fragment>
                                                        {newsFeedDetails.map((item, i) => {
                                                            return (



                                                                <tr>



                                                                    <td>

                                                                        <div className="widget-8-title-wrapper">
                                                                            <img src={item.newsfeedImage} className="widget-8-img" alt="" title="" />
                                                                            <div className="widget-8-product-info">

                                                                                <div className="attribute">{item.created ? moment(item.created).utc().format('MMMM') : ''}</div>
                                                                                <div className="attribute">{item.created ? moment(item.created).utc().format(' DD ,yyyy') : ''}</div>

                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div className="widget-status-desc">
                                                                            <p className="card-p">{item.newsfeed}.</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>

                                                                        <span onClick={() => this.handleOpenDeletePopup(item._id)}>

                                                                            <Delete />
                                                                        </span>

                                                                    </td>

                                                                    <td>
                                                                        <div className="widget-8-action-wrapper">
                                                                            <span className="btn btn-sm btn-flash-primary" onClick={() => this.handleOpenEditPopup(item)} type="button" >

                                                                                <Edit />
                                                                            </span>
                                                                        </div>
                                                                    </td>

                                                                </tr>

                                                            )
                                                        })}
                                                    </React.Fragment> : ''

                                            }


                                        </tbody>
                                    </table>

                                </div>
                                {

                                    !_.isEmpty(newsFeedDetails) ? '' : <div className="text-center"><p className="mb-4 mb-sm-0 mt-4 ">No data present </p></div>

                                }

                            </div>



                        </div>
                    </div>
                </div>
                <div className="card-footer bg-white">
                    <div className="d-flex align-items-end justify-content-between flex-column flex-sm-row mt-2">
                        <p className="mb-sm-0">Showing {this.state.page} to {this.state.limit} of {this.state.totalCount} updates</p>

                        <nav>
                            <ul >
                                {/* {this.state.totalCount > 4 && !_.isEmpty(ticketsList) ? */}
                                <Pagination
                                    activePage={this.state.page}
                                    itemsCountPerPage={this.state.limit}
                                    totalItemsCount={this.state.totalCount}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange}
                                />
                                {/* : ''} */}
                            </ul>
                        </nav>

                    </div>
                </div>

                <Modal
                    size="md"
                    show={this.state.openDeletePopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"

                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <div className="cm_modal">


                        <div className="modal-body">
                            <h4 className="mb-4">Are you sure, you want delete this update?</h4>
                            <br />

                            <div className="bg-white pull-right mb-4">
                                <button className="btn btn-light" type="button" onClick={this.closeModal}>No</button>
                                <button type="submit" className="btn btn-primary ml-2" onClick={() => this.handleDelete(newsFeedDetails)}>Yes</button>

                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </Modal>

                <Modal
                    size="lg"
                    show={this.state.openEditPopup}
                    onHide={this.closeModal}

                    aria-labelledby="example-modal-sizes-title-sm"

                >
                    <Modal.Header closeButton>
                        <h5 className="card-title mt-4">Event Status Update</h5>
                    </Modal.Header>



                    <div id="custom-content" class="white-popup-block">


                        <div class="card-body">
                            <form class="forms-sample" >
                                <div className="card card-margin">

                                    <div className="card-body">
                                        <form className="forms-sample">
                                            <div className="form-group">
                                                <label for="exampleTextarea">What is the latest?</label>

                                                <textarea className="form-control" id="exampleTextarea" rows="3" name="editfeed" value={this.state.editfeed} onChange={this.handleChange}></textarea>
                                                {editfeedErrorMsg ? <div className="error ml-0">{editfeedErrorMsg}</div> : null}
                                            </div>

                                            <Dropzone onDrop={acceptedFile => this.handleImageChange(acceptedFile)}>
                                                {({ getRootProps, getInputProps, isDragActive }) => (
                                                    <section>

                                                        <div
                                                            style={{ background: newfeedImgPreview ? `url(${newfeedImgPreview})` : '' }}
                                                            className="upload_img" {...getRootProps()}
                                                        >

                                                            {this.state.imagePreview ?
                                                                <ReactCrop
                                                                    className="nesweed-crop"
                                                                    src={this.state.imagePreview}
                                                                    crop={this.state.crop}
                                                                    onImageLoaded={this.onImageLoaded}
                                                                    onComplete={this.onCropComplete}
                                                                    onChange={this.onCropChange}
                                                                />
                                                                :
                                                                <input {...getInputProps()} accept=".png, .jpg, .jpeg" className="image" type="file" />
                                                            }
                                                            {
                                                                imagePreview || newfeedImgPreview ?

                                                                    ''

                                                                    : <p className="background container"><br /><span className="blue mt-3">Drag and drop a file here or click</span></p>
                                                            }

                                                        </div>


                                                    </section>
                                                )}
                                            </Dropzone>
                                            {imageEditErrorMsg ? <div className="error ml-0">{imageEditErrorMsg}</div> : null}
                                        </form>
                                    </div>

                                </div>
                                <div class="card-footer bg-white">
                                    <button type="button" class="btn btn-light" onClick={this.closeModal}>Cancel</button>
                                    <button type="button" class="btn btn-primary ml-2" onClick={this.handleEditSubmit}>Save</button>

                                </div>
                            </form>

                        </div>
                    </div>


                </Modal>
            </React.Fragment>


        );
    }
}


export default UpdateEdit;

/**
 * @About
 * This file consist all action of user management.
 */

import promise from 'bluebird'
import { usersService } from "../services/ApiServices";
import { ResponseFilter } from '../_helper/ResponseHandler'

export const userTypes = {

    //get user 
    ADMIN_GET_USER_REQUEST: 'ADMIN_GET_USER_REQUEST',
    ADMIN_GET_USER_SUCCESS: 'ADMIN_GET_USER_SUCCESS',
    ADMIN_GET_USER_ERROR: 'ADMIN_GET_USER_ERROR',

    //delete user
    ADMIN_DELETE_USER_REQUEST: 'ADMIN_DELETE_USER_REQUEST',
    ADMIN_DELETE_USER_SUCCESS: 'ADMIN_DELETE_USER_SUCCESS',
    ADMIN_DELETE_USER_ERROR: 'ADMIN_DELETE_USER_ERROR',

    //add attendee
    ADMIN_ADD_ATTENDEE_REQUEST: 'ADMIN_ADD_ATTENDEE_REQUEST',
    ADMIN_ADD_ATTENDEE_SUCCESS: 'ADMIN_ADD_ATTENDEE_SUCCESS',
    ADMIN_ADD_ATTENDEE_ERROR: 'ADMIN_ADD_ATTENDEE_ERROR',

    //add attendee
    ADMIN_EDIT_ATTENDEE_REQUEST: 'ADMIN_EDIT_ATTENDEE_REQUEST',
    ADMIN_EDIT_ATTENDEE_SUCCESS: 'ADMIN_EDIT_ATTENDEE_SUCCESS',
    ADMIN_EDIT_ATTENDEE_ERROR: 'ADMIN_EDIT_ATTENDEE_ERROR',

    //get particular attendee
    ADMIN_GET_PARTICULAR_ATTENDEE_REQUEST: 'ADMIN_GET_PARTICULAR_ATTENDEE_REQUEST',
    ADMIN_GET_PARTICULAR_ATTENDEE_SUCCESS: 'ADMIN_GET_PARTICULAR_ATTENDEE_SUCCESS',
    ADMIN_GET_PARTICULAR_ATTENDEE_ERROR: 'ADMIN_GET_PARTICULAR_ATTENDEE_ERROR',

    //dashboard
    ADMIN_GET_ALL_DETAILS_REQUEST: 'ADMIN_GET_ALL_DETAILS_REQUEST',
    ADMIN_GET_ALL_DETAILS_SUCCESS: 'ADMIN_GET_ALL_DETAILS_SUCCESS',
    ADMIN_GET_ALL_DETAILS_ERROR: 'ADMIN_GET_ALL_DETAILS_ERROR',
};

export const userActions = {
    getUser,
    deleteUser,
    addAttendee,
    editAttendee,
    getDashboardDetails,
    getParticularAttendee,

};

//get user
function getUser(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getUser(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: userTypes.ADMIN_GET_USER_REQUEST, payload }
    }

    function success(payload) {
        return { type: userTypes.ADMIN_GET_USER_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: userTypes.ADMIN_GET_USER_ERROR, payload }
    }
}

//delete user
function deleteUser(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.deleteUser(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: userTypes.ADMIN_DELETE_USER_REQUEST, payload }
    }

    function success(payload) {
        return { type: userTypes.ADMIN_DELETE_USER_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: userTypes.ADMIN_DELETE_USER_ERROR, payload }
    }
}

//add attendee
function addAttendee(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.addAttendee(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: userTypes.ADMIN_ADD_ATTENDEE_REQUEST, payload }
    }

    function success(payload) {
        return { type: userTypes.ADMIN_ADD_ATTENDEE_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: userTypes.ADMIN_ADD_ATTENDEE_ERROR, payload }
    }
}
//add attendee
function editAttendee(params) {

    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.editAttendee(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: userTypes.ADMIN_EDIT_ATTENDEE_REQUEST, payload }
    }

    function success(payload) {
        return { type: userTypes.ADMIN_EDIT_ATTENDEE_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: userTypes.ADMIN_EDIT_ATTENDEE_ERROR, payload }
    }
}

//get particular details
function getParticularAttendee(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getParticularAttendee(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: userTypes.ADMIN_GET_PARTICULAR_ATTENDEE_REQUEST, payload }
    }

    function success(payload) {
        return { type: userTypes.ADMIN_GET_PARTICULAR_ATTENDEE_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: userTypes.ADMIN_GET_PARTICULAR_ATTENDEE_ERROR, payload }
    }
}

//get all details
function getDashboardDetails(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getDashboardDetails(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: userTypes.ADMIN_GET_ALL_DETAILS_REQUEST, payload }
    }

    function success(payload) {
        return { type: userTypes.ADMIN_GET_ALL_DETAILS_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: userTypes.ADMIN_GET_ALL_DETAILS_ERROR, payload }
    }
}



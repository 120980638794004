import React, { useReducer } from 'react';
//link
import { Link } from 'react-router-dom';
//auth action
import { authActions } from "../../_action/Auth";
//react-redux action
import { connect } from "react-redux";
//svg icon
import { EyeIcon } from "../../SvgIcon";
//inline error
import InlineError from '../../components/common/InlineError';
//notification
import { notification } from 'antd'
import ResetSuccessMessage from './ResetSuccessMessage';


//reset password screen
function ResetPassword(props) {
	const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
		{
			password: '', confirmPassword: '', showPassword: '', showConfirmPassword: '',
			passwordError: '', confirmPasswordError: "", isResponse: false
		})

	//on chnage
	const handleOnChange = (e) => {
		setUserInput({ ...userInput, [e.target.name]: e.target.value, passwordError: '', confirmPasswordError: '' })
	}

	//show password
	const handleShowPassword = () => setUserInput({ showPassword: !userInput.showPassword })
	const handleShowConfirmPassword = () => setUserInput({ showConfirmPassword: !userInput.showConfirmPassword })
	console.log('token', props);
	//submit reset
	const handleResetPasswordSubmit = () => {
		console.log('passwprd', userInput.password)
		let { password, confirmPassword, isResponse } = userInput
		let resp = props.location.search;
		let token = resp.split("?token=");
		if (password === '' || password === undefined)
			setUserInput({ passwordError: '*Please enter password' })
		else if (password.length <= 4)
			setUserInput({ passwordError: '*Password must be greater than 4 digit.' })
		else if (confirmPassword === '')
			setUserInput({ confirmPasswordError: '*Please enter confirm password.' })
		else if (password !== confirmPassword)
			setUserInput({ confirmPasswordError: 'password and confirm password must be same.' })
		else {
			let req = {
				password: userInput.password,
				token: token[1],
			}

			let { dispatch } = props;
			setUserInput({ loading: true });
			dispatch(authActions.resetPassword(req)).then(data => {
				if (data.statusCode === 1) {
					console.log('msg', data.responseData);
					setUserInput({ loading: false, isResponse: true })
				}
				else {
					notification.error({ message: 'Error', description: data?.error?.responseMessage })
					setUserInput({
						loading: false, isResponse: false, 
					})
				}
			});
		}
	}

	let { password, confirmPassword, showPassword, showConfirmPassword, passwordError, confirmPasswordError, isResponse } = userInput
	return (
		<div className="base-color-option-2">
			<div classNameName="main-container">

				{
					isResponse === true ? <ResetSuccessMessage /> :
						<div className="container-fluid page-body-wrapper full-page-wrapper">
							<div className="user-login login-v2">
								<div className="row no-gutters">
									<div className="col-lg-12">
										<div className="logo-container text-center">
											<Link className="brand-logo login-page" to="/"> <img src={require('../../assets/images/mobile-logo.png')} alt="" title="" /></Link>
										</div>
									</div>
								</div>
								<div className="user-login-content">
									<div className="card">
										<div className="p-4">
											<h4 className="login-title mt-2 cm_bold">Reset Your Password</h4>
											<p className="text-muted mb-4">Please enter your new password.</p>
											<div className="form-group inner-addon">
												<span onClick={handleShowPassword} className={`eye_icon ${showPassword ? '' : 'slesh'}`}><EyeIcon /></span>
												<input type={`${showPassword ? 'text' : 'password'}`}
													className="form-control"
													autocomplete="off"
													id="exampleInputEmail11"
													aria-describedby="emailHelp"
													placeholder="New Password"
													maxLength="75"
													name="password"
													value={password}
													onChange={handleOnChange} />
												<InlineError
													message={passwordError}
												/>
											</div>
											<div className="form-group inner-addon">
												<span onClick={handleShowConfirmPassword} className={`eye_icon ${showConfirmPassword ? '' : 'slesh'}`}><EyeIcon /></span>
												<input type={`${showConfirmPassword ? 'text' : 'password'}`}
													className="form-control"
													autocomplete="off"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Confirm password"
													onChange={handleOnChange}
													value={confirmPassword}
													name="confirmPassword"
													maxLength="75"
												/>
												<InlineError
													message={confirmPasswordError}
												/>

											</div>

											<div className="text-center mb-3">
												<button type="submit" className="btn btn-primary btn-block mt-3 mb-5" onClick={handleResetPasswordSubmit}>SUBMIT</button>
											</div>
											<div className="text-center">
												<div className="text-muted  text-uppercase"><Link to="/" className="link">Back to sign in</Link></div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
				}
			</div>
		</div>

	);

}

function mapStateToProps(state) {
	const { props, resetPassword, user } = state;
	return {
		props,
		resetPassword,
		user
	}
}

export default connect(mapStateToProps)(ResetPassword);

// class ResetPassword extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			loader: false,

// 			email: '',
// 			password: '',
// 			confirmPassword: '',
// 			passwordError: '',
// 			confirmPasswordError: "",

// 			passwordErrorMsg: '',
// 			confirmPasswordErrorMsg: '',
// 			showConfirmPassword: false,
// 			showPassword: false,

// 			errors: '',
// 			redirect: false,
// 			showPassword: false,
// 			showConfirmPassword: false
// 		}
// 		this.redirectTo = this.redirectTo.bind(this)

// 	}

// 	componentDidMount() {
// 		let token = this.props.location.search;
// 		let resp = token.split("?token=");
// 		console.log("resp>>>>>", resp[1]);

// 	}

// 	redirectTo() {

// 		this.props.history.push({
// 			pathname: '/reset-successfully',
// 			state: { email: this.state.email }
// 		}
// 		);

// 	}


// 	handleChange = e => {
// 		let self = this;
// 		this.setState({
// 			[e.target.name]: e.target.value,passwordError:'',confirmPasswordError:'',
// 			[e.target.name + 'ErrorMsg']: '', errorMessage: '',
// 		}, () => this.setState({ errors: '' }))
// 	}


// 	handleValidation = () => {
// 		let validate = true;
// 		let { password, confirmPassword } = this.state;




// 		if (password === '' || password === undefined) {
// 			validate = false;
// 			this.setState({
// 				passwordErrorMsg: 'Please enter password.'
// 			})
// 		} else if (password.length <= 5) {
// 			validate = false;
// 			this.setState({
// 				passwordErrorMsg: ' Please enter minimum 6 characters.'
// 			})
// 		} else {
// 			this.setState({
// 				passwordErrorMsg: ''
// 			})
// 		}

// 		if (confirmPassword === '' || confirmPassword === undefined) {
// 			validate = false;
// 			this.setState({
// 				confirmPasswordErrorMsg: 'Please enter confirm password.'
// 			})
// 		} else if (confirmPassword !== password) {
// 			validate = false;
// 			this.setState({
// 				confirmPasswordErrorMsg: 'Password and confirm password does not match.'
// 			})
// 		} else {
// 			this.setState({
// 				confirmPasswordErrorMsg: ''
// 			})
// 		}


// 		return validate
// 	}

// 	handleSubmit = () => {
// 		// console.log('passwprd', resetPassData.password)

// 		let { password, confirmPassword, showPassword, showConfirmPassword } = this.state
// 		if (password == '')
// 			this.setState({ passwordError: '*Please enter password' })
// 		else if (password.length <= 4)
// 			this.setState({ passwordError: '*Password must be greater than 4 digit.' })
// 		else if (confirmPassword == '')
// 			this.setState({ confirmPasswordError: '*Please enter confirm password.' })
// 		else if (password != confirmPassword)
// 			this.setState({ confirmPasswordError: 'password and confirm password must be same.' })
// 		else {

// 		}
// 	}


// 	// handleSubmit = e => {
// 	// 	e.preventDefault();


// 	// 	let resp = this.props.location.search;
// 	// 	let token=resp.split("?token=");

// 	// 	if (this.handleValidation()) {
// 	// 		this.setState({ redirect: false, loader: true });

// 	// 		let obj = {
// 	// 			 'token': token[1],
// 	// 			'password':this.state.password
// 	// 		}

// 	// 		let { dispatch } = this.props;
// 	// 		dispatch(usersActions.reset(obj)).then(data => {

// 	// 			if (data.data.statusCode == 1) {
// 	// 				this.setState({ loader: false })
// 	// 				this.redirectTo();


// 	// 			} else {

// 	// 				this.setState({ loader: false })
// 	// 				this.setState({ errors: data.data.error.responseMessage,
// 	// 					errorCode: data.data.error.errorCode })
// 	// 			}
// 	// 		});
// 	// 	}
// 	// }
// 	handleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
// 	handleShowConfirmPassword = () => this.setState({ showConfirmPassword: !this.state.showConfirmPassword });

// 	render() {


// 		let { loader, errors, password, confirmPassword, passwordErrorMsg, confirmPasswordErrorMsg, confirmPasswordError, passwordError, showConfirmPassword,
// 			showPassword, redirect } = this.state;

// 		return (
// 			<div className="base-color-option-2">

// 				<div classNameName="main-container">
// 					<div className="container-fluid page-body-wrapper full-page-wrapper">
// 						<div className="user-login login-v2">
// 							<div className="row no-gutters">
// 								<div className="col-lg-12">
// 									<div className="logo-container text-center">
// 										<Link className="brand-logo login-page" to="/"> <img src={require('../../assets/images/mobile-logo.png')} alt="" title="" /></Link>
// 									</div>
// 								</div>
// 							</div>

// 							<div classNa="justify-content-center align-self-center">
// 								<div className="center">
// 									<div className="card-body card reset-card">
// 										<div className="user-login-content">
// 											<h4 className="login-title mt-2 cm_bold">Reset Your Password</h4>
// 											<p className="text-muted mb-4">Please enter your new password.</p>
// 											<div>
// 												<div className="form-group inner-addon">
// 													<span onClick={this.handleShowPassword} className={`eye_icon ${this.state.showPassword ? '' : 'slesh'}`}><EyeIcon /></span>
// 													<input type={`${showPassword ? 'text' : 'password'}`}
// 														className="form-control"
// 														autocomplete="off"
// 														id="exampleInputEmail1"
// 														aria-describedby="emailHelp"
// 														placeholder="New Password"
// 														maxLength="75"
// 														name="password"
// 														value={this.state.password}
// 														onChange={this.handleChange} />

// 													<InlineError message={passwordError} />
// 												</div>
// 												<div className="form-group inner-addon">
// 													<span onClick={this.handleShowConfirmPassword} className={`eye_icon ${this.state.showConfirmPassword ? '' : 'slesh'}`}><EyeIcon /></span>

// 													<input type={`${showConfirmPassword ? 'text' : 'password'}`}
// 														className="form-control"
// 														autocomplete="off"
// 														id="exampleInputEmail1"
// 														aria-describedby="emailHelp"
// 														placeholder="Confirm password"
// 														onChange={this.handleChange}
// 														value={this.state.confirmPassword}
// 														name="confirmPassword"
// 														maxLength="75"
// 													/>
// 													<InlineError message={confirmPasswordError} />
// 												</div>

// 												<div className="text-center mb-3">
// 													<button type="button" className="btn btn-primary btn-block mt-3 mb-5" onClick={this.handleSubmit}>SUBMIT</button>
// 												</div>
// 												<div className="text-center">
// 													<div className="text-muted  text-uppercase"><Link to="/" className="link">Back to sign in</Link></div>

// 												</div>
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 							</div>

// 						</div>
// 					</div>
// 				</div>
// 			</div>

// 		);
// 	}
// }


// function mapStateToProps(state) {
// 	const { props, login, user } = state;
// 	return {
// 		props,
// 		login,
// 		user
// 	}
// }

// export default connect(mapStateToProps)(ResetPassword);

/**
 * @About
 * This file contains alrt message.
 */

import React from "react"
import { Alert } from "react-bootstrap"


// user for alert
export const MyAlert = (props) => {
    return (
        <Alert variant={props.type ? 'green justify-content-center' : 'red justify-content-center'}>
            {props.message}
        </Alert>
    )
}








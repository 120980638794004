import React, { useEffect, useReducer } from "react";
//header
import TopBar from "../../components/template/TopBar";
//side navbar
import SideNav from "../../components/template/SideNav";
////event action
import { eventActions } from "../../_action/EventManage";
//antd
import { notification, Button } from "antd";
//react-redux connect
import { connect } from "react-redux";
//loader
import { Loader } from "../../components/common/Loader";
//card
import { Card } from "react-bootstrap";

//event details screens
var parse = require("html-react-parser");

function EventDetails(props) {
  const [eventData, setEventData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoader: "false",
      eventName: "",
      eventDate: "",
      timeZone:'',
      startTime: "",
      endTime: "",
      tagLine: "",
      aboutTheEvent: "",
      status: "",
      newsFeedDetails: [],
      promoterName: "",
      promoterImage: "",
      promoterEmail: "",
      tagline: "",
      address2: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      aboutPromoter: "",
      eventAfterImage:[]
    }
  );

  useEffect(() => {
    eventDetails();
    getNewsFeed();
  }, []);

  //get event details
  const eventDetails = () => {
    try {
      let obj = {
        eventId: props.match.params.id,
      };
      setEventData({ isLoader: true });
      let { dispatch } = props;
      dispatch(eventActions.viewEventDetails(obj)).then((data) => {
        if (data.statusCode === 1) {
          console.log("address", data?.responseData?.result);
          let params = data?.responseData?.result;
          setEventData({
            eventName: params?.eventName,
            tagLine: params?.tagline,
            startTime: params.startTime,
            endTime: params.endTime,
            aboutPromoter: params?.aboutPromoter,
            eventDate: params.eventDate,
            timeZone: params.timeZone,
            aboutTheEvent: params.aboutTheEvent,
            eventImage: params.eventImage,
            promoterName: params.promoterName,
            promoterEmail: params.promoterEmail,
            promoterImage: params.promoterImage,
            ticketMin: params.ticketMin,
            ticketMax: params.ticketMax,
            tagline: params.tagline,
            address2: params.address2,
            address: params?.latlng?.name,
            city: params.city,
            state: params.state,
            zip: params.zip,
            isLoader: false,
            eventAfterImage:params.eventAfterImage
          });
        } else {
          notification.error({
            message: "Error",
            description: data?.error?.responseMessage,
          });
          setEventData({ isLoader: false });
        }
      });
    } catch {}
  };

  //get newsfedd
  const getNewsFeed = () => {
    try {
      // let obj = {
      //     eventId: props.match.params.id
      // }
      let obj = `eventId=${props.match.params.id}`;
      setEventData({ isLoader: true });
      let { dispatch } = props;
      dispatch(eventActions.getNewsFeed(obj)).then((data) => {
        if (data.statusCode === 1) {
          // let params = data?.responseData?.result[0]
          setEventData({
            newsFeedDetails: data?.responseData?.result,
            isLoader: false,
          });
        } else {
          notification.error({
            message: "Error",
            description: data?.error?.responseMessage,
          });
          setEventData({ isLoader: false });
        }
      });
    } catch {}
  };

  let {
    isLoader,
    eventName,
    startTime,
    endTime,
    eventDate,
    timeZone,
    aboutTheEvent,
    eventImage,
    newsFeedDetails,
    promoterName,
    promoterEmail,
    promoterImage,
    address,
    address2,
    tagline,
    aboutPromoter,
    city,
    state,
    zip,
    eventAfterImage
  } = eventData;

  return (
    <div className="dark-sidebar">
      <div className="main-container">
        <div className="container-fluid page-body-wrapper">
          {isLoader && <Loader />}
          <nav className="navbar fixed-top">
            <div className="navbar-menu-container d-flex align-items-center justify-content-center">
              <div className="sub-heading">
                <h4 className="page-title ">Event Details</h4>
              </div>
              <div className="text-center navbar-brand-container align-items-center justify-content-center">
                <a className="brand-logo" href="/">
                  {" "}
                  <img
                    src={require("../../assets/images/mobile-logo.png")}
                    alt="Event 3"
                    className="mb-3"
                  />
                </a>
              </div>
              <TopBar {...props} />
            </div>
          </nav>
          <SideNav />
          <div className="main-panel">
            <div className="content-wrapper manage-event">
              <div className="row card-margin">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">
                        Event
                        <small className="card-title-secondary">
                          See your event detail below
                        </small>
                      </h5>
                    </div>

                    <div className="card-body">
                      <div
                        className="tab-pane fade show active"
                        id="details"
                        role="tabpanel"
                        aria-labelledby="details"
                      >
                        <div class="forms-sample">
                          <div className="row">
                            <div className="col-md-6">
                              <div class="form-group">
                                <label for="exampleInputUsername12">
                                  Event Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="event-name"
                                  disabled
                                  value={eventName}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="form-group m-b-40">
                                <label for="exampleInputUsername12">
                                  {" "}
                                  Event Date
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={eventDate}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div class="form-group">
                                <label for="exampleInputUsername12">
                                  Event Start Time
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={startTime}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div class="form-group m-b-40">
                                <label for="exampleInputUsername12">
                                  Event End Time
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={endTime}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div class="form-group">
                                <label for="exampleInputUsername12">
                                  Event Time Zone
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={timeZone}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div class="form-group">
                                <label for="exampleInputUsername12">
                                  Promoter Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={promoterName}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="form-group m-b-40">
                                <label for="exampleInputUsername12">
                                  Promoter Email
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={promoterEmail}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div class="form-group">
                                <label for="exampleInputUsername12">
                                  Tag Line
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={tagline}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="form-group m-b-40">
                                <label for="exampleInputUsername12">
                                  Address 1
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={address}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div class="form-group">
                                <label for="exampleInputUsername12">
                                  Address 2
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={address2}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="form-group m-b-40">
                                <label for="exampleInputUsername12">City</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={city}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div class="form-group">
                                <label for="exampleInputUsername12">
                                  State
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={state}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="form-group m-b-40">
                                <label for="exampleInputUsername12">
                                  Zip code
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={zip}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div class="form-group m-t-40">
                                <label for="exampleTextarea">
                                  About the Event
                                </label>
                                <textarea
                                  class="form-control"
                                  name="aboutTheEvent"
                                  disabled
                                  rows="6"
                                  value={aboutTheEvent}
                                ></textarea>
                              </div>
                            </div>
                            {console.log("aboutPromoter", parse(aboutPromoter))}
                            <div className="col-md-6">
                              <div class="form-group m-t-40">
                                <label for="exampleTextarea">
                                  About the Promoter
                                </label>

                                <p className="text-wrap text-break page-msg">
                                  {aboutPromoter
                                    ? parse(aboutPromoter)
                                    : "No data present"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div class="form-group">
                                <label for="exampleInputUsername12">
                                  Event Image
                                </label>
                                <div className="col-md-8">
                                  <b></b>{" "}
                                  <Card
                                    className="m-3"
                                    style={{ width: "18rem" }}
                                  >
                                    <Card.Img variant="top" src={eventImage} />
                                  </Card>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="form-group m-b-40">
                                <label for="exampleInputUsername12">
                                  Promoter Image
                                </label>
                                <div className="col-md-8">
                                  <b></b>{" "}
                                  <Card
                                    className="m-3"
                                    style={{ width: "18rem" }}
                                  >
                                    <Card.Img
                                      variant="top"
                                      src={promoterImage}
                                    />
                                  </Card>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml-5">
                        <br />
                        {newsFeedDetails.length <= 0 ? (
                          ""
                        ) : (
                          <div>
                            <h2>
                              <b>NewsFeed</b>
                            </h2>
                          </div>
                        )}

                        <div className="row">
                          {newsFeedDetails?.map((item, i) => {
                            return item.newsfeedImage.map((val, ind) => {
                              return (
                                <Card
                                  className="m-3"
                                  style={{ width: "18rem" }}
                                >
                                  <Card.Img variant="top" src={val} />
                                </Card>
                              );
                            });
                          })}
                        </div>
                      </div>
                      {
                          <div className="ml-5">
                          <br />
                          {eventAfterImage.length <= 0 ? (
                            ""
                          ) : (
                            <div>
                              <h2>
                                <b>NewsFeed</b>
                              </h2>
                            </div>
                          )}
  
                          <div className="row">
                            {
                               eventAfterImage.map((val, ind) => {
                                return (
                                  <Card
                                    className="m-3"
                                    style={{ width: "18rem" }}
                                  >
                                    <Card.Img variant="top" src={val} />
                                  </Card>
                                );
                              })
                            }
                          </div>
                        </div>
                      }
                      <div>
                        {" "}
                        <br />
                        <Button
                          className="status_button float-right"
                          type="primary"
                          danger
                          onClick={() => props.history.goBack()}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { props, viewEventDetails, getNewsFeed, user } = state;
  return {
    props,
    viewEventDetails,
    getNewsFeed,
    user,
  };
}
export default connect(mapStateToProps)(EventDetails);

import React, { useReducer, useEffect } from 'react';
//header
import TopBar from '../../components/template/TopBar';
//footer
import Footer from '../../components/template/Footer';
//side navbar
import SideNav from '../../components/template/SideNav';
//action
import { userActions } from '../../_action/UserManage'
//notification
import { notification } from 'antd'
//loader
import { Loader } from '../..//components/common/Loader';
//connect
import { connect } from 'react-redux';


//dashboard screen
function Dashboard(props) {
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            isLoader: false
        })

    const getAllData = () => {
        let { dispatch } = props;
        setUserInput({ isLoader: true })
        dispatch(userActions.getDashboardDetails()).then(data => {
            if (data?.statusCode == 1) {
                let taotalCount = data.responseData;
                setUserInput({ isLoader: false, totalEvent: taotalCount.totalEvent, totalOrder: taotalCount.totalOrder, totalUser: taotalCount.totalUser })

            }
            else {
                notification.error({ message: 'Error', description: data?.error?.responseMessage })
                setUserInput({ isLoader: false })

            }
        });
    }

    useEffect(() => {
        getAllData()
    }, [])

    let { isLoader, totalOrder, totalUser, totalEvent } = userInput;
    return (
        <div>
            <div className="dark-sidebar">
                <div className="main-container">
                    {isLoader && <Loader />}
                    <div className="container-fluid page-body-wrapper">
                        <nav className="navbar fixed-top"><div className="navbar-menu-container d-flex align-items-center justify-content-center">
                            <div className="sub-heading">
                                <h4 className="page-title ">DASHBOARD</h4>
                            </div>
                            <div className="text-center navbar-brand-container align-items-center justify-content-center">

                                <a className="brand-logo" href="/">	<img src={require('../../assets/images/mobile-logo.png')} alt="Event 3" className="mb-3" />
                                </a>
                            </div>
                            <TopBar
                                {...props}
                            />
                        </div>
                        </nav>
                        <SideNav />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <h5 className="pr-3 m-t-20 cm_bold  mange-evnt">QUICK STATS</h5>
                                <div className="row m-b-40">
                                    <div className="col-lg-4 card-margin" onClick={() => props.history.push("/event-list")}>
                                        <div className="card ">
                                            <div className="card-body p-0">
                                                <div className="widget-20">
                                                    <div className="widget-20-header">
                                                        <div className="widget-20-content">
                                                            <h5 className="widget-20-title cm_bold">Total Events</h5>
                                                            {/**
                                                                <span className="widget-20-info">Per Event</span>
                                                             */}
                                                        </div>
                                                        <h3 className="widget-20-figure">{totalEvent}</h3>
                                                    </div>
                                                    <div className="widget-20-chart">
                                                        <img src={require('../../assets/images/red-graph.png')} className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 card-margin">
                                        <div className="card ">
                                            <div className="card-body p-0">
                                                <div className="widget-20">
                                                    <div className="widget-20-header">
                                                        <div className="widget-20-content">
                                                            <h5 className="widget-20-title cm_bold">Total Booking</h5>
                                                        </div>
                                                        <h3 className="widget-20-figure">{totalOrder}</h3>
                                                    </div>
                                                    <div className="widget-20-chart">
                                                        <img src={require('../../assets/images/green-bar-graph.png')} className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 card-margin" onClick={() => props.history.push("/manage-user")}>
                                        <div className="card ">
                                            <div className="card-body p-0">
                                                <div className="widget-20">
                                                    <div className="widget-20-header">
                                                        <div className="widget-20-content">
                                                            <h5 className="widget-20-title cm_bold">Total Users</h5>
                                                        </div>
                                                        <h3 className="widget-20-figure">{totalUser}</h3>
                                                    </div>
                                                    <div className="widget-20-chart">
                                                        <img src={require('../../assets/images/black-graph.jpg')} className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { props, getDashboardDetails, user } = state;
    return {
        props,
        getDashboardDetails,
        user
    }
}
export default connect(mapStateToProps)(Dashboard)
/**
 * @About
 * This file contains header.
 */
import React from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { authActions } from '../../_action/Auth'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { MySpinner } from '../../components/common/Loader'
import { notification } from 'antd';

//Header
class TopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            openLogoutPopup: false,
            logoutdeletePopup: false,
            loading: false
        }
    }

    //open logout popup
    handleOpenLogoutPopup = () => this.setState({ openLogoutPopup: !this.state.openLogoutPopup });

    //close logout modal
    closeModal = () => this.setState({
        openLogoutPopup: false
    });

    //logout
    onLogoutSubmit = async () => {
        this.setState({ loading: false })
        let req = {
            accessToken: localStorage.getItem('accessToken')
        }
        this.setState({ loading: true })
        let { dispatch } = this.props;
        dispatch(authActions.logout(req)).then(data => {
            if (data.statusCode === 1) {
                localStorage.clear()
               window.location.href='/'
                this.closeModal()
                this.setState({ loading: false })
            } else {
                notification.error({ message: 'Error', description: data?.error?.responseMessage })
                this.setState({ loading: false })
            }
        })
    }

    toggleMenuClass = () => document.body.classList.toggle('menu_open')

    render() {
        let { openLogoutPopup, loading } = this.state;
        return (

            <div className="navbar-nav navbar-nav-right">
                <div className="mobile_trigger">  <li className="nav-item mobile-sidebar"><button className="nav-link navbar-toggler navbar-toggler-right align-self-center" data-toggle="lgy-sidebar" type="button"><i className="fa fa-align-right" aria-hidden="true"></i><span onClick={this.toggleMenuClass} /></button></li></div>
                <Dropdown className="nav-item nav-profile dropdown">
                    <Dropdown.Toggle className="nav-link dropdown-toggle" >
                        <li className="nav-item"><button className="nav-link navbar-toggler navbar-toggler-right align-self-center" data-toggle="lgy-sidebar" type="button"><FontAwesomeIcon className="far fa-user-circle fa-2x" icon={faUserCircle} /></button></li>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu nav-user-container animated">
                        <li className="dropdown-item">   <Dropdown.Item className="dropdown-link"> <Link to="/change-password" className="link-home">Change Password</Link></Dropdown.Item></li>
                        <li className="dropdown-item">  <Dropdown.Item className="dropdown-link" onClick={this.handleOpenLogoutPopup}>Sign Out</Dropdown.Item></li>
                    </Dropdown.Menu>
                </Dropdown>
                <Modal
                    size="md"
                    show={openLogoutPopup}
                    onHide={this.closeModal}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <div className="cm_modal">
                        <div className="modal-body">
                            <h4 className="mb-4"><b>Are you sure, you want to Sign out?</b></h4>
                            <br />
                            <div className="bg-white pull-right mb-4">
                                <button className="btn btn-light" onClick={this.closeModal}>No</button>
                                <button type="submit" className="btn btn-primary ml-2" onClick={this.onLogoutSubmit}>Yes
                                &nbsp;
                                {
                                        loading && <MySpinner />
                                    }
                                </button>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { props, user } = state;
    return {
        props,
        user
    }
}
export default connect(mapStateToProps)(TopBar);
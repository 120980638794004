/**
 * @About
 * This file consist all action of category management
 */

import promise from 'bluebird'
import { usersService } from "../services/ApiServices";
import { ResponseFilter } from '../_helper/ResponseHandler'
import { notification } from 'antd'

export const adminTypes = {

    //get category
    ADMIN_GET_CATEGORY_REQUEST: 'ADMIN_GET_CATEGORY_REQUEST',
    ADMIN_GET_CATEGORY_SUCCESS: 'ADMIN_GET_CATEGORY_SUCCESS',
    ADMIN_GET_CATEGORY_ERROR: 'ADMIN_GET_CATEGORY_ERROR',

    //delete category
    ADMIN_DELETE_CATEGORY_REQUEST: 'ADMIN_DELETE_CATEGORY_REQUEST',
    ADMIN_DELETE_CATEGORY_SUCCESS: 'ADMIN_DELETE_CATEGORY_SUCCESS',
    ADMIN_DELETE_CATEGORY_ERROR: 'ADMIN_DELETE_CATEGORY_ERROR',

    //edit category
    ADMIN_EDIT_CATEGORY_REQUEST: 'ADMIN_EDIT_CATEGORY_REQUEST',
    ADMIN_EDIT_CATEGORY_SUCCESS: 'ADMIN_EDIT_CATEGORY_SUCCESS',
    ADMIN_EDIT_CATEGORY_ERROR: 'ADMIN_EDIT_CATEGORY_ERROR',

    //edit category
    ADMIN_ADD_CATEGORY_REQUEST: 'ADMIN_ADD_CATEGORY_REQUEST',
    ADMIN_ADD_CATEGORY_SUCCESS: 'ADMIN_ADD_CATEGORY_SUCCESS',
    ADMIN_ADD_CATEGORY_ERROR: 'ADMIN_ADD_CATEGORY_ERROR',

};

export const adminActions = {
    getCategory,
    deleteCategory,
    editCategory,
    addCategory
};

//get category
function getCategory(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getCategory(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: adminTypes.ADMIN_GET_CATEGORY_REQUEST, payload }
    }

    function success(payload) {
        return { type: adminTypes.ADMIN_GET_CATEGORY_SUCCESS, payload }
    }

    function failure(payload) {
        notification.error({ message: 'Error', description: payload.Error })
        return { type: adminTypes.ADMIN_GET_CATEGORY_ERROR, payload }
    }
}

//delete category
function deleteCategory(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.deleteCategory(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: adminTypes.ADMIN_DELETE_CATEGORY_REQUEST, payload }
    }

    function success(payload) {
        return { type: adminTypes.ADMIN_DELETE_CATEGORY_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: adminTypes.ADMIN_DELETE_CATEGORY_ERROR, payload }
    }
}

//edit category
function editCategory(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.editCategory(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: adminTypes.ADMIN_EDIT_CATEGORY_REQUEST, payload }
    }

    function success(payload) {
        return { type: adminTypes.ADMIN_EDIT_CATEGORY_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: adminTypes.ADMIN_EDIT_CATEGORY_ERROR, payload }
    }
}

//add category
function addCategory(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.addCategory(params)
                .then(response => {
                    let { serverResponseData } = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(payload) {
        return { type: adminTypes.ADMIN_ADD_CATEGORY_REQUEST, payload }
    }

    function success(payload) {
        return { type: adminTypes.ADMIN_ADD_CATEGORY_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: adminTypes.ADMIN_ADD_CATEGORY_ERROR, payload }
    }
}


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './Routes';
import { Provider } from 'react-redux';
import { store } from './_store/Store';
import ErrorBoundary from './_helper/ErrorBoundry';



if (navigator.onLine) {
  ReactDOM.render(
    <Provider store={store}>
      <ErrorBoundary>
      <Routes />
      </ErrorBoundary>
    </Provider>,

    document.getElementById('root')
  );

} else {
  alert('Oops we hane no internet please check your internet connetion.')
}

/**
 * @About
 * All screens routing
 */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
//axios
import axios from 'axios'
//history
import { createBrowserHistory } from 'history';
//react-router-dom
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
//login screen
import Login from './screens/auth/Login';
//forgot password screen
import ForgotPassword from './screens/auth/ForgotPassword';
//reset password screen
import ResetPassword from './screens/auth/ResetPassword';
//dashboard
import Dashboard from './screens/dashboard/Dashboard';
//event list
import EventList from './screens/eventManagemnt/EventList';
//change password
import ChangePassword from './screens/auth/ChangePassword';
//user list screen
import ManageUser from './screens/userManagement/ManageUser';
//category list screen
import ManageCategory from './screens/categoryManagement/ManageCategory';
//event details screen
import EventDetail from './screens/eventManagemnt/eventDetails';
//event details screen
import EventEdit from './screens/eventManagemnt/EventEdit';
//404 sscreen
import Page from './components/common/PageBotFound';
//add user
import AddAttandee from './screens/userManagement/AddAttandee'
//side navbar
import SideNav from './components/template/SideNav';
//header
import TopBar from './components/template/TopBar';
//footer
import Footer from './components/template/Footer'



export const history = createBrowserHistory();

// Private Routes
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    localStorage.getItem('accessToken')
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  )} />
)

// public Route
const PrivateOuterRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    localStorage.getItem('accessToken')
      ? <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
      : <Component {...props} />
  )} />
)
//redirct to other screen go to top of new screen
history.listen(({ pathname }) => {
  window.scrollTo(0, 0)
})

export default function Routes() {
  // axios.defaults.headers.common['Authorization'] = `${'Basic ZGVtbzpkZW1v'}`;
  axios.defaults.headers.common['AccessToken'] = localStorage.getItem('accessToken');
  return (
    <>
      <Router history={history}>

        {/*
          {
          localStorage.getItem('accessToken') && localStorage.getItem('isLoggedIn') ?
            <>
              <SideNav />
              <Footer />
              <TopBar />

            </> : ''
        }
     */}

        <Switch>
          <PrivateOuterRoute exact path='/' component={Login} />
          <PrivateOuterRoute path='/forgot-password' component={ForgotPassword} />
          <PrivateOuterRoute path='/reset-password' component={ResetPassword} />

          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/event-list" component={EventList} />
          <PrivateRoute path="/change-password" component={ChangePassword} />
          <PrivateRoute path="/manage-user" component={ManageUser} />
          <PrivateRoute path="/manage-category" component={ManageCategory} />
          <PrivateRoute path="/event-details/:id" component={EventDetail} />
          <PrivateRoute path="/event-edit/:id" component={EventEdit} />
          <PrivateRoute path="/add-user" component={AddAttandee} />
          <PrivateRoute path="/edit-user/:id" component={AddAttandee} />

        
          <Route path='/404' component={Page} />
          <Redirect from='*' to='/404' />
        

        </Switch>
      </Router>

    </>
  );

}




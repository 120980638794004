/**
 * @About
 * This file contains page not found.
 */
import React from 'react'
import { Link } from 'react-router-dom'

//404 page
export default function Page() { 
    return (
        <>
            <div className="login_wrapper d-flex align-items-center">
                <div className="container cm_container bg_white d-flex align-items-center">
                    <div className="page_content text-center">
                        <h2><b>Oops! Page not found</b></h2>
                        <h4 className="cm_heading mb-4 text-danger">404</h4>
                        <h4><b>we are sorry, but the page you requested was not found</b>
                        </h4><br />
                        <div >
                            <Link to="/" class="btn btn-back btn-outline-brand border-brand font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand">
                                <i class="fas fa-arrow-left mr-2"></i>
                                 Back to Homepage
                            </Link>
                        </div>
                    </div>
                </div></div>
        </>

    );

}
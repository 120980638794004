import React from 'react';

//link
import { Link } from 'react-router-dom';

//reset password success message screen
export default function ResetSuccessMessage() {
    return (
        <div className="base-color-option-2">
            <div classNameName="main-container">
            
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="user-login login-v2">
                        <div className="row no-gutters">
                            <div className="col-lg-12">
                                <div className="logo-container text-center">
                                    <Link className="brand-logo login-page" to="/"> <img src={require('../../assets/images/mobile-logo.png')} alt="" title="" /></Link>
                                </div>
                            </div>
                        </div>
                        <div classNa="justify-content-center align-self-center">
                            <div className="center">
                                <div className="card-body card">
                                    <div className="">
                                        <div className="user-login-content">
                                            <h4 className="login-title mt-2 cm_bold">SUCCESS!</h4>
                                            <p className="text-muted mb-4 text-center">Your password has been reset successfully.</p>
                                            <div className="cm_check"></div>
                                            <form className="needs-validation" novalidate>
                                                <div className="text-center mb-3">
                                                    <div className="text-muted  text-uppercase"><Link to="/" className="link">Back to sign in</Link></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
}
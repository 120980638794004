/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
//link
import { Link } from 'react-router-dom';
//auth action
import { authActions } from "../../_action/Auth";
//svg icon
import { EyeIcon } from "../../SvgIcon";
//react-redux connect
import { connect } from "react-redux";
//validaion
import { validateEmail } from '../../components/common/Validation';
//inline error
import InlineError from '../../components/common/InlineError';
//alert
import { MyAlert } from '../../components/common/MyAlert'
//loader
import { MySpinner } from '../../components/common/Loader';


//login screen
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            userType: '',
            emailError: '',
            passwordError: '',
            userTypeError: '',
            isLoader: true,
            showPassword: false,
            redirect: false,
            isLoading: false,
            erroStatus: false,
            message: '',
            showMessage: false,
            mySpinner: false
        }
    }

    //Show password 
    handleShowPassword = () => this.setState({ showPassword: !this.state.showPassword })

    //on change
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value, emailError: '', passwordError: ''
        })
    }

    //login submit
    handleSubmit = e => {
        let { email, password } = this.state;
        e.preventDefault();
        try {
            if (email === '')
                this.setState({ emailError: '*Please enter email.' })
            else if (!validateEmail(email).status) {
                this.setState({ emailError: "*Please enter valid email." })
            }
            else if (password === '')
                this.setState({ passwordError: '*Please enter password.' })
            else if (password.length <= 4)
                this.setState({ passwordError: '*Password must be greater than 4 character.' })
            else {
                (this.setState({ isLoader: true, mySpinner: true, erroStatus: false, showMessage: false }))
                let obj = {
                    'email': email.toLowerCase().trim(),
                    'password': password
                }
                let { dispatch } = this.props;
                dispatch(authActions.login(obj)).then(data => {
                    if (data?.statusCode === 1) {
                        this.setState({ mySpinner: false })
                        this.props.history.push('/dashboard')
                    }
                    else {
                        this.setState({
                            mySpinner: false, erroStatus: false, message: data?.error?.responseMessage, showMessage: true
                        })
                    }
                }
                );
            }
        }
        catch{ }
    }

    render() {
        let { mySpinner, emailError, passwordError, showPassword } = this.state;
        return (
            <div className="base-color-option-2">
                <div className="main-container">
                    <div className="container-fluid page-body-wrapper full-page-wrapper">
                        <div className="user-login login-v2">
                            <div className="row no-gutters">
                                <div className="col-lg-12">
                                    <div className="logo-container text-center">
                                        <Link className="brand-logo login-page" to="/"> <img src={require('../../assets/images/mobile-logo.png')} alt="" title="" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="user-login-content">
                                {
                                    this.state.showMessage &&
                                    <MyAlert
                                        message={this.state.message}
                                        type={this.state.erroStatus}
                                    />
                                }
                                <div className="card">
                                    <div className="p-4">
                                        <h4 className="login-title cm_bold">Admin Login</h4>
                                        <form className="needs-validation" onSubmit={this.handleSubmit}>
                                            <div className="form-group inner-addon">
                                                <input type="text"
                                                    autocomplete="off"
                                                    className="form-control"
                                                    maxLength="75"
                                                    name="email"
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                    placeholder="Email Address"
                                                />
                                                <InlineError
                                                    message={emailError}
                                                />
                                            </div>
                                            <div className="form-group inner-addon">
                                                <span onClick={this.handleShowPassword} className={`eye_icon ${this.state.showPassword ? '' : 'slesh'}`}><EyeIcon /></span>
                                                <input type={`${showPassword ? 'text' : 'password'}`}
                                                    name="password"
                                                    maxLength="75"
                                                    autocomplete="off"
                                                    value={this.state.password}
                                                    className="form-control"
                                                    id="exampleInputPassword1"
                                                    onChange={this.handleChange}
                                                    placeholder="Password" />
                                                <InlineError
                                                    message={passwordError}
                                                />
                                            </div>
                                            <div className="text-center mb-3">
                                                <button type="submit" className="btn btn-primary btn-block" disabled={mySpinner}>SIGN IN
                                                &nbsp;
                                                {this.state.mySpinner &&
                                                        <MySpinner
                                                        />}
                                                </button>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-right">
                                                    <div className="text-muted  text-uppercase"><Link to="/forgot-password" className="link float-left">Forgot Password?</Link></div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { props, login, user } = state;
    return {
        props,
        login,
        user
    }
}
export default connect(mapStateToProps)(Login);
import React from 'react';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import _, { lowerFirst, toUpper } from 'lodash';
import 'react-image-crop/dist/ReactCrop.css';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import { usersService } from '../../../services/ApiServices';
import 'react-day-picker/lib/style.css';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { eventActions } from '../../../_action/EventManage';

import { Loader } from '../../../components/common/Loader';
import { Uploade, Delete } from '../../../SvgIcon';
import { DatePicker, Popover, message, Select, notification } from 'antd';
import Dropzone from 'react-dropzone';
import { ResponseFilter } from '../../../_helper/ResponseHandler';

var newaddress = '';

const validateNumber = () => {
  let numberRegex = /^[0-9]+\.?[0-9]*$/;
};

const dateFormat = 'MM-DD-YYYY';

class EditDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      startTimeErrorMsg: '',
      endTimeErrorMsg: '',
      imageErrorMsg: '',
      passwordErrorMsg: '',
      disabledBtn: false,
      name: '',
      eventListDetails: [],
      tagline: '',
      eventDate: new Date(),
      startTime: '',
      endTime: '',
      address: '',
      zip: '',
      about: '',
      eventImage: '',
      categories: [],
      categoryId: '',
      type: 0,
      imagePreview: '',
      checked: false,
      passEvent: 0,
      password: '',
      eventType: 0,
      publicEventCheck: false,
      noChecK: true,
      privateEventCheck: false,
      privateEventProtectedCheck: false,
      eventId: '',
      ticketInput: [],
      src: null,
      crop: {
        unit: '%',
        width: 90,
        aspect: 2.62,
      },
      croppedImageUrl: '',
      imageResize: '',
      imageUpload: '',
      lat: '',
      lng: '',
      loader: false,
      city: '',
      eventPlatform: 1,
      street: '',
      state: '',
      address2: '',
      hour: 8,
      minute: '00',
      meridian: 'AM',
      endhour: 12,
      endminute: '00',
      endmeridian: 'PM',
      hourEnd: 8,
      minuteEnd: '00',
      meridianEnd: 'PM',
      endhourEnd: 12,
      endminuteEnd: '00',
      endmeridianEnd: 'AM',
      noChecKEnd: true,
      limit: 5,
      sortOrder: 'desc',
      sortBy: 'created',
      page: 1,
      openEditPopup: false,
      openAddPopup: false,
      users: [{ ticketType: '', ticketName: '', quantity: '', price: '' }],
      ticketsList: [],
      openAddPaidTicketPopup: false,
      openEditTicketPopup: false,
      openAddFreeTicketPopup: false,
      ticketNameErrorMsg: '',
      quantityErrorMsg: '',
      priceErrorMsg: '',
      openDeleteTicketPopup: false,
      nameErrorMsg: '',
      eventImageErrorMsg: '',
      promoterEmail: '',
      aboutPromoter: '',
      aboutTheEventErrorMsg: '',
      eventDateErrorMsg: '',
      promoterName: '',
      addressErrorMsg: '',
      zipErrorMsg: '',
      stateErrorMsg: '',
      cityErrorMsg: '',
      isLoader: '',
      perTicketFees: '',
      ccFees: '',
      transactionFees: '',
      ccFeesError: '',
      DateArr: [],
      selectedDays: [],
      DateArrShow: [],
      startDate: '',
      endDate: '',
      startDateCheck: '',
      aboutTheEvent: '',
      aboutTheEventValid: '',
      multiDaysArr: [
        {
          date: '',
          startTime: '',
          eventEndDate: '',
          endTime: '',
        },
      ],

      fieldNameErrorMsg: false,
      showErrorEndTimeDiff: false,
      qniqueErrorMsg: false,
      fieldNameErrorMsgIndex: '',
      showErrorEndTime: false,
      showErrorStartTime: false,
      showErrorDate: false,
      multiDays: 1,
    };
    this.updateCheckBox = this.updateCheckBox.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }
  startTimeChange = (i, time) => {
    let { multiDaysArr } = this.state;
    multiDaysArr[i].startTime = moment(time, 'h mm A').format('h mm A');
    if (multiDaysArr[i].endTime !== '' && multiDaysArr[i].date !== '') {
      if (
        moment(multiDaysArr[i].startTime, 'HH:mm A') >
        moment(multiDaysArr[i].endTime, 'HH:mm A')
      ) {
        multiDaysArr[i].eventEndDate = moment(multiDaysArr[i].date)
          .add(1, 'days')
          .format('YYYY-MM-DD');
      } else {
        multiDaysArr[i].eventEndDate = moment(multiDaysArr[i].date).format(
          'YYYY-MM-DD'
        );
      }
    }
    this.setState({
      multiDaysArr,
      showErrorStartTime: false,
    });
  };
  endTimeChange = (i, time) => {
    let { multiDaysArr } = this.state;
    multiDaysArr[i].endTime = moment(time, 'h mm A').format('h mm A');
    if (multiDaysArr[i].startTime !== '' && multiDaysArr[i].date !== '') {
      if (
        moment(multiDaysArr[i].startTime, 'HH:mm A') >
        moment(multiDaysArr[i].endTime, 'HH:mm A')
      ) {
        multiDaysArr[i].eventEndDate = moment(multiDaysArr[i].date)
          .add(1, 'days')
          .format('YYYY-MM-DD');
      } else {
        multiDaysArr[i].eventEndDate = moment(multiDaysArr[i].date).format(
          'YYYY-MM-DD'
        );
      }
    }

    this.setState({
      multiDaysArr,
      showErrorEndTime: false,
      showErrorEndTimeDiff: false,
    });
  };

  checkDiff = (start, end) => {
    var startTimeDiff = moment(start, 'HH:mm:ss a');
    var endTimeDiff = moment(end, 'HH:mm:ss a');
    var duration = moment.duration(endTimeDiff.diff(startTimeDiff));
    var minutesDiff = parseInt(duration.asMinutes());
    return minutesDiff;
  };

  validateMultiDateArr = () => {
    let validate = true;
    var current = this.getTime();
    var currentTime = moment(current, 'HH:mm A');
    var currentDate = new Date();
    let { multiDaysArr } = this.state;

    var unique = [...new Set(multiDaysArr.map((a) => a.date))];
    if (unique.length !== multiDaysArr.length) {
      validate = false;
      this.setState({
        qniqueErrorMsg: true,
      });
    }

    multiDaysArr.map((item, index) => {
      var startTimeDiff = moment(multiDaysArr[index].startTime, 'HH:mm:ss a');
      var endTimeDiff = moment(multiDaysArr[index].endTime, 'HH:mm:ss a');
      var duration = moment.duration(endTimeDiff.diff(startTimeDiff));
      var minutesDiff = parseInt(duration.asMinutes());

      if (multiDaysArr[index].date === '') {
        validate = false;
        this.setState({
          showErrorDate: true,
        });
      }
      if (multiDaysArr[index].endTime === '') {
        validate = false;
        this.setState({
          showErrorEndTime: true,
        });
      }
      if (multiDaysArr[index].startTime === '') {
        validate = false;
        this.setState({
          showErrorStartTime: true,
        });
      } else if (
        moment(multiDaysArr[index].date).format('YYYY-MM-DD') ==
        moment(currentDate).format('YYYY-MM-DD')
      ) {
        if (moment(multiDaysArr[index].startTime, 'HH:mm A') < currentTime) {
          validate = false;
          this.setState({
            showErrorStartTime: true,
          });
        }
      }
      //  else if (
      //   moment(multiDaysArr[index].endTime, 'HH:mm A') !== '12:00 AM'
      // ) {
      //   if (
      //     moment(multiDaysArr[index].startTime, 'HH:mm A') >
      //     moment(multiDaysArr[index].endTime, 'HH:mm A')
      //   ) {
      //     validate = false;
      //     this.setState({
      //       showErrorStartTime: true,
      //     });
      //   }
      // }
      if (
        moment(multiDaysArr[index].date).format('YYYY-MM-DD') ==
        moment(multiDaysArr[index].eventEndDate).format('YYYY-MM-DD')
      ) {
        if (minutesDiff < 30) {
          validate = false;
          this.setState({
            showErrorEndTimeDiff: true,
          });
        }
      }
    });

    return validate;
  };

  DateChange = (i, date) => {
    let { multiDaysArr } = this.state;
    let found = multiDaysArr.some(
      (el) => el.date === moment(date).format('YYYY-MM-DD')
    );
    if (found === false) {
      multiDaysArr[i].date = moment(date).format('YYYY-MM-DD');
      this.setState({
        multiDaysArr,
        qniqueErrorMsg: false,
        showErrorDate: false,
        fieldNameErrorMsg: false,
      });
    } else {
      multiDaysArr[i].date = moment(date).format('YYYY-MM-DD');
      this.setState({
        multiDaysArr,
        showErrorDate: false,
        fieldNameErrorMsgIndex: i,
        fieldNameErrorMsg: true,
      });
    }

    if (multiDaysArr[i].startTime !== '' && multiDaysArr[i].endTime !== '') {
      if (
        moment(multiDaysArr[i].startTime, 'HH:mm A') >
        moment(multiDaysArr[i].endTime, 'HH:mm A')
      ) {
        multiDaysArr[i].eventEndDate = moment(date)
          .add(1, 'days')
          .format('YYYY-MM-DD');
      } else {
        multiDaysArr[i].eventEndDate = moment(date).format('YYYY-MM-DD');
      }
    }
  };

  handleMultidays = (e) => {
    let multiDaysArr = [...this.state.multiDaysArr];
    if (e === 1 && this.state.multiDaysArr.length > 1) {
      multiDaysArr.splice(1, multiDaysArr.length);
      this.setState({
        multiDaysArr,
      });
    }
    this.setState({
      multiDays: e,
    });
  };

  addDateFieldsClick() {
    this.setState((prevState) => ({
      multiDaysArr: [
        ...prevState.multiDaysArr,
        {
          date: '',
          startTime: '',
          eventEndDate: '',
          endTime: '',
        },
      ],
    }));
  }

  removeDateFieldsClick(i) {
    let multiDaysArr = [...this.state.multiDaysArr];
    multiDaysArr.splice(i, 1);
    this.setState({ multiDaysArr: multiDaysArr });
  }

  handleChangeEditor(content, delta, source, editor) {
    this.setState({ aboutTheEvent: content, aboutTheEventErrorMsg: '' });
    const text = editor.getText(content);
    this.setState({ aboutTheEventValid: text });
  }

  componentDidMount() {
    console.log('qwertyui', this.props);
  }
  //Add event fee
  upadteEveentFee = () => {
    try {
      let obj = {
        eventId: this.props.eventId,
        ccFees: this.state.ccFees == '' ? '0' : this.state.ccFees,
        perTicketFees:
          this.state.perTicketFees == '' ? '0' : this.state.perTicketFees,
        transactionFees:
          this.state.transactionFees == '' ? '0' : this.state.transactionFees,
      };
      this.setState({ isLoader: true });
      let { dispatch } = this.props;
      dispatch(eventActions.addEventFees(obj)).then((data) => {
        if (data.statusCode === 1) {
          console.log('address', data?.responseData?.result);
          let params = data?.responseData?.result;
        } else {
          notification.error({
            message: 'Error',
            description: data?.error?.responseMessage,
          });
          this.setState({ isLoader: false });
        }
      });
    } catch (err) {
      console.log('eeeeeeeeeeeeeeeeeee', err);
    }
  };

  onImageLoaded = (image) => {
    image.setAttribute('crossorigin', 'anonymous');
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrlFile = await this.getCroppedImg(
        this.imageRef,
        crop,
        this.state.imageUpload

        // 'newFile.jpeg'
      );
      this.setState(
        {
          croppedImageUrl: window.URL.createObjectURL(croppedImageUrlFile),
          croppedImageUrlFile,
          crossOrigin: 'Anonymous',
        },
        () => {
          this.setState({ imageUpload: croppedImageUrlFile });
        }
      );
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;
    // maximum width/height
    var maxWidth = 1920,
      maxHeight = 1920 / (16 / 9);
    var targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }
    // set canvas size
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    });
  }

  clearDropzone = () => {
    this.setState({
      imagePreview: '',
      eventImgPreview: '',
      eventImage: '',
      crop: {
        unit: '%',
        aspect: 2.62,
        width: 90,
      },
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChange1 = (address) => {
    this.setState({
      address,
      addressErrorMsg: '',
      cityErrorMsg: '',
      stateErrorMsg: '',
      zipErrorMsg: '',
    });
  };

  handleSelectAddress = (address) => {
    console.log(address, 'adresssss');

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        newaddress = address.split(',');
        console.log('address', newaddress[0]);

        this.setState({
          lng: latLng.lng,
          lat: latLng.lat,
          address: newaddress[0],
        });
        // this.postalCode(latLng)

        let Url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=AIzaSyAjSodVOYLA8VVU2oOSToc7VtM8xKs13fI`;

        fetch(Url)
          .then((response) => response.json())
          .then((result) => {
            console.log('response>>', result.results);
            let zipcode;
            let city;
            let state;
            let street;
            for (var i = 0; i < result.results.length; i++) {
              for (
                var j = 0;
                j < result.results[i].address_components.length;
                j++
              ) {
                for (
                  var k = 0;
                  k < result.results[i].address_components[j].types.length;
                  k++
                ) {
                  if (
                    result.results[i].address_components[j].types[k] == 'route'
                  ) {
                    street = result.results[i].address_components[j].long_name;
                    console.log('zipcode>>', street);
                    // this.setState({ zip: zipcode })
                    if (street) {
                      this.setState({ street: street });
                    } else {
                      this.setState({ street: '' });
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ==
                    'postal_code'
                  ) {
                    zipcode =
                      result.results[i].address_components[j].short_name;
                    // console.log("zipcode>>",zipcode);
                    // this.setState({ zip: zipcode })
                    if (zipcode) {
                      this.setState({ zip: zipcode });
                    } else {
                      this.setState({ zip: '' });
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ==
                    'locality'
                  ) {
                    city = result.results[i].address_components[j].long_name;
                    if (city) {
                      this.setState({ city: city });
                    } else {
                      this.setState({ city: '' });
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ==
                    'administrative_area_level_1'
                  ) {
                    state = result.results[i].address_components[j].long_name;
                    // console.log("state>>", state);
                    if (zipcode) {
                      this.setState({ state: state });
                    } else {
                      this.setState({ state: '' });
                    }
                  }
                }
              }
            }
            //  this.setState({ zip: result.results[0].address_components[8].long_name })
          });
      })
      .catch((error) => console.error('Error', error));
  };

  handleChangeLocation = () => {
    console.log('jjjjjj');

    this.setState(
      {
        location: this.search.value,
      },
      () => {
        if (this.state.location && this.state.location.length > 1) {
          this.getInfo(this.state.location);
        }
      }
    );
  };

  getInfo = (params) => {
    usersService.getInfo(params);
  };

  getEventDetails = () => {
    let obj = {
      eventId: this.props.eventId,
    };
    window.scrollTo(0, 0);
    this.setState({ isLoader: true });
    usersService.viewEventDetails(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (res.data.statusCode === 1) {
        let {
          aboutTheEvent,
          address2,
          categoryId,
          categoryName,
          city,
          createdBy,
          endTime,
          eventDate,
          eventImage,
          eventName,
          eventPassword,
          eventVideo,
          privateType,
          startTime,
          state,
          status,
          tagline,
          zip,
          typeOfEvent,
          latlng,
          eventPlatform,
          promoterName,
          promoterEmail,
          aboutPromoter,
          ccFees,
          transactionFees,
          perTicketFees,
          multiEventDate,
        } = res?.data?.responseData?.result;

        this.setState({
          multiDaysArr: multiEventDate
            ? multiEventDate
            : [
                {
                  date: '',
                  startTime: '',
                  eventEndDate: '',
                  endTime: '',
                },
              ],

          multiDays: multiEventDate ? (multiEventDate.length === 1 ? 1 : 2) : 1,

          ccFees:
            ccFees !== '' &&
            ccFees !== undefined &&
            ccFees !== null &&
            ccFees !== NaN
              ? ccFees
              : 2.99,
          transactionFees:
            transactionFees !== '' &&
            transactionFees !== undefined &&
            transactionFees !== NaN &&
            transactionFees !== null
              ? transactionFees
              : 2.75,
          perTicketFees:
            perTicketFees !== '' &&
            perTicketFees !== undefined &&
            perTicketFees !== NaN &&
            perTicketFees !== null
              ? perTicketFees
              : 0.99,
          promoterEmail,
          aboutPromoter,
          aboutTheEvent,
          promoterName,
          address2,
          categoryId,
          categoryName,
          city,
          createdBy,
          endTime,
          eventDate,
          DateArr: eventDate,
          eventImgPreview: eventImage,
          eventPlatform,
          name: eventName,
          eventPassword,
          eventVideo,
          privateType,
          state,
          status,
          tagline,
          zip,
          typeOfEvent,
          address: latlng ? latlng.name : '',
          lat: latlng ? latlng.coordinates[1] : '',
          lng: latlng ? latlng.coordinates[0] : '',
          point: latlng ? latlng.type : '',
        });
        this.setState({ isLoader: false });
      } else {
      }
    });
  };

  handleChangeEventPlatform = (e) => {
    this.setState({
      eventPlatform: e,
    });
  };

  handleEditValidetion = () => {
    let validate = true;
    var dateString = new Date();
    var momentObj = moment(dateString, 'MM-DD-YYYY');
    var current = momentObj.format('MM-DD-YYYY');

    let {
      name,
      DateArr,
      address,
      state,
      zip,
      city,
      aboutTheEvent,
      eventImage,
      eventImgPreview,
      aboutTheEventValid,
    } = this.state;

    if (eventImage === '' && eventImgPreview === '') {
      validate = false;
      this.setState({
        imageErrorMsg: 'Please select an image.',
      });
    } else {
      this.setState({
        imageErrorMsg: '',
      });
    }
    if (name === '' || name === undefined) {
      validate = false;
      this.setState({
        nameErrorMsg: 'Please enter name.',
      });
    } else {
      this.setState({
        nameErrorMsg: '',
      });
    }

    if (aboutTheEvent === '' || aboutTheEvent === undefined) {
      validate = false;
      this.setState({
        aboutTheEventErrorMsg: 'Please enter about the event.',
      });
    }
    if (
      (aboutTheEvent === '' || aboutTheEvent === '<p><br></p>') &&
      (JSON.stringify(aboutTheEventValid.substring(1, 2)) === '' ||
        JSON.stringify(aboutTheEventValid.substring(1, 2)) === '\n' ||
        aboutTheEventValid.substring(1, 2).toString() === '' ||
        aboutTheEventValid.substring(1, 2).toString() === '\n')
    ) {
      validate = false;
      this.setState({
        aboutTheEventErrorMsg: 'Please enter about the event.',
      });
    }
    if (DateArr.length === 0 || DateArr.length === undefined) {
      validate = false;
      this.setState({
        eventDateErrorMsg: 'Please select date.',
      });
    }

    if (this.state.eventPlatform === 1) {
      if (address === '' || address === undefined) {
        validate = false;
        this.setState({
          addressErrorMsg: 'Please enter address.',
        });
      }
      if (zip === '' || zip === undefined) {
        validate = false;
        this.setState({
          zipErrorMsg: 'Please enter zipcode.',
        });
      }
      if (state === '' || state === undefined) {
        validate = false;
        this.setState({
          stateErrorMsg: 'Please enter state.',
        });
      }
      if (city === '' || city === undefined) {
        validate = false;
        this.setState({
          cityErrorMsg: 'Please enter city.',
        });
      }
    }

    return validate;
  };
  reDirectTo = () => {
    window.location.href = '/event-list';
  };

  endTimeStamp = (d, t) => {
    var acronym = new Date()
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')[2];

    var endDate = moment(d).format('DD MMM YYYY');

    var date = new Date(endDate + ' ' + t + ' ' + acronym).getTime();

    return date;
  };

  handleEditEvent = () => {
    if (this.handleEditValidetion()) {
      if (this.validateMultiDateArr()) {
        let sortDateTimeArr = this.state.multiDaysArr.sort(
          (a, b) => Date.parse(a.date) - Date.parse(b.date)
        );
        var start_time = moment(sortDateTimeArr[0].startTime, 'h:mm A').format(
          'h:mm A'
        );

        var end_time = moment(
          sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
          'h:mm A'
        ).format('h:mm A');

        let startTimeStamp = this.endTimeStamp(
          sortDateTimeArr[0].date,
          start_time
        );

        let endTimeStamp = this.endTimeStamp(
          sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
          end_time
        );
        var acronym = new Date()
          .toLocaleTimeString('en-us', { timeZoneName: 'short' })
          .split(' ')[2];
        this.upadteEveentFee();
        var formData = new FormData();
        formData.append('timeZone', acronym);
        formData.append('eventId', this.props.eventId);
        formData.append('eventName', this.state.name);
        formData.append('endTimeStamp', endTimeStamp);
        formData.append('startTimeStamp', startTimeStamp);
        formData.append('tagline', this.state.tagline);
        formData.append('promoterEmail', this.state.promoterEmail);
        formData.append('aboutPromoter', this.state.aboutPromoter);
        formData.append('promoterName', this.state.promoterName);
        formData.append('multiEventDate', JSON.stringify(sortDateTimeArr));
        formData.append(
          'eventDate',
          JSON.stringify([
            sortDateTimeArr[0].date,
            sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
          ])
        );
        // formData.append("eventDate", moment(this.state.eventDate).format('YYYY-MM-DD'))

        formData.append(
          'startTime',
          moment(sortDateTimeArr[0].startTime, 'h mm A').format('h mm A')
        );
        formData.append(
          'endTime',
          moment(
            sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
            'h mm A'
          ).format('h mm A')
        );
        formData.append(
          'zip',
          this.state.eventPlatform === 2 ? '' : this.state.zip
        );
        formData.append(
          'city',
          this.state.eventPlatform === 2 ? '' : this.state.city
        );
        formData.append(
          'state',
          this.state.eventPlatform === 2 ? '' : this.state.state
        );
        formData.append(
          'address2',
          this.state.eventPlatform === 2 ? '' : this.state.address2
        );
        formData.append(
          'address',
          this.state.eventPlatform === 2 ? '' : this.state.address
        );
        formData.append('eventPlatform', this.state.eventPlatform);
        formData.append('aboutTheEvent', this.state.aboutTheEvent);
        formData.append('aboutTheEventText', this.state.aboutTheEventValid);
        formData.append('categoryId', this.state.categoryId);
        formData.append(
          'eventImage',
          this.state.imageUpload
            ? this.state.imageUpload
            : this.state.eventImgPreview
        );
        formData.append(
          'latlng',
          this.state.eventPlatform === 2
            ? ''
            : JSON.stringify({
                coordinates: [this.state.lng, this.state.lat],
                name: this.state.address,
              })
        );

        let token = localStorage.getItem('accessToken');
        this.setState({ disabledBtn: true, isLoader: true });

        usersService.editEvent(formData, token).then((data) => {
          if (data.data.statusCode === 1) {
            message.success('Event successfully updated.', 5);
            this.getEventDetails(this.state.eventId);
            this.setState({ disabledBtn: false, loader: false });
            this.reDirectTo();
          } else {
            this.setState({ disabledBtn: false, loader: false });
            this.setState({
              errors: data.data.error.responseMessage,
              isLoader: false,
            });
          }
        });
      }
    }
  };

  disabledDate = (current) => {
    if (this.state.status === 3) {
      return current && current > moment().subtract(1, 'day');
    } else {
      return current && current < moment().startOf('day');
    }
  };

  componentDidMount() {
    this.getEventDetails();
    this.category();
  }

  category = () => {
    usersService.getCategory().then((data) => {
      if (data.data.statusCode === 1) {
        this.setState({
          categories: data.data.responseData.result,
        });
      }
    });
  };
  categoriesFilter = (e) => {
    this.setState({ categoryId: e });
  };
  filterCategory = (id) => {
    this.setState({ categoryId: id });
  };

  updateCheckBox(params) {
    if (params == 'publicEvent') {
      this.setState({
        publicEventCheck: !this.state.publicEventCheck,
        privateEventCheck: false,
        privateEventProtectedCheck: false,
        eventType: 1,
        type: 0,
        password: '',
      });
    }
    if (params == 'privateEvent') {
      this.setState({
        publicEventCheck: false,
        privateEventCheck: !this.state.privateEventCheck,
        privateEventProtectedCheck: false,
        eventType: 2,
        type: 1,
        password: '',
      });
    }
    if (params == 'privateEventProtected') {
      this.setState(
        {
          publicEventCheck: false,
          privateEventCheck: false,
          privateEventProtectedCheck: !this.state.privateEventProtectedCheck,
          eventType: 2,
          type: 2,
        },
        () => {
          if (this.state.privateEventProtectedCheck == false)
            this.setState({ type: 0 });
        }
      );
    }
  }
  abc = (arg) => {
    arg === 'password' && this.state.passEvent !== 2
      ? this.setState({ passEvent: 2, type: 2 })
      : this.setState({ passEvent: 1, type: 2 });
  };

  imageDimensions = (file) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };
      img.onerror = () => {
        reject('There was some problem with the image.');
      };
      img.src = URL.createObjectURL(file);
    });
  handleImageChange = async (droppedFile) => {
    let reader = new FileReader();
    let imageUpload = droppedFile[0];
    var imageName = imageUpload.name;
    var idxDot = imageUpload.name.lastIndexOf('.') + 1;
    var extFile = imageUpload.name
      .substr(idxDot, imageUpload.name.length)
      .toLowerCase();

    if (
      extFile === 'jpg' ||
      extFile === 'jpeg' ||
      extFile === 'png' ||
      extFile === 'svg'
    ) {
      const dimensions = await this.imageDimensions(imageUpload);
      console.info(dimensions, 'dimensions');
      if (dimensions.width >= 1388 && dimensions.height >= 530) {
        this.setState({
          eventImgPreview: '',
          eventImage: droppedFile[0],
          imageErrorMsg: '',
          imageName: imageName,
          docErrorMsg: '',
          prev: false,
        });
        reader.addEventListener(
          'load',
          () => {
            this.setState({
              imagePreview: reader.result,
              eventImage: droppedFile[0],
              departmentIconErrorMsg: '',
              imageName,
            });
          },
          false
        );
        if (imageUpload) {
          reader.readAsDataURL(imageUpload);
        }
      } else {
        this.setState({
          imageErrorMsg: 'Image size must be equal or greater than 1388x530',
        });
      }
    } else {
      this.setState({
        imageErrorMsg: 'Only jpg/jpeg, png, and svg files are allowed!',
      });
    }
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: '',
    });

  handleChangeEventPlatform = (e) => {
    this.setState({
      eventPlatform: e,
    });
  };

  getTime() {
    var d = new Date();
    d.setHours(d.getHours()); // offset from local time
    var h = d.getHours() % 12 || 12; // show midnight & noon as 12
    return (
      (h < 10 ? '0' : '') +
      h +
      (d.getMinutes() < 10 ? ':0' : ':') +
      d.getMinutes() +
      (d.getHours() < 12 ? ' AM' : ' PM')
    );
  }
  render() {
    var current = this.getTime();
    var currentTime = moment(current, 'HH:mm A');
    var currentDate = new Date();

    let {
      showErrorEndTime,
      showErrorStartTime,
      showErrorDate,
      showErrorEndTimeDiff,
      eventImgPreview,
      isLoader,
      imagePreview,
      imageErrorMsg,
      nameErrorMsg,
      aboutTheEventErrorMsg,
      addressErrorMsg,
      zipErrorMsg,
      stateErrorMsg,
      cityErrorMsg,
    } = this.state;

    let module = {
      toolbar: [
        [{ header: [1, 2, false] }, { font: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link'],
        ['clean'],
      ],
    };
    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    let format = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video',
    ];

    return (
      <React.Fragment>
        {isLoader && <Loader />}
        <div
          className="tab-pane fade show active"
          id="details"
          role="tabpanel"
          aria-labelledby="details"
        >
          <form class="forms-sample">
            <div class="form-group">
              <label for="exampleInputUsername12">
                Event Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="event-name"
                placeholder="Event Name"
                name="name"
                maxLength={100}
                value={this.state.name}
                onChange={this.handleChange}
              />
              {nameErrorMsg ? (
                <div className="error ml-0">{nameErrorMsg}</div>
              ) : null}
            </div>
            <div class="form-group m-b-40">
              <label for="exampleInputUsername12">Event Sub-Title</label>
              <input
                type="text"
                class="form-control"
                id="event-sub"
                maxLength={250}
                name="tagline"
                placeholder="Event Sub-Title"
                onChange={this.handleChange}
                value={this.state.tagline}
              />
            </div>
            <div className="row">
              <div className="col-md-4">
                <div class="form-group m-b-40">
                  <label for="exampleInputUsername12">CC Fees(%)</label>
                  <input
                    type="number"
                    class="form-control"
                    id="event-sub"
                    name="ccFees"
                    placeholder="CC Fees"
                    onChange={this.handleChange}
                    value={this.state.ccFees}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="form-group m-b-40">
                  <label for="exampleInputUsername12">Transaction Fee(%)</label>
                  <input
                    type="number"
                    class="form-control"
                    id="event-sub"
                    name="transactionFees"
                    placeholder="Transaction Fee"
                    onChange={this.handleChange}
                    value={this.state.transactionFees}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="form-group m-b-40">
                  <label for="exampleInputUsername12">Per Ticket Fee($)</label>
                  <input
                    type="number"
                    class="form-control"
                    id="event-sub"
                    name="perTicketFees"
                    placeholder="Per Ticket Fee"
                    onChange={this.handleChange}
                    value={this.state.perTicketFees}
                  />
                </div>
              </div>
            </div>
            {/* <div class="form-group m-b-40">
							<label for="exampleInputUsername12">Promoter Name</label>
							<input type="text" class="form-control" id="event-sub" name="promoterName" placeholder="promoter name" onChange={this.handleChange}
								value={this.state.promoterName} />
						</div>
						<div class="form-group m-b-40">
							<label for="exampleInputUsername12">Promoter Email</label>
							<input type="text" class="form-control" id="event-sub" disabled name="promoterEmail" placeholder="promoter email" onChange={this.handleChange}
								value={this.state.promoterEmail} />
						</div> */}
            <hr />

            <strong>Single or Multi-day Event?</strong>
            <div className="mb-2 mt-2">
              <span className="string-check string-check-bordered-primary string-check-inline mr-2 csm-check">
                <input
                  type="radio"
                  className="form-check-input"
                  id="formRadioInput11"
                  value={this.state.multiDays}
                  checked={this.state.multiDays === 1 ? true : false}
                  onChange={(e) => this.handleMultidays(1)}
                />
                <label className="string-check-label" for="formRadioInput11">
                  <span className="ml-2">
                    <small className="cm_bold_str">Single Day</small>
                  </span>
                </label>
              </span>
            </div>
            <div className="mb-4 pb-1">
              <span className="string-check string-check-bordered-primary string-check-inline mr-2">
                <input
                  type="radio"
                  className="form-check-input"
                  id="formRadioInput11"
                  value={this.state.multiDays}
                  checked={this.state.multiDays === 2 ? true : false}
                  onChange={(e) => this.handleMultidays(2)}
                />
                <label className="string-check-label" for="formRadioInput11">
                  <span className="ml-2 ">
                    <small className="cm_bold_str">Multi-Day</small>
                  </span>
                </label>
              </span>
            </div>
            {showErrorDate ? (
              ''
            ) : (
              <>
                {this.state.qniqueErrorMsg ? (
                  <div className="error ml-0 mb-2">
                    Please select unique event date.
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
            {this.state.multiDaysArr.map((el, i) => (
              <React.Fragment>
                <div className="row">
                  <div
                    className={`${
                      this.state.multiDaysArr.length > 1 ? 'col-11' : 'col-12'
                    }`}
                  >
                    <div className="form-row">
                      <div
                        className={`form-group ${
                          el.eventEndDate &&
                          moment(el.date).format('YYYY-MM-DD') <
                            moment(el.eventEndDate).format('YYYY-MM-DD')
                            ? ' col-lg-3 col-md-6 col-sm-6'
                            : ' col-lg-4 col-md-6  col-sm-6'
                        }`}
                      >
                        <label for="exampleInputUsername12">
                          Event Date
                          <span className="text-danger">*</span>
                        </label>
                        <DatePicker
                          allowClear={false}
                          className="form-control"
                          disabledDate={this.disabledDate}
                          value={el.date ? moment(el.date) : ''}
                          format={dateFormat}
                          onChange={(e) => this.DateChange(i, e)}
                        />
                        {showErrorDate ? (
                          <>
                            {el.date === '' ? (
                              <div className="error ml-0">
                                Please select event date.
                              </div>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}
                        {this.state.fieldNameErrorMsg &&
                        this.state.fieldNameErrorMsgIndex === i ? (
                          <div className="error ml-0">
                            This Date is already selected.
                          </div>
                        ) : null}
                      </div>
                      <div
                        className={`form-group ${
                          el.eventEndDate &&
                          moment(el.date).format('YYYY-MM-DD') <
                            moment(el.eventEndDate).format('YYYY-MM-DD')
                            ? ' col-lg-3 col-md-6 col-sm-6'
                            : ' col-lg-4 col-md-3 col-sm-3'
                        }`}
                      >
                        <label for="exampleInputUsername12">
                          Start Time
                          <span className="text-danger">*</span>
                        </label>

                        <Datetime
                          dateFormat={false}
                          value={
                            el.startTime
                              ? moment(el.startTime, 'h:mm A').format('h:mm A')
                              : ''
                          }
                          inputProps={{
                            readOnly: 'true',
                            className: 'form-control bg-white',
                            placeholder: 'Start time',
                          }}
                          onChange={(e) => this.startTimeChange(i, e)}
                        />
                        {showErrorStartTime ? (
                          <>
                            {el.startTime === '' ? (
                              <div className="error ml-0">
                                Please select start time.
                              </div>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}

                        {showErrorStartTime &&
                        el.date !== '' &&
                        el.date &&
                        el.startTime !== '' &&
                        el.startTime ? (
                          <>
                            {moment(el.date).format('YYYY-MM-DD') ===
                            moment(currentDate).format('YYYY-MM-DD') ? (
                              <>
                                {moment(el.startTime, 'HH:mm A') <
                                currentTime ? (
                                  <div className="error ml-0">
                                    Start time must be after current time.
                                  </div>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </div>

                      {el.eventEndDate &&
                      moment(el.date).format('YYYY-MM-DD') <
                        moment(el.eventEndDate).format('YYYY-MM-DD') ? (
                        <div
                          className={`form-group ${
                            el.eventEndDate &&
                            moment(el.date).format('YYYY-MM-DD') <
                              moment(el.eventEndDate).format('YYYY-MM-DD')
                              ? ' col-lg-3 col-md-6 col-sm-6'
                              : ' col-lg-4 col-md-3 col-sm-3'
                          }`}
                        >
                          <label for="exampleInputUsername12">
                            Event End Date
                            <span className="text-danger">*</span>
                          </label>
                          <DatePicker
                            disabled
                            allowClear={false}
                            className="form-control bg-white"
                            disabledDate={this.disabledDate}
                            value={
                              el.eventEndDate ? moment(el.eventEndDate) : ''
                            }
                            format={dateFormat}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        className={`form-group ${
                          el.eventEndDate &&
                          moment(el.date).format('YYYY-MM-DD') <
                            moment(el.eventEndDate).format('YYYY-MM-DD')
                            ? ' col-lg-3 col-md-6 col-sm-6'
                            : ' col-lg-4 col-md-3 col-sm-3'
                        }`}
                      >
                        <label for="exampleInputUsername12">
                          End Time
                          <span className="text-danger">*</span>
                        </label>

                        <Datetime
                          dateFormat={false}
                          inputProps={{
                            readOnly: 'true',
                            className: 'form-control bg-white',
                            placeholder: 'End time',
                          }}
                          value={
                            el.endTime
                              ? moment(el.endTime, 'h:mm A').format('h:mm A')
                              : ''
                          }
                          onChange={(e) => this.endTimeChange(i, e)}
                        />
                        {showErrorEndTime || showErrorStartTime ? (
                          ''
                        ) : (
                          <>
                            {this.checkDiff(el.startTime, el.endTime) < 30 &&
                            moment(el.date).format('YYYY-MM-DD') ===
                              moment(el.eventEndDate).format('YYYY-MM-DD') &&
                            showErrorEndTimeDiff ? (
                              <div className="error ml-0">
                                The event created should be of at least 30
                                minutes in time.
                              </div>
                            ) : (
                              ''
                            )}
                          </>
                        )}

                        {showErrorEndTime ? (
                          <>
                            {el.endTime === '' ? (
                              <div className="error ml-0">
                                Please select end time.
                              </div>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                  {this.state.multiDaysArr.length > 1 ? (
                    <div className="col-1">
                      <div class="text-md-center mt-4 pt-1">
                        <span
                          title="remove"
                          class="text-danger"
                          onClick={this.removeDateFieldsClick.bind(this, i)}
                        >
                          <Delete />
                        </span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </React.Fragment>
            ))}

            {this.state.multiDays === 2 ? (
              <button
                className="btn btn-primary next"
                type="button"
                onClick={this.addDateFieldsClick.bind(this)}
              >
                Add Date
              </button>
            ) : (
              ''
            )}
            <hr />
            <div className="row mt-3 mb-3">
              <span className="string-check string-check-soft-primary ml-2">
                <input
                  type="checkbox"
                  checked={this.state.eventPlatform == 1 ? true : false}
                  value={this.state.eventPlatform}
                  onChange={(e) => this.handleChangeEventPlatform(1)}
                  className="form-check-input"
                  id="chk2"
                />

                <label className="string-check-label" for="chk2">
                  Live
                </label>
              </span>
              <span className="string-check string-check-soft-primary ml-5">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value={this.state.eventPlatform}
                  onChange={(e) => this.handleChangeEventPlatform(2)}
                  checked={this.state.eventPlatform == 2 ? true : false}
                  id="chk2"
                />

                <label className="string-check-label" for="chk2">
                  Virtual
                </label>
              </span>
            </div>
            {this.state.eventPlatform == 1 ? (
              <React.Fragment>
                <div class="form-group m-t-40">
                  <label class="font-weight-semibold" for="inputAddress">
                    Event Address 1:<span className="text-danger">*</span>
                  </label>
                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange1}
                    onSelect={this.handleSelectAddress}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Places',
                            className: 'location-search-input form-control',
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: '#fafafa',
                                  cursor: 'pointer',
                                }
                              : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  {addressErrorMsg ? (
                    <div className="error ml-0">{addressErrorMsg}</div>
                  ) : null}
                </div>
                <div class="form-group">
                  <label class="font-weight-semibold" for="inputAddress2">
                    Event Address 2:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputAddress2"
                    name="address2"
                    maxLength={100}
                    placeholder="Apartment, studio, or floor"
                    value={this.state.address2}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-row">
                  <div class="form-group col-md-4">
                    <label class="font-weight-semibold" for="inputCity">
                      City<span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="inputCity"
                      placeholder="City"
                      maxLength={100}
                      name="city"
                      value={this.state.city}
                      onChange={this.handleChange}
                    />
                    {cityErrorMsg ? (
                      <div className="error ml-0">{cityErrorMsg}</div>
                    ) : null}
                  </div>
                  <div class="form-group col-md-4">
                    <label class="font-weight-semibold" for="state">
                      State<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={100}
                      id="exampleOption14"
                      placeholder="State"
                      name="state"
                      value={this.state.state}
                      onChange={this.handleChange}
                    />
                    {stateErrorMsg ? (
                      <div className="error ml-0">{stateErrorMsg}</div>
                    ) : null}
                  </div>

                  <div className="form-group col-md-4">
                    <label className="font-weight-semibold" for="inputZip">
                      Zip<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="inputZip"
                      maxLength={15}
                      placeholder="Zip"
                      name="zip"
                      value={this.state.zip}
                      onChange={this.handleChange}
                    />
                    {zipErrorMsg ? (
                      <div className="error ml-0">{zipErrorMsg}</div>
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ''
            )}

            <hr />
            <div class="form-group m-t-40">
              <label for="exampleTextarea">
                About the Event<span className="text-danger">*</span>
              </label>
              <ReactQuill
                theme={this.state.theme}
                onChange={this.handleChangeEditor}
                value={this.state.aboutTheEvent}
                bounds={'.app'}
                modules={module}
                formats={format}
              />
              {aboutTheEventErrorMsg ? (
                <div className="error ml-0">{aboutTheEventErrorMsg}</div>
              ) : null}
            </div>
            {/**
							<div class="form-group m-t-40">
							<label for="exampleTextarea">About the Promoter</label>
							<textarea class="form-control" name="aboutPromoter" rows="6" value={this.state.aboutPromoter} onChange={this.handleChange}></textarea>
							{aboutPromoterErrorMsg ? <div className="error ml-0">{aboutPromoterErrorMsg}</div> : null}
						</div>
	                     */}
            <div class="form-group  m-t-40">
              <label
                className="col-form-label col-sm-12 mb-0 ml-0"
                style={{ lineHeight: 0 }}
              >
                Event Image
              </label>
              <label
                className="col-form-label col-sm-12 mb-0"
                style={{ lineHeight: 0 }}
              >
                <small>
                  Recommended size is 1388x530. You will be able to use our
                  cropping tool to make it the perfect size.
                </small>
              </label>

              <Dropzone
                onDrop={(acceptedFile) => this.handleImageChange(acceptedFile)}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <section>
                    <div
                      style={{
                        background: eventImgPreview
                          ? `url(${eventImgPreview})`
                          : '',
                      }}
                      className={`upload_doc ${
                        imagePreview || eventImgPreview
                          ? 'imagePreview'
                          : 'dropify-wrapper'
                      }`}
                      {...getRootProps()}
                    >
                      {this.state.imagePreview ? (
                        <ReactCrop
                          minWidth={400}
                          src={this.state.imagePreview}
                          keepSelection={true}
                          crop={this.state.crop}
                          onImageLoaded={this.onImageLoaded}
                          onComplete={this.onCropComplete}
                          onChange={this.onCropChange}
                        />
                      ) : (
                        <input
                          {...getInputProps()}
                          accept=".png, .jpg, .jpeg"
                          className="image"
                          type="file"
                        />
                      )}
                      {imagePreview || eventImgPreview ? (
                        <span
                          className="btn btn-back btn-outline-brand border-brand remove-btn"
                          type="button"
                          onClick={this.clearDropzone}
                        >
                          Remove
                        </span>
                      ) : (
                        <p className="background container">
                          <Uploade className="fas fa-cloud-upload-alt" />
                          <br />{' '}
                          <span className="blue mt-3">
                            Drag and drop a file here or click
                          </span>
                        </p>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>

              {imageErrorMsg ? (
                <div className="error ml-0">{imageErrorMsg}</div>
              ) : null}
            </div>
          </form>
        </div>
        <div className="text-left mt-4 pt-3 border-top">
          <button
            type="submit"
            className="btn btn-primary float-right"
            onClick={this.handleEditEvent}
          >
            Save
          </button>
          <button
            className="btn btn-light mr-2 float-right "
            onClick={this.reDirectTo}
          >
            Cancel
          </button>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { props, viewEventDetails, getNewsFeed, user } = state;
  return {
    props,
    viewEventDetails,
    getNewsFeed,
    user,
  };
}
export default connect(mapStateToProps)(EditDetails);

// export default EditDetails;

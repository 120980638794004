import React, { useReducer } from 'react';
//header
import TopBar from '../../components/template/TopBar';
//footer
import Footer from '../../components/template/Footer';
//side nav var
import SideNav from '../../components/template/SideNav';
//Inline error
import InlineError from '../../components/common/InlineError';
//react-redux
import { connect } from "react-redux";
//action
import { authActions } from '../../_action/Auth';
//notification
import { notification } from 'antd'
import { EyeIcon } from "../../SvgIcon";
import { useState } from 'react';

//change password screen
function ChangePassword(props) {

    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            oldPassword: '', newPassword: '', oldPasswordError: '', newPasswordError: '',
            confirmPassword: '', confirmPasswordError: ''
        })

    //onChange
    const handleOnChange = (e) => {
        setUserInput({ ...userInput, [e.target.name]: e.target.value, newPasswordError: '', oldPasswordError: '', confirmPasswordError: '' })

    }

    //cancel button
    const handleSubmitCancel = () => {
        props.history.goBack()
    }

    //upadte button
    const handleSubmit = () => {
        let { oldPassword, newPassword, confirmPassword } = userInput
        if (oldPassword === '')
            setUserInput({ oldPasswordError: '*Please enter old password.' })
        else if (newPassword === '')
            setUserInput({ newPasswordError: '*Please enter new password.' })

        else if (newPassword.length <= 4)
            setUserInput({ newPasswordError: '*Password must be greter than 4 digits.' })
        else if (confirmPassword === '')
            setUserInput({ confirmPasswordError: '*Please enter confirm password.' })
        else if (newPassword !== confirmPassword)
            setUserInput({ confirmPasswordError: '*New password and confirm password must be same.' })
        else {
            let req = {
                oldPassword: userInput.oldPassword,
                password: userInput.newPassword
            }
            let { dispatch } = props;
            setUserInput({ loading: true });
            dispatch(authActions.changePassword(req)).then(data => {
                if (data.statusCode === 1) {
                    setUserInput({ loading: false })
                    notification.success({ message: 'Success', description: data.responseData.message });
                    props.history.goBack()
                }
                else {
                    notification.error({ message: 'Error', description: data?.error?.responseMessage })
                    setUserInput({
                        loading: false, erroStatus: false, message: data?.error?.responseMessage, showMessage: true
                    })
                }
            });
        }
    }

    let { oldPassword, newPassword, oldPasswordError, newPasswordError, confirmPassword, confirmPasswordError } = userInput
    const [showPassword, setShowPassword] = useState(false)
    const [newPasswordN, setNewPasswordN] = useState(false)
    const [confirmPasswordN, setConfirmPasswordN] = useState(false)
    return (
        <body className="dark-sidebar">
            <div className="main-container">
                <div className="container-fluid page-body-wrapper">
                    <nav className="navbar fixed-top"><div className="navbar-menu-container d-flex align-items-center justify-content-center">
                        <div className="sub-heading">
                            <h4 className="page-title ">Change Your Password</h4>
                        </div>
                        <div className="text-center navbar-brand-container align-items-center justify-content-center">
                            <a className="brand-logo" href="/">	<img src={require('../../assets/images/mobile-logo.png')} alt="Event 3" className="mb-3" />
                            </a>
                        </div>
                        <TopBar
                            {...props}
                        />
                    </div>
                    </nav>
                    <SideNav />
                    <div className="main-panel">
                        <div className="content-wrapper manage-event">
                            <div className="row card-margin">
                                <div className="col-md-12">
                                    <div className="card card-margin">
                                        <div className="card-header">
                                            <div className="col-md-10">
                                                <h5 className="card-title">Update Password</h5>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="forms-sample">
                                                <div className="form-row">
                                                    <div className="form-group col-md-4 position-relative input-ico">
                                                        <label className="font-weight-semibold"
                                                            for="name">Current Password</label>
                                                        <input type={showPassword ? 'text' : 'password'}
                                                            className="form-control"
                                                            id="name"
                                                            placeholder="Current Password"
                                                            maxLength="75"
                                                            autoComplete="off"
                                                            name='oldPassword'
                                                            value={oldPassword}
                                                            onChange={handleOnChange} />
                                                            <span onClick={() => setShowPassword(showPassword => !showPassword)} className={showPassword ?  '' : 'showeye'}>
                                                                <EyeIcon />
                                                            </span>
                                                        <InlineError
                                                            message={oldPasswordError}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4 position-relative input-ico">
                                                        <label className="font-weight-semibold"
                                                            for="email">New Password</label>
                                                        <input type={newPasswordN ? 'text' : 'password'}
                                                            className="form-control"
                                                            id="name"
                                                            placeholder="New Password"
                                                            maxLength="75"
                                                            autoComplete="off"
                                                            name='newPassword'
                                                            value={newPassword}
                                                            onChange={handleOnChange}
                                                        />
                                                        <span onClick={() => setNewPasswordN(newPasswordN => !newPasswordN)} className={newPasswordN ?  '' : 'showeye'}>
                                                                <EyeIcon />
                                                            </span>
                                                        <InlineError
                                                            message={newPasswordError}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4 position-relative input-ico">
                                                        <label className="font-weight-semibold"
                                                            for="phone">Confirm Password</label>
                                                        <input type={confirmPasswordN ? 'text' : 'password'}
                                                            className="form-control"
                                                            id="phone"
                                                            placeholder="Confirm Password"
                                                            maxLength="75"
                                                            autoComplete="off"
                                                            name='confirmPassword'
                                                            value={confirmPassword}
                                                            onChange={handleOnChange}
                                                        />
                                                        <span onClick={() => setConfirmPasswordN(confirmPasswordN => !confirmPasswordN)} className={confirmPasswordN ?  '' : 'showeye'}>
                                                                <EyeIcon />
                                                            </span>
                                                        <InlineError
                                                            message={confirmPasswordError}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer bg-white">
                                            <div className="pull-right">
                                                <button className="btn btn-light" onClick={handleSubmitCancel}>Cancel</button>
                                                <button type="submit" className="btn btn-primary mr-2 " onClick={handleSubmit}>Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </body >

    );

}


function mapStateToProps(state) {
    const { props, login, user } = state;
    return {
        props,
        login,
        user
    }
}
export default connect(mapStateToProps)(ChangePassword);



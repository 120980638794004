/**
 * @About
 * This file contains side navbar.
 */
import React from 'react';
import { Home, Reports, Manage_event, Create_event } from "../../SvgIcon";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link, NavLink } from 'react-router-dom';

const removeMenuClass = () => document.body.classList.remove('menu_open');

//side navbar
class SideBar extends React.Component {
    constructor() {
        super();
        this.handleClickSearch = this.handleClickSearch.bind(this);

        this.state = {

            settingsVisible: false,
        };
    }

    handleClickSearch() {
        if (!this.state.settingsVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
        this.setState(prevState => ({
            settingsVisible: !prevState.settingsVisible,
        }));
    }
    render() {
        return (
            <div className="sidebar">
                <Link to='/'><img className="logo-white" src={require('../../assets/images/logo.png')} alt="" /></Link>
                <ul className="sidebar-menu">
                    <li >
                        <NavLink onClick={removeMenuClass()} exact to="/dashboard"><span className="menu_icon"><Home /></span>Dashboard</NavLink>
                    </li>
                    <li >
                        <NavLink onClick={removeMenuClass()} exact to="/event-list"><span className="menu_icon"><Create_event /></span>Event Management</NavLink>
                    </li>

                    <li>
                        <NavLink onClick={removeMenuClass()} exact to="/manage-category"><span className="menu_icon"><Reports /></span>Category Management</NavLink>
                    </li>
                    <li>
                        <NavLink onClick={removeMenuClass()} exact to="/manage-user"><span className="menu_icon"><i className="far fa-user"></i></span>User Management</NavLink>
                    </li>
                    <hr />
                </ul>
            </div>
        );
    }
}
export default SideBar;